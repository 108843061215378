import React from 'react'

import {
    Container,
    Box,
    Input,
    Button,
    InputLabel,
    Typography,
    InputAdornment,
    IconButton,
    Grid,
    Stepper,
    Step,
    StepButton,
    styled,
    Hidden,
} from "@material-ui/core";
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import { newlogo } from '../../blocks/subscriptionbilling2/src/assets';

export default function SubscriptionSuccessScreen(props: any) {

    const SubscriptionSuccessStepWeb = () => {
        return (
            <Grid container spacing={4} style={{width:"100%", margin:"0px"}}>
                <Grid item xs={12}
                    style={{
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "25px",
                        padding: "50px 0px 20px",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <img
                        src={newlogo}
                        style={{ height: "45.59px", width: "249.88px", }}
                    />
                    <Box style={{ height: "34.59px", width: "277px" }}>
                        <Stepper activeStep={3} style={{display:"flex", gap:"5px", paddingTop: "0px"}}>
                            <Step>
                                <StepButton></StepButton>
                            </Step>
                            <Step>
                                <StepButton></StepButton>
                            </Step>
                            <Step>
                                <StepButton></StepButton>
                            </Step>
                        </Stepper>
                    </Box>
                    <Typography style={{ fontSize: "20px", 
                    color: "#14362E", fontFamily: "MyFont", 
                    letterSpacing: "1.3", fontWeight: 700 }}>
                    Payment Approved</Typography>
                    <Box style={{ height: "155px", marginTop: "20px" }}>
                        <CheckCircleOutlinedIcon style={{ height: "129px", width: "129px", color: "#73A58E" }} />
                    </Box>
                    <Box style={{ height: "80px", width: "350px" }}>
                        <Typography style={{ ...webStyle.ThankyouBoxStyle as any, fontFamily: "serif" }}>Thank You for Choosing KELLA! </Typography>
                    </Box>
                    <Box style={{ height: "26px", width: "469px" }}>
                        <Typography style={{
                            fontSize: "18px",
                            fontFamily: "MyFont !important",
                            fontWeight: 400,
                            lineHeight: "26px",
                            textAlign: "center",
                            color: "#0F172A",
                        }}>Your payment has been accepted. Welcome to KELLA </Typography>
                    </Box>
                    <Box style={{ height: "56px", width: "350px", padding: "8% 8% 0 8%",
                     borderRadius: "8px" }}>
                        <Button variant="contained" style={{
                            fontSize: "16px",
                            color: "#FFFFFF",
                            height: "56px",
                            width: "350px",
                            fontFamily: "MyFont",
                            fontWeight: 700,
                            lineHeight: "24px",
                            textAlign: "center",
                            backgroundColor: "#14362E",
                            textTransform: "capitalize"
                        }}
                        onClick={() => props.navigation.navigate("NewsFeed")}
                        >Continue</Button>
                    </Box>
                </Grid>
            </Grid>
        )
    }
    const SubscriptionSuccessStepMobile = () => {
        return (
            <Grid container spacing={4} style={{width:"100%", margin:"0px"}}>
                <Grid item xs={12}
                    style={{
                        gap: "25px",
                        padding: "50px 0px 0px 0px",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                    }}
                >
                    <img
                        src={newlogo}
                        style={{ width: "249px", height: "45.59px" }}
                    />
                    <Box style={{ width: "277px", height: "34.59px"}}>
                        <Stepper activeStep={3} style={{display:"flex", gap:"5px", paddingTop: "0px"}}>
                            <Step>
                                <StepButton></StepButton>
                            </Step>
                            <Step>
                                <StepButton></StepButton>
                            </Step>
                            <Step>
                                <StepButton></StepButton>
                            </Step>
                        </Stepper>
                    </Box>
                    <Typography style={{ fontSize: "20px", 
                    fontFamily: "MyFont", color: "#14362E", letterSpacing: "1.3", fontWeight: 700 }}>
                        Payment Approved</Typography>
                    <Box style={{ height: "155px", marginTop: "50px" }}>
                        <CheckCircleOutlinedIcon style={{ height: "129px", color: "#73A58E", width: "129px", }} />
                    </Box>
                    <Box style={{ height: "80px", width: "298px", marginTop: "20px" }}>
                        <CustomTypography className="font" style={{ ...webStyle.ThankyouBoxStyle as any, fontFamily: "MyFont" }}>Thank You for Choosing KELLA! </CustomTypography>
                    </Box>
                    <Box style={{ height: "80px", width: "350px" }}>
                        <Typography style={{
                            fontSize: "18px",
                            lineHeight: "26px",
                            textAlign: "center",
                            color: "#0F172A",
                            fontFamily: "MyFont ",
                            fontWeight: 400,
                        }}>Your payment has been accepted. Welcome to KELLA </Typography>
                    </Box>
                    <Box style={{ height: "56px", width: "auto", borderRadius: "8px", bottom: "30px", margin: "15px 0"  }}>
                        <Button variant="contained" style={{
                            fontWeight: 700,
                            lineHeight: "24px",
                            textAlign: "center",
                            backgroundColor: "#14362E",
                            color: "#FFFFFF",
                            fontSize: "16px",
                            fontFamily: "MyFont",
                            height: "56px",
                            width: "328px",
                            textTransform: "capitalize"
                        }}
                            onClick={() => props.navigation.navigate("NewsFeed")}
                        >Continue</Button>
                    </Box>
                </Grid>
            </Grid>
        )
    }
    return (
        <>
            <Hidden smDown>{SubscriptionSuccessStepWeb()}</Hidden>
            <Hidden mdUp>{SubscriptionSuccessStepMobile()}</Hidden>
        </>
    )
}

const webStyle = {
    ThankyouBoxStyle: {
        fontSize: "30px",
        fontFamily: "MyFont",
        fontWeight: 700,
        lineHeight: "40px",
        textAlign: "center",
        color: "#14362E",
    },
}

const CustomTypography = styled(Typography)({
    '& .MuiTypography-body1': {
        fontFamily: 'MyFont',
    },

});