import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { countries } from "countries-list";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  showLastGrid: boolean;
  webDrawerOpen: boolean;
  mobileDrawerOpen: boolean;
  showMobileGrid: boolean;
  selectedImage: string;
  finalImg: any,
  showPassword: boolean;
  steps: any[];
  value: any;
  selectedCountry: any;
  options: any;
  searchQuery: any;
  enablePassword: boolean;
  passwordError: any;
  errorConfirmPasswordFlag: boolean;
  errorEmailFlag: boolean;
  errorPasswordFlag: boolean;
  isOneCapital: boolean;
  isOneLower: boolean;
  isOneNumber: boolean;
  isValidLength: boolean;
  isSpecialcharacter:boolean;
  isValidEmail: boolean;  
  isValidEmailField: boolean;
  isValidPasswordField: boolean;
  errorFirstNameFlag: boolean;
  errorLastNameFlag: boolean;
  isValidFirstName: boolean;
  isValidFirstNameField: boolean;
  isValidLastName: boolean;
  isValidLastNameField: boolean;
  isValidPhoneNumberField: boolean;
  countryError: boolean;
  showAlert: boolean;
  kellaFirstName: string;
  kellaLastName: string;
  kellaPhoneNumber: string;
  kellaEmail: string;
  kellaPassword: string;
  showModel: boolean;
  isAcceptTerms: boolean;
  error: string,
  erroremail: boolean,
  errorphonenum: boolean,
  errorCheckBox: boolean,
  formSubmitted:boolean,
  isChecked: boolean,
  isFocused: boolean,
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  options: any;
  searchQuery: any;
  passwordError: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      showLastGrid: false,
      webDrawerOpen: false,
      mobileDrawerOpen: false,
      showMobileGrid: false,
      selectedImage: "",
      finalImg: "",
      showPassword: false,
      steps: [1, 2, 3, 4],
      value: "",
      selectedCountry: "IE",
      options: countries,
      searchQuery: "",
      enablePassword: true,
      passwordError: '',
      errorConfirmPasswordFlag: false,
      errorEmailFlag: false,
      errorPasswordFlag: false,
      isValidFirstNameField: false,
      isValidLastNameField: false,
      isValidLastName: false,
      isValidPhoneNumberField: false,
      countryError: false,
      isOneCapital: false,
      isOneLower: false,
      isOneNumber: false,
      isValidLength: false,
      isSpecialcharacter:false,
      isValidEmail: false,
      isValidEmailField: false,
      isValidPasswordField: false,
      isValidFirstName: false,
      errorFirstNameFlag: false,
      errorLastNameFlag: false,
      showAlert: false,
      kellaFirstName: "",
      kellaLastName: "",
      kellaPhoneNumber: "",
      kellaEmail: "",
      kellaPassword: "",
      showModel: false,
      isAcceptTerms: false,
      error: "",
      erroremail: false,
      errorphonenum: false,
      errorCheckBox: false,
      formSubmitted:false,
      isChecked: false,
      isFocused: false,
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const {RestAPIResponceDataMessage, RestAPIResponceSuccessMessage, RestAPIResponceErrorMessage } = MessageEnum;
    const apiRequestCallId = message.getData(getName(RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(RestAPIResponceSuccessMessage));
    const errorReponse = message.getData(getName(RestAPIResponceErrorMessage));

    if (!apiRequestCallId || !responseJson) return;


    if (apiRequestCallId === this.validationApiCallId) {
      this.handleValidationResponse();
    } else if (apiRequestCallId === this.createAccountApiCallId) {
      this.handleCreateAccountResponse(responseJson, errorReponse);
    }
     // Customizable Area End
  }

  // Customizable Area Start
  handleValidationResponse() {
    const regexData = this.arrayholder[0];
    if (regexData.password_validation_regexp) {
      this.passwordReg = new RegExp(regexData.password_validation_regexp);
    }
    if (regexData.password_validation_rules) {
      this.setState({ passwordHelperText: regexData.password_validation_rules });
    }
    if (regexData.email_validation_regexp) {
      this.emailReg = new RegExp(regexData.email_validation_regexp);
    }
  }

  handleCreateAccountResponse(responseJson: any, errorReponse: any) {
    if (!responseJson.errors) {

      localStorage.setItem("useremail", responseJson.data?.attributes?.personal_information.email);
      localStorage.setItem("userotptoken", responseJson.meta.token);
      window.location.href = "OTPInputAuthBlock";
    } else {

      this.setState({ erroremail: responseJson.errors?.email?.length > 0 ? true : false })
      this.setState({ errorphonenum: responseJson.errors?.full_phone_number ? true : false })
      this.parseApiErrorResponse(responseJson);
      this.setState({ error: responseJson.errors[0].account })
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }
  acceptPrivacyPollicy = () => {
    const prevData = localStorage.getItem('countSet');
    if(prevData){
      localStorage.removeItem('countSet')
    }else {
      localStorage.setItem('countSet','true');
    }
    this.setState((prev) =>  ({isChecked: !prev.isChecked}))
  }
  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone

    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  alreadyHaveAccount() {
    window.location.href = '/EmailAccountLoginBlock';
  }
  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {

  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    && this.txtInputEmailWebPrpos

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  handleSignUpClick = () => {
    this.setState({
      showLastGrid: !this.state.showLastGrid,
    });
  };

  handleMobileSignUpClick = () => {
    this.setState({
      showMobileGrid: !this.state.showMobileGrid,
    });
  };

  toggleWebDrawer = (open: any) => () => {
    this.setState({ webDrawerOpen: open });
  };
  toggleMobileDrawer = (open: any) => () => {
    this.setState({ mobileDrawerOpen: open });
  };
  handleChangeCountry = (event: any) => {
    this.setState({
      selectedCountry: event.target.value,
      webDrawerOpen: false,
      countryError: false
    });
    this.setState({
      selectedCountry: event.target.value,
      mobileDrawerOpen: false,
      countryError: false
    });
  };

  setEmail = (text: string) => {
    if (text) {
      this.setState({ errorEmailFlag: true, isValidEmailField: false })
    }
    const regexForEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    this.setState({
      isValidEmail: regexForEmail.test(text),
      kellaEmail: text
    })
  };
  setPassword = (kellaPassword: any) => {
    if (kellaPassword) {
      this.setState({
        errorConfirmPasswordFlag: true, errorPasswordFlag: true, isValidPasswordField: false, error: ''
      })
    }
    const regexOneCapital = /^(?=.*[A-Z]).+$/;
    const regexOneLower = /^(?=.*[a-z]).+$/;
    const regexOneNumber = /^(?=.*\d).+$/;
    const regexLength = /^.{8,}$/;
    const regexSpecialCharacter = /[$&+,:;=?@#|'<>.^*()%!-]/
    this.setState({
      isOneCapital: regexOneCapital.test(kellaPassword),
      isOneLower: regexOneLower.test(kellaPassword),
      isOneNumber: regexOneNumber.test(kellaPassword),
      isValidLength: regexLength.test(kellaPassword),
      isSpecialcharacter : regexSpecialCharacter.test(kellaPassword)
    })
    this.setState({ kellaPassword, error: "" });
  };
  handleClickShowPassword = () => {
    this.setState({ enablePassword: !this.state.enablePassword });
  };
  handleFileChange = (event: any) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const fileReader = new FileReader();

      this.setState({ finalImg: file })

      fileReader.onload = (event: any) => {
        const imageUrl = event.target.result;
        this.setState({ selectedImage: imageUrl });
      };

      fileReader.readAsDataURL(file);
    }
  };

  handleSearch = (event: any) => {
    this.setState({ searchQuery: event.target.value });
  };

  handleFirstName = (firstName: string) => {
    if (firstName) {
      this.setState({ errorFirstNameFlag: true, isValidFirstNameField: false })
    }
    const regexForFirstName = /^[A-Za-z]+$/;
    this.setState({
      isValidFirstName: regexForFirstName.test(firstName),
      kellaFirstName: firstName
    })
  };

  handleLastName = (lastName: string) => {
    if (lastName) {
      this.setState({ errorLastNameFlag: true, isValidLastNameField: false })
    }
    const regexForLastName = /^[A-Za-z]+$/;
    this.setState({
      isValidLastName: regexForLastName.test(lastName),
      kellaLastName: lastName
    })
  };

  alertclose = () => {
    this.setState({formSubmitted:false})
    this.setState({
      isValidEmailField: false,
      isValidPasswordField: false,
      isValidLastNameField: false,
      isValidFirstNameField: false,
      isValidPhoneNumberField: false,
      countryError: false,
      erroremail: false,
      errorphonenum: false,
      errorCheckBox: false,
      error: ''
    })
  }

  searchQueryfunc = () => {
    this.setState({ searchQuery: '' })
  }
  validateFormFields = () => {
    const {
      kellaEmail,
      kellaPassword,
      kellaFirstName,
      kellaLastName,
      kellaPhoneNumber,
      selectedCountry,
    } = this.state;

    // Check for missing or invalid fields
    const errors = {
      isValidEmailField: kellaEmail === "",
      isValidPasswordField: kellaPassword === "",
      isValidFirstNameField: kellaFirstName === "",
      isValidLastNameField: kellaLastName === "",
      isValidPhoneNumberField: kellaPhoneNumber === "",
      countryError: !selectedCountry,
    };

    return errors;
  };
  handleButtonClick = () => {
    this.setState({formSubmitted:true})
    this.setState({ error: '' })
    const errors = this.validateFormFields();

    if (Object.values(errors).some(error => error)) {
      // Set error states and return without making the API call
      this.setState(errors);
      return false;
    }
    const header = {
    };
    let formdata = new FormData();
    formdata.append("[account]first_name", this.state.kellaFirstName);
    formdata.append("[account]last_name", this.state.kellaLastName);
    formdata.append("[account]email", this.state.kellaEmail);
    formdata.append("[account]full_phone_number", this.state.kellaPhoneNumber);
    formdata.append("[account]country", this.state.selectedCountry);
    formdata.append("[account]profile_picture", this.state.finalImg)
    formdata.append("[account]password", this.state.kellaPassword);
    formdata.append("[data]type", "email_account");
    formdata.append("[account]company", 'FF');
    formdata.append("[account]terms_conditions_agreed",'true');
    formdata.append("[account]privacy_policy_agreed", 'true');
    formdata.append("[account]gdpr_agreed", 'true');

    const emailRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = emailRequestMessage.messageId;
    emailRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signupApiEndPoint
    );

    emailRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    emailRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    emailRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    if(localStorage.getItem("countSet") !== "true"){
      this.setState({errorCheckBox:true})
    }else{
      runEngine.sendMessage(emailRequestMessage.id, emailRequestMessage);
    }

    return true;
  };

  handleTermsCondsOpen = () => {
    this.setState({ showModel: true });
  };

  handleDialogClose = () => {
    this.setState({ showModel: false });
  };

  async componentDidMount(): Promise<void> {
    localStorage.clear()
  }

  // Customizable Area End
}
