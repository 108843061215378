import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  InputLabel,
  Grid
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { bannerpost, logo } from "./assets"
import Alert from '@material-ui/lab/Alert'

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "10px 0px",
            height: "100%",
            fontFamily: "MyFont"
          }}
        >
         
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} md={8} style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%"
            }}>
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%"
              }}>
                <Box sx={{ width: "100%", paddingTop: "20px" }}>
                  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Box sx={{ marginTop: { xs: "none", md: "45px", lg: "45px" } }}>
                      <img src={logo} alt="" />
                    </Box>
                    {
                      this.state.showAlertError ? <Alert style={{ fontSize: "11px", margin: "20px 0 0",fontFamily:"MyFont",letterSpacing:"0.9px", alignItems:"center" }} severity="error">{this.state.alertErrorMessage}</Alert> : ""
                    }
                    <Box sx={{ display: { lg: "block", md: "block", }, marginTop: "13" }}>
                      <Typography variant="subtitle1" component="div" style={{ fontSize: "20px", fontFamily: "MyFont",fontWeight:"500"  }}>
                        {this.state.labelHeader}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ padding: "20px 0px" }}>
                    <InputLabel style={{ marginBottom: "10px", color: "black", fontFamily: "MyFont",fontWeight:"700",letterSpacing:"0.7px"  }}>Email</InputLabel>
                    <Input
                      data-test-id="txtInputEmail"
                      placeholder={"Email"}
                      fullWidth={true}
                      value={this.state.email}
                      disableUnderline
                      onChange={(e) => this.setEmail(e.target.value.trim())}
                      style={{ border: "1px solid black", padding: "5px", color:"#64748B",borderRadius: "10px",fontFamily: "MyFont",fontWeight:"400",letterSpacing:"0.7px" }}
                    />
                  </Box>
                  <Box sx={{ padding: "20px 0px 0px 0px" }}>
                    <InputLabel style={{ marginBottom: "10px", color: "black", fontFamily: "MyFont",fontWeight:"700",letterSpacing:"0.7px"  }}>Password</InputLabel>
                    <Input
                      data-test-id="txtInputPassword"
                      type={this.state.enablePasswordField ? "password" : "text"}
                      placeholder={"Password"}
                      fullWidth={true}
                      disableUnderline
                      value={this.state.password}
                      onChange={(e) => this.setPassword(e.target.value.trim())}
                      style={{ border: "1px solid black", padding: "5px", borderRadius: "10px" ,fontFamily: "MyFont",fontWeight:"400",letterSpacing:"0.7px",color:"#64748B"}}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            edge="end"
                          >
                            {this.state.enablePasswordField ? (
                              <VisibilityOff />

                            ) : (
                              <Visibility />

                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Box>
                  <Box
                    data-test-id={"btnForgotPassword"}
                    style={{
                      fontSize: "14px",
                      color: "#064E3B",
                      fontWeight: "bold",
                      marginTop: "10px",
                      display: "flex",
                      justifyContent: "end",
                      cursor: "pointer",
                      letterSpacing: "0.8px"
                    }}
                    onClick={() => this.goToForgotPassword()}
                  >
                    Forgot password ?
                  </Box>
                </Box>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      paddingTop: "200px"
                    }}
                  >
                    <Button
                      data-test-id={"btnEmailLogIn"}
                      variant="contained"
                      style={{
                        background: "black",
                        color: "white",
                        fontWeight: "500",
                        width: "288px",
                        textTransform: "capitalize",
                        fontFamily: "MyFont",
                        fontSize:"16px"
                      }}
                      fullWidth
                      onClick={() => this.doEmailLogIn()}
                    >
                      Login
                    </Button>
                    <Box sx={{ display: 'flex', justifyContent: "spaceBetween", alignItems: "center" }}>
                      <Box
                        data-test-id={"btnSignup"}
                        sx={{
                          display: "flex",
                          fontSize: "15px",
                          fontWeight: "500",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "10px 0px",
                          marginTop: "10px",
                          fontFamily: "MyFont",
                          letterSpacing: "0.6px"
                        }}
                      >
                        Don't have an account? &nbsp;
                      </Box>
                      <span style={{ fontWeight: "bold", fontFamily: "MyFont", fontSize: "14px", letterSpacing: "0.8px", marginTop: "10px" }} onClick={this.handlesignupNavigation}>Sign up </span>
                    </Box>

                  </Box>
                </Box>
              </Box>

            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  display: { xs: "none", md: "flex", lg: "flex" }
                }}
              >
                <img
                  src={bannerpost}
                  alt="login-screen"
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    height: "100%"
                  }}
                />
              </Box>
            </Grid>

          </Grid>


        </Box>
      </ThemeProvider>



      // Customizable Area End
    );
  }
}
