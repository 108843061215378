import React from "react";
// Customizable Area Start
import AllEventDetailController, {
    Props,
} from "./AllEventDetailController";
import {
    Container,
    Box,
    Card,
    Button,
    CardMedia,
    CardContent,
    Typography,
    Drawer,
    styled,
    Modal,
    IconButton
} from "@material-ui/core";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { promocode, NewPromo, corssImg } from "../src/assets"
import { Alert } from "@material-ui/lab";
import Carousel from "react-multi-carousel";

export default class AllEventDetail extends AllEventDetailController {
    constructor(props: Props) {
        super(props);
    }

    puchaseDisplay() {
        const responsive = {
            desktop: {
              breakpoint: { max: 3000, min: 0 },
              items: 1,
              slidesToSlide: 1
            },
          };
          function mapMediaItemsDatapurchase(mediaItemsData: { content_type: string; url: string; }[]) {
              return mediaItemsData.map((item: { content_type: string; url: string; }) => ({
                  type: item?.content_type?.startsWith('image/') ? 'image' : 'video',
                  url: item?.url,
                }))
            }
            const imagesAndVideos = Object.keys(this.state.eventData).length > 0 && this.state.eventData.attributes?.images ? mapMediaItemsDatapurchase(this.state.eventData.attributes.images) : [];
            const media =  Object.keys(this.state.eventData).length > 0 && this.state.eventData.attributes?.videos ? mapMediaItemsDatapurchase(this.state.eventData.attributes.videos) : [];
            
            const combinedMedia = [...imagesAndVideos, ...media];
            const images = combinedMedia.filter(media => media.type === 'image');
            const videos = combinedMedia.filter(media => media.type === 'video');
            console.log("his.state.eventData.",this.state.eventData)
        return (
            <>
                {
                    Object.keys(this.state.eventData).length > 0 && (
                        <>
                            <Card style={{ maxWidth: 700, marginBottom: "20px", width: "100%" }}>
                            <Carousel
                                    responsive={responsive}
                                    autoPlay={false}
                                    swipeable={true}
                                    draggable={true}
                                    showDots={false}
                                    infinite={false}
                                    partialVisible={false}
                                    dotListClass="custom-dot-list-style"
          >
                            {images.map((media, index) => (
                                <div key={`image-${index}`} style={{ display: "flex", alignItems: "center", height: "200px" }}>
                                    <img style={{ width: "100%" }} src={media?.url} alt={`media-${index}`} />
                                </div>
                                ))}
                            {videos.map((media, index) => (
                            <div key={`video-${index}`}>
                                <video style={{ width: "100%", height: "250px" }} controls>
                                    <source src={media.url} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                                ))}
                                </Carousel>
                                <CardContent style={{ display: "flex", justifyContent: "space-evenly" }}>
                                    <Box style={{ padding: "10px" }}>
                                        <Box style={{ padding: "10px" }}>
                                            <Typography style={{ fontFamily: "MyFont" }}>{this.state.eventData.attributes.formatted_date}</Typography>

                                        </Box>

                                    </Box>
                                    <Box style={{ padding: "10px" }}>
                                        <Typography>{this.state.eventData.attributes.title}</Typography>
                                        <Typography style={{ color: "#64748B", fontSize: "12px", fontFamily: "MyFont", letterSpacing: "0.8px" }}>{this.state.eventData.attributes.notes}</Typography>
                                        <Typography style={{ fontFamily: "MyFont", fontSize: "12px" }}>{this.state.eventData.attributes.time_duration}</Typography>
                                        <Typography style={{ fontFamily: "MyFont", fontSize: "12px" }}>{this.state.eventData.attributes.going} Going - {this.state.eventData.attributes.tickets_left} Tickets left</Typography>
                                    </Box>
                                    <Box>
                                        <Typography style={{ fontFamily: "MyFont", fontSize: "15px", marginTop: "40px", width: "80px", fontWeight: "bold", cursor: "pointer" }} >View details</Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </>
                    )
                }

            </>
        )
    }

    QuantityBox() {
        return (
            <>
                <Card style={{ maxWidth: 380, marginBottom: "20px", width: "100%", boxShadow: "none" }}>

                    <CardContent style={{ display: "flex", flexDirection: "column" }}>
                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontWeight: "bold" }}>Quantity</Typography>
                            <Typography style={{ display: "flex", border: "1px solid #CBD5E1", width: "70px", justifyContent: "space-between", borderRadius: "4px", padding: "3px", alignItems: "center" }}>
                                <span style={{ cursor: "pointer" }} onClick={this.handleQuantityDecrease}><i className="fa-solid fa-minus"></i></span>
                                <span>{this.state.quantity}</span>
                                <span style={{ cursor: "pointer" }} onClick={this.handleQuantityIncrease}><i className="fa-solid fa-plus"></i></span>
                            </Typography>
                        </Box>
                        <Box style={webStyles.borderBottomstyle as any}></Box>
                        {
                            this.state.promocodeadded && (
                                <>
                                    <Alert
                                        style={{ margin: "20px 0px", fontFamily: "MyFont", letterSpacing: "0.8px" }}

                                    >
                                        Promo code: {this.state.PromocodeFInal}
                                    </Alert>
                                </>
                            )
                        }

                        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "4px 0px" }}>
                            <Box style={{ display: "flex", alignItems: "center", marginTop: "4px" }}>
                                <img src={promocode} style={{ marginRight: "5px" }} alt="" />
                                <Typography>Promo code</Typography>
                            </Box>
                            <Typography style={{ cursor: "pointer" }} onClick={this.handlePromocodeOpen}>
                                <i className="fa-solid fa-angle-right"></i>
                            </Typography>

                        </Box>
                        <Box style={webStyles.borderBottomstyle as any}></Box>
                        <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", marginTop: "5px", color: "#334155", fontWeight: "bold" }}>Billing Confirmation</Typography>
                        <Box style={{ background: "#F8FAFC", borderRadius: "4px", padding: "5px", marginTop: "5px" }}>
                            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px", marginTop: "3px" }}>Tickets quantity</Typography>
                                <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px", color: "#0F172A" }}>{this.state.quantity}</Typography>
                            </Box>
                            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px", marginTop: "3px" }}>Payment date</Typography>
                                <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px", fontWeight: "bold", color: "#0F172A" }}>{this.formatDate(this.state.currentDate)}</Typography>
                            </Box>
                            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px", marginTop: "3px" }}>Total amount</Typography>
                                <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px", color: "#73A58E" }}>€ {this.state.finalamount * this.state.quantity}</Typography>
                            </Box>
                        </Box>

                    </CardContent>
                </Card>
                <Box style={{ width: '80%', margin: "auto", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Button variant="outlined" style={{ width: "50%", border: "1px solid #73A58E", color: "#14362E", fontFamily: "MyFont", letterSpacing: "0.8px", textTransform: "capitalize" }} onClick={this.handleCancel}>Cancel</Button>&nbsp;&nbsp;&nbsp;
                    <Button variant="contained" style={{ width: "50%", background: "#14362E", color: "#fff", fontFamily: "MyFont", letterSpacing: "0.8px", textTransform: "capitalize" }} onClick={this.handlecheckout}>Pay</Button>
                </Box>
            </>
        )
    }

    QuantityBoxForFree() {
        return (
            <>
                <Card style={{ marginBottom: "21px", width: "100%", maxWidth: 380, boxShadow: "none" }}>

                    <CardContent style={{ display: "flex", flexDirection: "column" }}>
                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography style={{ fontFamily: "MyFont", fontWeight: "bold", letterSpacing: "0.8px" }}>Quantity</Typography>
                            <Typography style={{ display: "flex", border: "1px solid #CBD5E1", justifyContent: "space-between", borderRadius: "4px", padding: "4px", alignItems: "center", width: "70px" }}>
                                <span style={{ cursor: "pointer" }} onClick={this.handleQuantityDecrease}><i className="fa-solid fa-minus"></i></span>
                                <span style={{ fontFamily: "MyFont" }}>{this.state.quantity}</span>
                                <span style={{ cursor: "pointer" }} onClick={this.handleQuantityIncrease}><i className="fa-solid fa-plus"></i></span>
                            </Typography>

                        </Box>
                    </CardContent>
                </Card>

                <Box style={{ width: '80%', display: "flex", justifyContent: "space-between", alignItems: "center", margin: "auto" }}>
                    <Button variant="outlined" style={{ width: "50%", border: "1px solid #73A58E", color: "#14362E", letterSpacing: "0.8px", textTransform: "capitalize", fontFamily: "MyFont" }} onClick={this.handleCancel}>Cancel</Button>&nbsp;&nbsp;&nbsp;
                    <Button variant="contained" style={{ width: "50%", background: "#14362E", fontFamily: "MyFont", letterSpacing: "0.8px", color: "#fff", textTransform: "capitalize" }} onClick={this.handlecheckout}>Register</Button>
                </Box>
            </>
        )
    }

    PromocodeSidebar() {
        return (
            <>
                <BoxMain>
                    <Box style={{ padding: "10px" }}>
                        <Typography style={{ fontFamily: "MyFont", fontWeight: "bold", letterSpacing: "0.8px", fontSize: "21px", marginTop: "10px", paddingLeft: "10px" }}>Promo code</Typography>
                        <span onClick={this.drawerClose} style={{ cursor: "pointer", position: "absolute", right: "20px", top: "32px" }}><i className="fa-solid fa-xmark"></i></span>
                    </Box>
                    <Box style={webStyles.borderBottomstyle as any}></Box>
                    {
                        this.state.showALert && (
                            <>
                                <Box style={{ padding: "20px" }}>
                                    {
                                        this.state.sucessalert ? (
                                            <>
                                                <Alert
                                                    style={{ fontFamily: "MyFont", letterSpacing: '0.8px' }}
                                                    action={
                                                        <IconButton
                                                            aria-label="close"
                                                            color="inherit"
                                                            size="small"
                                                            onClick={this.handleAlertClose}
                                                        >
                                                            <i className="fa-solid fa-xmark"></i>
                                                        </IconButton>
                                                    }

                                                >
                                                    {this.state.sucessalertmsg}
                                                </Alert>
                                            </>
                                        ) : (
                                            <Alert
                                                style={{ letterSpacing: '0.8px', fontFamily: "MyFont" }}
                                                severity="error"
                                                action={
                                                    <IconButton
                                                        aria-label="close"
                                                        color="inherit"
                                                        size="small"
                                                        onClick={this.handleAlertClose}
                                                    >
                                                        <i className="fa-solid fa-xmark"></i>
                                                    </IconButton>
                                                }

                                            >
                                                {this.state.erroralertmsg}
                                            </Alert>
                                        )
                                    }
                                </Box>
                            </>
                        )
                    }


                    <Container >
                        <Box style={{ padding: "20px", position: "relative" }}>

                            <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px" }}>Promo code</Typography>
                            <input type="text" style={{ width: "100%", padding: "10px 5px", borderRadius: "5px", border: "1px solid #CBD5E1", outline: "none", fontFamily: "MyFont" }} value={this.state.Promocode} onChange={this.handlePromocodeGet} placeholder="Enter Code" name="" id="" />
                            <img src={NewPromo} style={{ position: "absolute", right: "25px", top: "53px" }} alt="" />

                        </Box>

                    </Container>

                    <Box style={webStyles.borderBottomstyle as any}></Box>
                    {
                        this.state.discount && Object.keys(this.state.discount).length > 0 && (
                            <>
                                <Container>
                                    <Box style={{ padding: "20px" }}>
                                        <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", marginTop: "5px", color: "#334155", fontWeight: "bold" }}>Coupon details</Typography>
                                        <Box style={{ background: "#F8FAFC", borderRadius: "4px", padding: "5px", marginTop: "5px" }}>
                                            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px", marginTop: "3px", fontWeight: "bold" }}>Promo code</Typography>
                                                <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px", color: "#0F172A", fontWeight: "bold" }}>{this.state.discount.promo_code}</Typography>
                                            </Box>
                                            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px", marginTop: "3px" }}>Amount off</Typography>
                                                <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px", color: "#73A58E" }}>-{this.state?.discount.discount}</Typography>
                                            </Box>
                                            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px", marginTop: "3px" }}>Tota Amount</Typography>
                                                <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px", color: "#0F172A", fontWeight: "bold" }}>€ {this.state.discount.total_amount}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Container>
                            </>
                        )
                    }


                    <Box style={{ padding: "0 10px", marginTop: "200px", marginBottom: "10px" }}>
                        <Button variant="contained" style={{ width: "100%", background: "black", color: "white", textTransform: "capitalize", fontFamily: "MyFont", marginTop: "210px" }} onClick={this.applycode}>Add Promo Code</Button>
                    </Box>
                </BoxMain>
            </>
        )
    }

    ErrorModel() {
        return (
            <>
                <Modal
                    open={this.state.errorCheckout}
                    onClose={this.handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 350, background: '#fff', padding: "8px 10px", borderRadius: "4px" }}>
                        <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Typography style={{ marginTop: "10px" }}><img src={corssImg} alt="" /></Typography>

                            <Typography style={{ marginTop: "10px", textAlign: "center", fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "14px" }}>{this.state.errormsgcheckout}</Typography>
                            <Box style={{ width: "100%", marginTop: "10px" }}>
                                <Button variant="contained" style={{ width: "100%", background: "black", color: "white", textTransform: "capitalize", fontFamily: "MyFont" }} onClick={this.handleCloseModal}>Understand</Button>
                            </Box>
                        </Box>

                    </Box>
                </Modal>
            </>
        )
    }
    render() {
        return (
            <>
                <AppHeader />

                <Box key={1} style={{ display: "flex", alignItems: "center", background: "#F8FAFC", padding: "10px", flexDirection: "column", }}>
                    <Card style={{ maxWidth: 750, marginBottom: "10px", marginTop: "4px", fontFamily: "MyFont", width: "100%", borderRadius: "12px", minHeight: "100vh", padding: "10px", position: "relative" }}>
                        <Box style={webStyles.tabStyle as any}>
                            <Box style={{ padding: "5px", cursor: "pointer", position: "absolute", left: "30px" }}>
                                <i className="fa-solid fa-arrow-left" onClick={this.handleGoEventpage}></i>
                            </Box>
                            <Typography style={{ fontFamily: "MyFont", fontWeight: "bold", fontSize: "19px", letterSpacing: "0.8px" }}>Purchase Ticket</Typography>
                        </Box>
                        <Box style={{ borderBottom: "1px solid #94A3B8", marginTop: "4px", width: "100%" }}></Box>
                        <Container>
                            <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", background: "#F8FAFC", marginTop: "10px" }}>
                                {this.puchaseDisplay()}
                                {
                                    Object.keys(this.state.eventData).length > 0 && (
                                        <>
                                            {
                                                this.state.eventData.attributes.is_paid ? this.QuantityBox() : this.QuantityBoxForFree()
                                            }
                                        </>
                                    )
                                }

                            </Box>
                        </Container>
                    </Card>
                    <Drawer style={webStyles.drawer as any} anchor="right" open={this.state.open} onClose={this.toggleDrawer.bind(this, false)}>
                        {this.PromocodeSidebar()}
                    </Drawer>
                    {this.ErrorModel()}
                </Box>
            </>
        )
    }
}

const BoxMain = styled(Box)({

    width: "420px",
    position: "relative",
    "@media(max-width: 600px)": {
        width: '100vw',
    },
})

const webStyles = {
    tabStyle: {
        display: "flex",
        justifyContent: "center",
        margin: "auto",
        marginTop: "0px",
        padding: "10px 8px",
        fontFamily: "MyFont",
        fontWeight: "bold",
        width: "300px",
    },
    borderBottomstyle: {
        marginTop: "10px",
        border: "1px solid #E2E8F0",
    },
    drawer: {
        position: "relative",
        width: "501px",
        display: "block",
        overflowY: "unset",
        '@media (min-width: 600px)': {
            display: "none",
        },
        "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: "240px",
        },
    }

}
// Customizable Area End