import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("./config");
import { getStorageData } from "../../../framework/src/Utilities";

type ReportCategory = {
  id: number;
  reason: string;
  description: string;
};
type accounts = {
  id: number;
  display: string;
  profile_picture: string;
};
type blockCategory = {
  id: number;
  title: string;
  description: string;
};
// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  NewsFeedData: any;
  NewsFeedDataSet:any;
  isLoading: boolean;
  searchHistoryApiResponse: any[];
  role_list: any[];
  industry_list: any[];
  category_list: any[];
  subCategory_list: any[];
  city_list: any[];
  type_list: any[];
  openMenu: any;
  openMenuId: any;
  isCommentDrawerOpen: boolean;
  errorMsg: string;
  loading: boolean;
  comment: string;
  commentString: string;
  commentData: any;
  mentions_ids: any;
  isCommentsOpen: boolean;
  isReplyOpen: boolean;
  openForElement: number;
  selectedPostId: number;
  open: any;
  Contentchange: string,
  reportListData: ReportCategory[],
  selected: string,
  selectedpostId: number;
  reportId: number,
  successModalOpen: boolean,
  reasonDescription: string,
  reasonTitle: string,
  open1: boolean,
  open3: boolean,
  deleteReasonData: blockCategory[],
  indexBlockList: number,
  sidebaarOpen: boolean,
  postId: string,
  shareconnectiondata: any,
  shareconnectiondata2:any,
  sharemessage: any,
  sharemessage3: any,
  selectedmember: any,
  selectedmember3: any,
  openBlockModal: boolean
  commentReplyOpen: boolean[];
  replyComment: string;
  commentID: number;
  replyCommentString: string;
  flagNoData: boolean;
  profile: any;
  peopleData: any;
  serachDataType: string;
  users: accounts[];
  filterParamsState: any;
  selectedComment: number;
  showEmoji : boolean,
  emoji : any,
  replyShowEmoji: boolean,
  setSearchvalue:string,
  accountId: string,
  termsStep: number;
  isTermsOpen: boolean;
  termsError: boolean;
  termsDescription: string
  isChecked: boolean,
  page: number,
  iteamsperPage: number,
  previousPage: number,
  hasMore: boolean,
  totalItems: number,
  likedataid:number,
  setEventDataresponse:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NewsFeedController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiCommentItemCallId: string = "";
  getNewsFeedDataCallId: string = "";
  likePostCallId: string = "";
  createCommentId: string = "";
  getuserdataCallId: string = "";
  getSearchHistoryCallId: string = "";
  getSearchFilterCallId: string = "";
  getEventSearchCallId: string = "";
  getRolesApiCallID: string = "";
  getIndustryApiCallID: string = "";
  getCategoryApiCallID: string = "";
  getSubCategoryApiCallID: string = "";
  getCityApiCallID: string = "";
  getTypeApiCallID: string = "";
  addFavDataCallId: string = "";
  getDeleteSearchHistoryCallId: string = "";
  apiUserRequestUndoAction: string = "";
  private resolveIndustryList: ((value: any) => void) | null = null;
  private rejectIndustryList: ((reason?: any) => void) | null = null;
  private resolveRoleList: ((value: any) => void) | null = null;
  private rejectRoleList: ((reason?: any) => void) | null = null;
  private resolveCategoryList: ((value: any) => void) | null = null;
  private rejectCategoryList: ((reason?: any) => void) | null = null;
  private resolveSubCategoryList: ((value: any) => void) | null = null;
  private rejectSubCategoryList: ((reason?: any) => void) | null = null;
  private resolveCityList: ((value: any) => void) | null = null;
  private rejectCityList: ((reason?: any) => void) | null = null;
  private resolveTypeList: ((value: any) => void) | null = null;
  private rejectTypeList: ((reason?: any) => void) | null = null;
  getReportDataCallId: string = "";
  addReportPostId: string = "";
  addBlockUserId: string = "";
  sharePostId: string = "";
  notificationshowID: string = "";
  getShareconnectionCallId: string = ""
  replycommentID: string = "";
  likeCommentCallID: string = "";
  apiprofile: string = "";
  addInviteId: string = "";
  tagandMentionapiId: string = "";
  shareCommentId: string = "";
  getTermsCondsListCallId: string = '';
  saveTermsCondsListCallId: string = '';
  apiGetNewsFeedFilterDataId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      errorMsg: "",
      token: "",
      loading: false,
      NewsFeedData: [],
      NewsFeedDataSet:[],
      isLoading: true,
      searchHistoryApiResponse: [],
      role_list: [],
      industry_list: [],
      category_list: [],
      subCategory_list: [],
      city_list: [],
      type_list: [],
      openMenu: false,
      openMenuId: "",
      isCommentDrawerOpen: false,
      comment: "",
      commentString: "",
      mentions_ids: [],
      commentData: [],
      isCommentsOpen: false,
      isReplyOpen: false,
      openForElement: null as unknown as number,
      selectedPostId: 0,
      open: false,
      Contentchange: "",
      reportListData: [],
      selected: "",
      selectedpostId: 0,
      reportId: 0,
      successModalOpen: false,
      reasonDescription: "",
      reasonTitle: "",
      open1: false,
      open3: false,
      deleteReasonData: [],
      indexBlockList: null as unknown as number,
      openBlockModal: false,
      sidebaarOpen: false,
      postId: "",
      shareconnectiondata: [],
      shareconnectiondata2: [],
      sharemessage: "",
      sharemessage3: "",
      selectedmember: [],
      selectedmember3: [],
      commentReplyOpen: [],
      replyComment: "",
      commentID: null as unknown as number,
      replyCommentString: "",
      profile: [],
      flagNoData: false,
      peopleData: [],
      serachDataType: "",
      users: [],
      selectedComment: null as unknown as number,
      showEmoji:false,
      emoji:null,
      replyShowEmoji: false,
      setSearchvalue:"",
      accountId: "",
      termsStep: 1,
      isTermsOpen: false,
      termsError: false,
      termsDescription: '',
      isChecked: false,
      page: 1,
      iteamsperPage: 20,
      previousPage: 1,
      hasMore: true,
      totalItems: 0,
      likedataid:0,
      filterParamsState: {},
      setEventDataresponse:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

   if (this.isRestApiResponse(message, responseJson)) {
      this.handleRestApiResponse(apiRequestCallId, responseJson);
    }
    // Customizable Area End

  }

  // Customizable Area Start
  async componentDidMount() {
    let accountId = await getStorageData("account_id")
    this.setState({
       accountId: accountId
    })
    const postPage = localStorage.getItem("pagenumber");
    const postIndex = localStorage.getItem("indexpage");
    if(postPage){
      this.setState({page:parseInt(postPage)})
  
        this.scrollToPost(postIndex);
      
    }
    const token = localStorage.getItem("accessToken")
    if (token) {
      this.getNewsFeedData();
      this.getViewProfileDara();
      this.getSearchHistoryData();
      this.getEnablenotification()
      this.getTermsCondsList(token);
      window.addEventListener('scroll', this.handleScroll);
    } else {
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), 'EmailAccountLoginBlock');
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg)
    }
  }

  async componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  scrollToPost(postId:any) {
   
    setTimeout(() => {
      
      setTimeout(()=>{
        const founddata = document.getElementById(`card-${postId}`);
        if (founddata) {
          founddata.scrollIntoView({ behavior: 'smooth' });
        }
      },2000)

      setTimeout(()=>{
        localStorage.removeItem("pagenumber")
        localStorage.removeItem("indexpage")
      },3000)
    }, 100); 
  }

  handleScroll = () => {
    const { loading, hasMore } = this.state;
    if (loading || !hasMore) return;

    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const docHeight = document.documentElement.scrollHeight;

    if (windowHeight + scrollTop >= docHeight - 1) {
      if(!this.state.isLoading){
        this.setState(
          (prevState) => ({
            page: prevState.page + 1,
            isLoading: true
          }),
          this.getNewsFeedData
        );
      }
    }
  };


  isRestApiResponse(message: Message, responseJson: any): boolean {
    return responseJson && getName(MessageEnum.RestAPIResponceMessage) === message.id;
  }

  getNewsFeedFilterData(queryParams?:any) {
    const token = localStorage.getItem("accessToken")

    const getNewsFeedMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetNewsFeedFilterDataId = getNewsFeedMessage.messageId;

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getFilterNewsfeedApiEndPoint}?${queryParams}`
    );

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: `${token}`,
      })
    );

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getNewsFeedMessage.id, getNewsFeedMessage);
  }

  handleRestApiResponse(apiRequestCallId: string, responseJson: any) {
    switch (apiRequestCallId) {
      case this.apiGetNewsFeedFilterDataId:
        this.setState({ NewsFeedDataSet: [], NewsFeedData: [], isLoading: true });
        if(responseJson.data.length===0) {this.setState({ flagNoData: true})}
        const filtertotalItems = responseJson?.meta?.pagy?.vars?.count;
        const filterItemsCount = this.state.NewsFeedData?.length;

        this.setState(() => ({ NewsFeedData: [...responseJson.data], isLoading: false, hasMore: filtertotalItems > filterItemsCount }));

        const finalchangeData = this.state.NewsFeedData.filter((elemen: any) => this.state.likedataid == elemen.id)
        this.setState(() => ({ NewsFeedData: finalchangeData.length > 0 ? [...responseJson.data] : [...this.state.NewsFeedData, ...responseJson.data], isLoading: false, hasMore: filtertotalItems > filterItemsCount }));
        const filterNewsfeedSet = [...new Set(this.state.NewsFeedData)]
        this.setState({ NewsFeedDataSet: filterNewsfeedSet });
        break;
      case this.getNewsFeedDataCallId:  
        const totalItems = responseJson.meta.pagy.vars.count;
        const currentItemsCount = this.state.NewsFeedData.length;


        this.setState(() => ({ NewsFeedData: [...responseJson.data], isLoading: false, hasMore: totalItems > currentItemsCount }));

        const finalchange = this.state.NewsFeedData.filter((elemen:any)=> this.state.likedataid == elemen.id)
        this.setState(() => ({ NewsFeedData:finalchange.length>0 ? [...responseJson.data]: [...this.state.NewsFeedData, ...responseJson.data], isLoading: false, hasMore: totalItems > currentItemsCount }));
        const NewsfeedSet = [...new Set(this.state.NewsFeedData)]
        this.setState({NewsFeedDataSet: NewsfeedSet});
        break;
      case this.likePostCallId:
        this.getNewsFeedData();
        break;
      case this.getuserdataCallId:
        localStorage.setItem("userdata", JSON.stringify(responseJson.data));
        this.setState({ profile: responseJson.data.attributes, isLoading: false, isTermsOpen: !responseJson.data.attributes.terms_conditions_agreed });
        break;
      case this.getSearchHistoryCallId:
        this.handleSearchHistoryData(responseJson);
        break;
      case this.getSearchFilterCallId:
        if (this.state.serachDataType == 'People') {
          this.setState({ peopleData: responseJson.data || [], isLoading: false });
        } else {
          this.setState({ NewsFeedData: responseJson.data || [], isLoading: false });
        }
        break;
      case this.getRolesApiCallID:
        this.handleRolesApiResponse(responseJson);
        break;
      case this.getIndustryApiCallID:
        this.handleIndustryApiResponse(responseJson);
        break;
      case this.getCategoryApiCallID:
        this.handleCategoryApiResponse(responseJson);
        break;
      case this.getSubCategoryApiCallID:
        this.handleSubCategoryApiResponse(responseJson);
        break;
      case this.getCityApiCallID:
        this.handleCitiesApiResponse(responseJson);
        break;
      case this.getTypeApiCallID:
        this.handleTypesApiResponse(responseJson);
        break;
      case this.addFavDataCallId:
        this.AddToFavData();
        break;
      case this.getReportDataCallId:
        this.getReportCatData(responseJson);
        break;
      case this.addBlockUserId:
        this.postBlockData();
        break;
      case this.apiCommentItemCallId:
        this.setState({ commentData: responseJson.data, errorMsg: "", loading: false, });
        this.AddToFavData()
        this.getEnablenotification()
        break;
      case this.getShareconnectionCallId:
        this.getShareconnection(responseJson)
        break;
      case this.sharePostId:
        this.sharePostData(responseJson)
        break;
      case this.likeCommentCallID:
         this.getNewsFeedData()
        this.getCommentData(this.state.selectedPostId)
        break;
      case this.notificationshowID:
        this.getNotificationdata(responseJson)
        break;
      case this.createCommentId:
        this.getCommentData(this.state.selectedPostId)
        break;
      case this.replycommentID:
        this.getCommentData(this.state.selectedPostId)
        break;
      case this.addInviteId:
        this.addToCircle();
        break;
      case this.tagandMentionapiId:
        this.fetchUsers(responseJson)
        break;
      case this.addReportPostId:
        this.postReportData(responseJson)
        break;      
      case this.shareCommentId:
        this.sharCommentData(responseJson)
        break;
      case this.getTermsCondsListCallId:
          this.setState({ termsDescription: responseJson.data.attributes.description})
          break;
      case this.saveTermsCondsListCallId:
          this.handleCloseTerms();
        break;
      case this.apiUserRequestUndoAction:
          this.getFilterPost(this.state.filterParamsState);
        break;
      case this.getEventSearchCallId:
        this.EventResponse(responseJson);
        break;
      case this.getDeleteSearchHistoryCallId:
        this.getSearchHistoryData();
        break;
      default:
        break;
    }
  }

  async componentDidUpdate(prevProps: any, prevState: { commentData: any; showEmoji : boolean; replyShowEmoji: boolean,page: number}) {
    if (prevState.commentData !== this.state.commentData) {
      this.initializeCommentReplyOpen();
    }
    const postDrawer = document.getElementById('postDrawer');
    if(postDrawer) {
      if (this.state.showEmoji && !prevState.showEmoji) {
        postDrawer.addEventListener('click', this.handleClickOutside)
      } else if (!this.state.showEmoji && prevState.showEmoji) {
        postDrawer.removeEventListener('click', this.handleClickOutside);
      }
      if (this.state.replyShowEmoji && !prevState.replyShowEmoji) {
        postDrawer.addEventListener('click', this.handleClickOutside);
      } else if (!this.state.replyShowEmoji && prevState.replyShowEmoji) {
        postDrawer.removeEventListener('click', this.handleClickOutside);
      }
    }

    if (this.state.page !== prevState.page) {
      this.getNewsFeedData()
    }
  }
  handleCheck = () => {
    this.setState(prev => ({isChecked: !prev.isChecked}))
  }
  handleSubmitTerms = () => {
    if(!this.state.isChecked){
      this.setState({termsError: true});
    }else {
      this.saveTermsCondsList()
    }
  }
  getTermsCondsList = async (token: string) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTermsCondsListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllTermsCondsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  saveTermsCondsList = async () => {
    const header = {
      token: localStorage.getItem('accessToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saveTermsCondsListCallId = requestMessage.messageId;
    let formdata = new FormData();
    formdata.append('[account]terms_conditions_agreed', 'true')
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts/'+ localStorage.getItem('account_id')
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleCloseTerms = () =>  {
    this.setState({isTermsOpen: false, termsStep: 1})
  }
  handleOpenTrms = () => {
    this.setState({isTermsOpen: true})
  }
  handleNextStepTerms =() => {
    this.setState(prev => ({termsStep: prev.termsStep + 1}));
  }
  handleSelect = (item: { id: number }, index: number) => {
    this.setState({
      selected: `${index}`,
      reportId: item.id
    })
  };
  hanldeError = (value: boolean) => {
    this.setState({termsError: value})
  }
  handleCloseError= () => {
    this.setState({termsError: false})
  } 
  handleSelect1 = (item: { title: string, description: string }, index: number) => {
    this.setState({
      reasonTitle: item.title,
      reasonDescription: item.description,
      indexBlockList: index
    });
  }

  handleCloseSuccessModal = () => {
    this.setState({
      successModalOpen: false,
      openBlockModal: false
    })
  }

  closeOkayModal = () => {
    this.setState({
      successModalOpen: false,
      openBlockModal: false
    })
  }

  getReportCatData = (responseJson: { categories: ReportCategory[] }) => {
    this.setState({
      reportListData: responseJson.categories,
    })
  }

  postReportData = (responseJson: ReportCategory) => {
    if (responseJson) {
      this.setState({
        successModalOpen: true,
        reportId: 0,
        selected: "",
        open: false
      })
    }
  }

  EventResponse = (responseJson:any)=>{
    this.setState({setEventDataresponse:responseJson.data})
  }

  postBlockData = () => {
    this.setState({
      open1: false,
      openBlockModal: true,
    })
  }


  handlewritemessage = (event: any) => {
    this.setState({ sharemessage: event.target.value })
  }
  handlewriteCommentmessage = (event: any) => {
    this.setState({ sharemessage3: event.target.value })
  }

  getShareconnection = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ shareconnectiondata: responseJson.data, shareconnectiondata2:responseJson.data })
    }
  }

  sharePostData = (responseJson: any) => {
    if (responseJson.message) {
      this.getEnablenotification()
      this.setState({ shareconnectiondata: [], sharemessage: "", sidebaarOpen: false })
    }
  }

  sharCommentData = (responseJson: any) => {
    if (responseJson.message) {
      this.getEnablenotification()
      this.setState({ shareconnectiondata2: [], sharemessage3: "", open3: false })
    }
  }

  getNotificationdata = (responseJson: any) => {
    if (responseJson.data) {
      localStorage.setItem("unreadnotification", responseJson.data.unread_notification)
    }
  }

  getvalueconnectioncheck = (elementdata: any, data: any) => {
    if (elementdata.target.checked) {
      const IteamIndex = this.state.selectedmember.findIndex((iteam: any) => iteam.id === data.id);


      if (IteamIndex < 0) {
        const storetype = [...this.state.selectedmember, { id: data.id, value: data.name }];
        this.setState({ selectedmember: storetype });
      }
    } else {
      const deleteSelectedType = this.state.selectedmember.filter((element: any) => {
        return element.id !== data.id
      })

      this.setState({ selectedmember: deleteSelectedType });
    }
  }

  handleselectmemberDelete = (elementdata: number) => {
    const deleteSelectedType = this.state.selectedmember.filter((element: any, index: number) => {
      return index !== elementdata
    })

    this.setState({ selectedmember: deleteSelectedType });
  }

  handlePostshare = () => {
    const token = localStorage.getItem("accessToken");

    const memberid = this.state.selectedmember.map((element: any) => element.id);
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };


    let formdata = new FormData()
    for (let iteams of memberid) {
      formdata.append("room_ids[]", iteams)
    }
    formdata.append("content", this.state.sharemessage)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sharePostId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `posts/${this.state.postId}/share`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `POST`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getvalueconnectioncheck3 = (elementdata: any, data: any) => {

    if (elementdata.target.checked) {
      const IteamIndex = this.state.selectedmember3.findIndex((iteam: any) => iteam.id === data.id);


      if (IteamIndex < 0) {
        const storetype = [...this.state.selectedmember3, { id: data.id, value: data.name }];
        this.setState({ selectedmember3: storetype });
      }
    } else {
      const deleteSelectedType = this.state.selectedmember3.filter((element: any) => {
        return element.id !== data.id
      })

      this.setState({ selectedmember3: deleteSelectedType });
    }
  }
  handleselectmemberDelete3 = (elementdata: any) => {
    const deleteSelectedType = this.state.selectedmember3.filter((element: any, index: number) => {
      return element.id !== elementdata.id
    })

    this.setState({ selectedmember3: deleteSelectedType});
  }
  handleCommentshare = () => {
    const token = localStorage.getItem("accessToken");

    const memberid = this.state.selectedmember3.map((element: any) => element.id);
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };

    let formdata = new FormData()
    for (let iteams of memberid) {
      formdata.append("room_ids[]", iteams)
    }
    formdata.append("content", this.state.sharemessage3)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.shareCommentId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `comments/${this.state.selectedComment}/share`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `POST`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getEnablenotification = () => {
    const token = localStorage.getItem("accessToken");

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationshowID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/unreads`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handlesearchTag = (event: any) => {
    this.handleSearchconnections(event)
    this.setState({setSearchvalue:event.target.value})
  }

  removesearchdata=()=>{
    this.setState({setSearchvalue:""})
    this.setState({shareconnectiondata:[]})
  }

  handleSearchconnections = (data: any) => {
    const token = localStorage.getItem("accessToken")

    const getShareconnectionMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getShareconnectionCallId = getShareconnectionMessage.messageId;

    getShareconnectionMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_share/share?search=${data.target.value}`
    );

    getShareconnectionMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: `${token}`,
      })
    );

    getShareconnectionMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );

    runEngine.sendMessage(getShareconnectionMessage.id, getShareconnectionMessage);
  }

  handleOpenMenu = (event: any, id: string) => {
    this.setState({
      openMenuId: id,
      openMenu: event?.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      openMenuId: null,
      openMenu: false,
    });
  };

  toggleDrawer = (e: boolean, content: string) => {
    this.setState({ open: e, Contentchange: content })
  }

  toggleDrawer1 = (e: boolean, content: string) => {
    this.setState({ open1: e, Contentchange: content })
  }

  drawerClose = () => {
    this.setState({ open: false ,  open1: false })
  }

  AddToFavData = () => {
    this.getNewsFeedData();
    this.getViewProfileDara();

  }

  handlePostReport = (postId: number) => {
    this.setState({ open: true })
    this.getReportData();
    this.setState({
      selectedpostId: postId
    })
  }

  handleBlockUser = (elementId: number) => {
    this.setState({
      deleteReasonData: configJSON.blockReasons,
      selectedpostId: elementId
    })
    this.setState({ open1: true })
  }

  handleCopyLink = (link: string) => {
    navigator.clipboard.writeText(link)
  };

  handleRedirect = (link: string) => {
    window.open(link, '_blank');
  };

  handleReasonSelect = (title: string, description: string) => {
    this.setState({ reasonTitle: title, reasonDescription: description });
  };

  drawerSidebaar = (elementid: string) => {
    this.setState({ postId: elementid })
    this.setState({ sidebaarOpen: true })
  }

  drawerSidebaarClose = () => {
    this.setState({ sidebaarOpen: false })
  }

  getReportData() {
    const token = localStorage.getItem("accessToken")

    const getNewsFeedMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReportDataCallId = getNewsFeedMessage.messageId;

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getReportApiPoint
    );



    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: `${token}`,
      })
    );

    runEngine.sendMessage(getNewsFeedMessage.id, getNewsFeedMessage);
  }

  getNewsFeedData() {
    const token = localStorage.getItem("accessToken")

    const getNewsFeedMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getNewsFeedDataCallId = getNewsFeedMessage.messageId;

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.targetedFeedEndpoint}?items=${this.state.iteamsperPage}&page=${this.state.page}`
    );

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: `${token}`,
      })
    );

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getNewsFeedMessage.id, getNewsFeedMessage);
  }

  getViewProfileDara() {
    const account_id = localStorage.getItem("account_id");
    const token = localStorage.getItem("accessToken")

    const getNewsFeedMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getuserdataCallId = getNewsFeedMessage.messageId;

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${account_id}`
    );

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: `${token}`,
      })
    );

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getNewsFeedMessage.id, getNewsFeedMessage);
  }
  createComment = () => {
    const header = {
      "Content-Type": configJSON.commentsContentType,
      token: localStorage.getItem("accessToken")
    };

    const httpBody = {
      post_id: this.state.selectedPostId,
      comment: this.state.commentString,
      mentions_ids: this.state.mentions_ids
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createCommentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createcommentEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  createReplyComment() {
    const header = {
      "Content-Type": configJSON.commentsContentType,
      token: localStorage.getItem("accessToken")
    };

    const replyData = {
      parent_comment_id: this.state.commentID,
      comment: this.state.replyCommentString,
      mentions_ids: []
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.replycommentID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.commentreplyEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(replyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCommentData(postId: number): any {
    const header = {
      "Content-Type": configJSON.commentsContentType,
      token: localStorage.getItem("accessToken")
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiCommentItemCallId = requestMessage.messageId;

    const endpoint = `${configJSON.commentsEndPoint}?post_id=${postId}`;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  addToCircle = () => {
    this.getFilterPost(this.state.filterParamsState);
  }

  getUserData(): any {
    const header = {
      "Content-Type": configJSON.commentsContentType,
      token: localStorage.getItem("accessToken")
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.tagandMentionapiId = requestMessage.messageId;

    const endpoint = configJSON.userLists;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  toggleCommentDrawer = (isOpen: boolean, postId: number) => {
    this.setState({ isCommentDrawerOpen: isOpen, selectedPostId: postId });
  }
  handleCommentChange = (event: any) => {
    this.setState({ comment: event.target.value });
  };
  handleCommentSubmit = () => {
    const { comment, commentData } = this.state;
    const mentionRegex = /@\[(.+?)\]\((\d+)\)/g;
    let mentionIds: number[] = [];
    let formattedComment = comment.replace(mentionRegex, (_, display, id) => {
      mentionIds.push(id);
      return `@${display}`;
    });
    if (comment.trim()) {
      const newComment = {
        attributes: {
          comment: formattedComment,
          post_id: this.state.selectedPostId,
          mentions_ids: mentionIds
        }
      };

      this.setState({
        commentData: [newComment, ...commentData],
        commentString: formattedComment,
        mentions_ids: mentionIds,
        comment: '',
        isCommentsOpen: true,
      }, () => {
        this.createComment();
        this.getCommentData(this.state.selectedPostId)
      });
    }
  };
  toggleComments = () => {
    this.setState((prevState) => ({ isCommentsOpen: !prevState.isCommentsOpen }));
  };

  toggleReply = (element: any) => {
    this.setState((prevState) => ({
      isReplyOpen: element?.id === prevState.openForElement ? !prevState.isReplyOpen : true,
      openForElement: element?.id
    }));
  };
  handleCommentfetch = (postId: number) => {
    this.getCommentData(postId)
    this.getUserData()
  }
  handleClick = (postId: number, event: React.MouseEvent<HTMLElement>) => {
    this.toggleCommentDrawer(true, postId);
    this.setState({ selectedPostId: postId });
    this.handleCommentfetch(postId)
  }
  handleClose2 = (event: React.MouseEvent<HTMLElement>) => {
    this.toggleCommentDrawer(false, 0);
    this.setState({showEmoji:false});
  }
  likePost = (datas: any) => {
    const token = localStorage.getItem("accessToken")
    const account_id = localStorage.getItem("account_id")

    this.setState({likedataid:datas.id})

    const attrs = {
      "post_id": datas.id,
      "account_id": account_id
    };

    const data = {
      type: datas.attributes.is_liked ? "unlike" : "like",
      attributes: attrs
    };

    const finaldata = {
      data
    }


    const LikePostMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.likePostCallId = LikePostMessage.messageId;

    LikePostMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_like_a_post/like_posts`
    );

    LikePostMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.exampleApiContentType,
        token: `${token}`,
      })
    );


    LikePostMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(finaldata)
    );

    LikePostMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );


    runEngine.sendMessage(LikePostMessage.id, LikePostMessage);
  }

  getSearchHistoryData = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSearchHistoryCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchHistoryApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  getSearchFilterData = (filters: any) => {
    const storedFilterState: any = JSON.parse(localStorage.getItem("isFilterOpen") || "[]");
  
    // Helper function to format arrays into query strings
    const formatArrayForQuery = (arr: any[]) => {
      return arr && arr.length > 0 ? encodeURIComponent(JSON.stringify(Array.from(new Set(arr)))) : "";
    };
  
    // Helper function to build query parameters
    const buildQueryParams = (filters: any, typeParams: any) => {
      let queryParams: any = [`search_type=${filters?.search_type}`];
  
      Object.entries(typeParams).forEach(([key, value]) => {
        const formatted = formatArrayForQuery(value as any);
        if (formatted) queryParams.push(`${key}=${formatted}`);
      });
  
      return queryParams.join('&');
    };
  
    // Posts filter
    if (storedFilterState?.[1] && filters?.search_type === "Posts") {
      const typeParams = {
        category_id: filters?.category,
        sub_category: filters?.sub_category,
      };
      this.getNewsFeedFilterData(buildQueryParams(filters, typeParams));
    }
  
    // People filter
    else if (storedFilterState?.[0] && filters?.search_type === "People") {
      const typeParams = {
        country: filters?.country,
        industry: filters?.industry,
        role: filters?.role,
      };
      this.getNewsFeedFilterData(buildQueryParams(filters, typeParams));
    }
  
    // Recommendations filter
    else if (storedFilterState?.[2] && filters?.search_type === "Recommendations") {
      const typeParams = {
        recommendation_type_id: filters?.recommendation_type_id,
        city: filters?.city,
      };
      this.getNewsFeedFilterData(buildQueryParams(filters, typeParams));
    }
  
    // Events filter
    else if (filters?.search !== "" && filters?.search_type === "Events") {
      this.getEventData(filters);
    }
  
    // Default behavior when no search is provided or search_type is not Events
    else {
      filters?.search !== "" && filters?.search_type !== "Events"
        ? this.getFilterPost(filters)
        : this.getNewsFeedData();
    }
  
    this.setState({
      flagNoData: true,
      serachDataType: filters.search_type
    });
  };
  

  handleDeleteSearchHistory = (item:any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDeleteSearchHistoryCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteSearchHistoryApiEndPoint}${item}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getEventData = (filters:any)=>{
    const header = {
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEventSearchCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_events/events?search=${filters.search}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUserRequestUndoApiCall = (
    user_id: any,
  ) => {
    const header = {
      "Content-Type": configJSON.accountDetailsAPiContentType,
      token: localStorage.getItem("accessToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUserRequestUndoAction = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addConnectionEndPoint}/${user_id}/request_remove`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getFilterPost = (filters?: any) => {
    this.setState({filterParamsState: filters})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSearchFilterCallId = requestMessage.messageId;
    const queryParams = new URLSearchParams(filters)

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.searchFilterApiEndPoint}?${queryParams}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRoleList = (): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.resolveRoleList = resolve;
      this.rejectRoleList = reject;
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken"),
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getRolesApiCallID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.rolesApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    });
  };

  getIndustryList = (): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.resolveIndustryList = resolve;
      this.rejectIndustryList = reject;
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken"),
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getIndustryApiCallID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.industriesApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    });
  };

  getCategoryList = (): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.resolveCategoryList = resolve;
      this.rejectCategoryList = reject;
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken"),
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getCategoryApiCallID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.categoryApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    });
  };

  getSubCategoryList = (): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.resolveSubCategoryList = resolve;
      this.rejectSubCategoryList = reject;
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken"),
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getSubCategoryApiCallID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.subCategoryApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    });
  };

  inviteCircle = (e: any) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addInviteId = requestMessage.messageId;

    const bodyConnection = {
      receipient_id: e
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addConnectionEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyConnection)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  getCityList = (): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.resolveCityList = resolve;
      this.rejectCityList = reject;
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken"),
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getCityApiCallID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.citiesApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    });
  };

  getTypesList = (): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.resolveTypeList = resolve;
      this.rejectTypeList = reject;
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken"),
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getTypeApiCallID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.typesApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    });
  };


  handleSearchHistoryData = (responseJson: any) => {
    if (Array.isArray(responseJson.data)) {
      this.setState({ searchHistoryApiResponse: responseJson.data });
    }
  }

  handleRolesApiResponse = (responseJson: any) => {
    if (responseJson.roles) {
      if (this.resolveRoleList) {
        this.resolveRoleList(responseJson);
        this.resolveRoleList = null;
        this.rejectRoleList = null;
      }
    } else if (this.rejectRoleList) {
      this.rejectRoleList(new Error("Failed to fetch roles"));
      this.resolveRoleList = null;
      this.rejectRoleList = null;
    }
  }

  handleIndustryApiResponse = (responseJson: any) => {
    if (responseJson.industries) {
      if (this.resolveIndustryList) {
        this.resolveIndustryList(responseJson);
        this.resolveIndustryList = null;
        this.rejectIndustryList = null;
      }
    } else if (this.rejectIndustryList) {
      this.rejectIndustryList(new Error("Failed to fetch industries"));
      this.resolveIndustryList = null;
      this.rejectIndustryList = null;
    }
  };

  handleCategoryApiResponse = (responseJson: any) => {
    if (responseJson.data) {
      if (this.resolveCategoryList) {
        this.resolveCategoryList(responseJson);
        this.resolveCategoryList = null;
        this.rejectCategoryList = null;
      }
    } else if (this.rejectCategoryList) {
      this.rejectCategoryList(new Error("Failed to fetch categories"));
      this.resolveCategoryList = null;
      this.rejectCategoryList = null;
    }
  };

  handleSubCategoryApiResponse = (responseJson: any) => {
    if (responseJson.data) {
      if (this.resolveSubCategoryList) {
        this.resolveSubCategoryList(responseJson);
        this.resolveSubCategoryList = null;
        this.rejectSubCategoryList = null;
      }
    } else if (this.rejectSubCategoryList) {
      this.rejectSubCategoryList(new Error("Failed to fetch subCategories"));
      this.resolveSubCategoryList = null;
      this.rejectSubCategoryList = null;
    }
  };

  handleCitiesApiResponse = (responseJson: any) => {
    if (responseJson.data) {
      if (this.resolveCityList) {
        this.resolveCityList(responseJson);
        this.resolveCityList = null;
        this.rejectCityList = null;
      }
    } else if (this.rejectCityList) {
      this.rejectCityList(new Error("Failed to fetch cities"));
      this.resolveCityList = null;
      this.rejectCityList = null;
    }
  }

  handleTypesApiResponse = (responseJson: any) => {
    if (responseJson.data) {
      if (this.resolveTypeList) {
        this.resolveTypeList(responseJson);
        this.resolveTypeList = null;
        this.rejectTypeList = null;
      }
    } else if (this.rejectTypeList) {
      this.rejectTypeList(new Error("Failed to fetch types"));
      this.resolveTypeList = null;
      this.rejectTypeList = null;
    }
  }

  getToFavriouteData(addFavId: string) {
    const token = localStorage.getItem("accessToken")

    const getNewsFeedMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addFavDataCallId = getNewsFeedMessage.messageId;

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AddToFavIdPoint + addFavId + '/mark_as_favorite'
    );

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: `${token}`,
      })
    );

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(getNewsFeedMessage.id, getNewsFeedMessage);
  }

  addedReport = () => {
    const token = localStorage.getItem("accessToken")
    let data = {
      post_id: this.state.selectedpostId,
      flag_category_id: this.state.reportId
    }

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addReportPostId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getReportApiPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  addedToBlock = () => {
    const token = localStorage.getItem("accessToken")
    let data = {
      blocker_id: this.state.selectedpostId,
      reason_title: this.state.reasonTitle,
      reason_description: this.state.reasonDescription
    }

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addBlockUserId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addToBlockApiPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  calculateTimeGap = (createdAt: string): string => {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const diffInMillis = currentDate.getTime() - createdDate.getTime();
    const diffInSeconds = diffInMillis / 1000;
    const diffInMinutes = diffInSeconds / 60;
    const diffInHours = diffInMinutes / 60;
    const diffInDays = diffInHours / 24;
    const diffInWeeks = diffInDays / 7;

    if (diffInMinutes < 1) {
      return 'few mins ago';
    } else if (diffInHours < 1) {
      return `${Math.floor(diffInMinutes)}m`;
    } else if (diffInHours < 24) {
      if (diffInHours < 1.5) {
        return 'few hrs ago';
      }
      return `${Math.floor(diffInHours)}h`;
    } else if (diffInDays < 7) {
      return `${Math.floor(diffInDays)}d`;
    } else {
      return `${Math.floor(diffInWeeks)}w`;
    }
  };
  likeComment = (e: any) => {
    const header = {
      "Content-Type": configJSON.commentsContentType,
      token: localStorage.getItem("accessToken")
    };

    const attrs = {
      "post_id": e.attributes.post_id,
      "likeable_id": e.id,
      "likeable_type": "BxBlockMentionsAndTagging::Comment"
    };
    const data = {
      "type": e.attributes.is_liked ? "unlike" : "like",
      "attributes": attrs
    };

    const finaldata = {
      data
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.likeCommentCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.likeCommentapi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(finaldata)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  initializeCommentReplyOpen = () => {
    const commentReplyOpen = this.state.commentData.map(() => false);
    this.setState({ commentReplyOpen });
  };
  handleReplyCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ replyComment: event.target.value});
  };
  handleReplycomment = () => {
    const { replyComment, commentData } = this.state;
    if (replyComment.trim()) {
      const newReply = {
        attributes: {
          comment: replyComment,
          post_id: this.state.selectedPostId,
          parent_comment_id: this.state.commentID,
        }
      };

      const updatedComments = commentData.map((comment: any) => {
        if (comment.attributes.id === this.state.commentID) {
          return {
            ...comment,
            attributes: {
              ...comment.attributes,
              replies: [newReply, ...comment.attributes.replies]
            }
          };
        }
        return comment;
      });

      this.setState({
        commentData: updatedComments,
        replyCommentString: this.state.replyComment,
        replyComment: '',
        commentReplyOpen: []
      }, () => {
        this.createReplyComment();
      });
    }
    this.getCommentData(this.state.selectedPostId)
  }
  handleReplySection = (index: number, commentId: number, elementID: number) => {
    const updatedCommentReplyOpen = this.state.commentReplyOpen.map((isOpen: boolean, i: number) => (i === index ? !isOpen : isOpen));
    this.setState({ commentReplyOpen: updatedCommentReplyOpen, commentID: commentId ,isReplyOpen: true, openForElement: elementID });
  };
  fetchUsers = (data: { accounts: { id: number; first_name: string; last_name: string; profile_picture: { url: string } }[] }) => {
    const mappedUsers: accounts[] = data.accounts.map((user) => ({
      id: user.id,
      display: `${user.first_name} ${user.last_name}`,
      profile_picture: user.profile_picture.url,
    }));
    this.setState({ users: mappedUsers });
  };

  handleShareCommentClick = (id: number) => {
    this.setState({ selectedComment: id, open3: true });
  };
  drawerSidebaarClose3 = () => {
    this.setState({ open3: false, isCommentDrawerOpen: false, selectedmember3: [], shareconnectiondata2: [] })
  }
  
  onClickEmoji(){
    this.setState({showEmoji: !this.state.showEmoji})
  }
  EmojiClick(emojiObject:any){
  const { comment } = this.state;
    const newText = comment.slice(0) + emojiObject.emoji 
    this.setState({ comment: newText, showEmoji: false });
  }

  onClickReplyEmoji(){
    this.setState({replyShowEmoji: !this.state.replyShowEmoji});
  }
  ReplyEmojiClick(emojiObject:any){
  const { replyComment } = this.state;
    const newText = replyComment + emojiObject.emoji 
    this.setState({ replyComment: newText, replyShowEmoji: false });
  }
  handleClickOutside=(event: any)=>{
    this.setState({showEmoji: false, replyShowEmoji: false,});
    document.removeEventListener('click', this.handleClickOutside);
  }
  
  // Customizable Area End
  
}
