import React from "react";

// Customizable Area Start
import {
  Box,
  styled,
  Container,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Button,
  Hidden,
  IconButton,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { Toaster } from 'react-hot-toast';
// Customizable Area End

import PrivacySettingsController, {
  Props,
} from "./PrivacySettingsController.web";

class PrivacySettings extends PrivacySettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  privacySettingsForWeb() {
    return (
      <Setting>
        <Box className="outerBox">
          <Box className="arrow">
            <a style={{ color: "inherit", textDecoration: "none" }} href="/Settings">
              <ArrowBack />
            </a>
          </Box>
          <Toaster
            position="top-center"
            reverseOrder={false}
          />
          <Box className="heading">Privacy Settings</Box>
          <Box className="mainBox">
            <Box className="items">
              <Typography style={{ fontFamily: "MyFont"}} className="account">Profile</Typography>
            </Box>
            <Box className="items">
              <Box className="icon-content">
                <Typography  style={{ fontFamily: "MyFont"}}>
                  Who can see my profile information?
                </Typography>
              </Box>
              <Box className="icon-content">
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="privacy"
                    name="privacy1"
                    value={this.state.privacyOption}
                    onChange={this.handleRadioChange}
                  >
                    <FormControlLabel
                      value="public"
                      control={<Radio style={{ color: "#73A58E" }} />}
                      label="Public: Everyone on Kella"
                      style={{ marginTop: 5 }}
                    />
                    <FormControlLabel
                      value="private"
                      control={<Radio style={{ color: "#73A58E" }} />}
                      label="Private: My Circle only"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "20px",
                  marginTop: "30%",
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#14362E",
                    color: "white",
                    textTransform: "none",
                    width: "40%",
                    height: "46px",
                    fontSize: "18px",
                    fontWeight: 900,
                    fontFamily: "MyFont",
                    letterSpacing: "0.05em",
                  }}
                  onClick={this.updatePrivacySettings}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Setting>
    );
  }

  privacySettingsForMobile() {
    return (
      <Box style={{ marginTop:"20px", minHeight: "100vh" }}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            position: "relative",
            paddingLeft: 0,
            paddingRight: 0,
            borderBottom: "2px solid #E2E8F0",            
          }}
        >
          <IconButton style={{ position: "absolute", left: 0 }}>
            <a href="/Settings"
              style={{ textDecoration: "none", color: "inherit" }} >
              <ArrowBack />
            </a>
          </IconButton>
          <Toaster
            position="top-center"
            reverseOrder={false}
          />
          <Typography variant="h6" style={webStyle.mobileHeading}>
            Privacy Settings
          </Typography>
          <div style={{ width: 48 }}></div>{" "}
        </Box>

        <Box
          style={{
            maxWidth: "600px",
            margin: "0 auto",
            padding: "15px",
            borderRadius: "16px",
            backgroundColor: "#FFFFFF",            
          }} >
          <Box style={webStyle.mobileMainBox}>
            <Box style={{ position: "relative", ...webStyle.mobileItems, }}>
              <Typography style={webStyle.mobileAccount}>Profile</Typography>
            </Box>

            <Box style={{ ...webStyle.mobileItems, position: "relative" }}>
              <Box style={webStyle.mobileIconContent}>
                <Typography style={webStyle.mobileContent}>
                  Who can see my profile information?
                </Typography>
              </Box>
              <Box style={webStyle.mobileIconContent}>
                <FormControl
                  component="fieldset"
                  style={{
                    borderBottom: "1px solid #E2E8F0",
                    marginBottom: "15px",
                  }}
                >
                  <RadioGroup
                    name="privacy1"
                    aria-label="privacy"
                    value={this.state.privacyOption}
                    onChange={this.handleRadioChange} >
                    <FormControlLabel
                      label="Public: Everyone on Kella"
                      value="public"
                      style={{ marginTop: 5,fontFamily: "MyFont", cursor: "pointer"}}
                      control={<Radio style={{ color: "#73A58E" }} />}
                    />
                    <FormControlLabel
                      value="private"
                      label="Private: My Circle only"
                      style={{fontFamily: "MyFont"}}
                      control={<Radio style={{ color: "#73A58E", cursor:"pointer" }} />}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box
                style={{
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "fixed",                  
                  padding: "20px",
                }}
              >
                <Button
                  variant="contained"
                  style={{  color: "white",
                    width: "850%",
                    height: "45px",
                    backgroundColor: "#14362E",                   
                    textTransform: "none",                  
                    fontSize: "18px",
                    fontWeight: 900,
                    letterSpacing: "0.05em",
                    fontFamily: "MyFont"
                  }}
                  onClick={this.updatePrivacySettings} >
                  Save
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Container maxWidth={false}>
        <Hidden smDown>{this.privacySettingsForWeb()}</Hidden>
        <Hidden mdUp>{this.privacySettingsForMobile()}</Hidden>
      </Container>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

export default PrivacySettings;

const Setting = styled(Box)({
  "& .outerBox": {
    width: "100%",
    height: "100%",
  },
  "& .arrow": {
    display: "flex",
    alignItems: "center",
    height: "48px",
    padding: "0px 2%",
    backgroundColor: "#FFFFFF",   
  },
  "& .heading": {
    display: "flex",
    alignItems: "center",
    paddingLeft: "6%",
    color: "#14362E",
    height: "56px",
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: "MyFont",
    backgroundColor: "#FFFFFF",    
  },
  "& .mainBox": {
    width: "816px",
    padding: "24px 40px",
    margin: "2% auto",
    borderRadius: "16px",
    backgroundColor: "#F8FAFC",  
    height: "450px"
  },
  "& .items": {
    // display: "flex",
    alignItems: "center",
    height: "42px",
    marginLeft: "10%",
    marginRight: "10%",
    padding: "2px 0px",   
    position: "relative",    
    // borderBottom: '1px solid #E2E8F0',
  },
  "& .account": {
    color: "#334155",
    fontSize: "16px",
    fontWeight: 800,
    fontFamily: "MyFont",  
    letterSpacing: "0.08em",
  },
  "& .icon-content": {
    gap: "10px",
    // display: "flex",
    alignItems: "center",
  },
  "& .form-control": {
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
  },

  "& .content": {
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: 800,
    fontFamily: "MyFont",
    letterSpacing: "0.05em",
  },
});

const webStyle = {
  mobileHeading: {
    height: "56px",
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: "MyFont",
    backgroundColor: "#FFFFFF",
    color: "#14362E",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%", // Ensures the text can use the full width for centering
  },
  mobileMainBox: {
    backgroundColor: "#FFFFFF",
    margin: "1% auto",
    borderRadius: "16px",
  },
  mobileItems: {
    height: "42px",
    // borderBottom: "1px solid #E2E8F0",
    // display: "flex",
    alignItems: "center",
    marginRight: "40px",
    marginTop: "18px",
  },
  mobileAccount: {
    fontSize: "18px",
    fontWeight: 900,
    fontFamily: "MyFont",
    color: "#334155",
    marginBottom: "18px",
    letterSpacing: "0.05em",
  },
  mobileIconContent: {
    gap: "10px",
    // display: "flex",
    alignItems: "center",
    marginBottom: "18px",
  },
  mobileContent: {
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "MyFont",
    marginButtom: "10px",
    letterSpacing: "0.05em",
  },
  mobileContact: {
    height: "120px",
    paddingTop: "28px",
    display: "flex",
    alignItems: "center",
  },
  mobileIconContentContact: {
    gap: "10px",
    display: "flex",
    alignItems: "center",
    marginBottom: "18px",
    // marginRight: "-200px"
  },
  contactUsDrawerTxt: {
    fontFamily: "MyFont",
    letterSpacing: "1.4",
    color: "#1E1E1E",
    fontSize: "14px",
    marginBottom: "20px",
  },
  contactUsDrawerInputLabel: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#334155",
  },
  contactDrawerSubmitBtn: {
    height: "45px",
    color: "white",
    fontWeight: 700,
    fontSize: "16px",
    borderRadius: "8px",
    fontFamily: "'MyFont', 'Halyard Display'",
    letterSpacing: "1px",
    bottom: "10px",
    //   marginTop: "25%",
  },
};
// Customizable Area End
