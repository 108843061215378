import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Stepper,
  Step,
  StepButton,
  Drawer,
  Hidden,
  styled,
  OutlinedInput
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";
import {newlogo} from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
      fontFamily: "'MyFont', 'Halyard Display'",
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Subscriptionbilling2Controller, {
  Props,
  configJSON,
} from "./Subscriptionbilling2Controller.web";

export default class Subscriptionbilling2 extends Subscriptionbilling2Controller {
  componentWillUnshallow() {
	  throw new Error('Method not implemented.');
  }
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  PlanSwitchButton = () => {
    return (
      <Box display="flex" alignItems="center">
        <Typography
          variant="body1"
          onClick={() => this.setState({
            isAnnualPlan: true
          })}
          style={{
            cursor: 'pointer',
            fontWeight: this.state.isAnnualPlan ? 'bold' : 'normal',
            color: this.state.isAnnualPlan ? 'black' : '#888',
            marginRight: '10px',
          }}
        >
          Annual
        </Typography>

        <Box
          onClick={()=>this.handleSelectPlanToggle()}
          style={{
            width: '60px',
            height: '30px',
            background: '#14362E',
            borderRadius: '30px',
            position: 'relative',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            style={{
              width: '26px',
              height: '26px',
              background: '#ffffff',
              borderRadius: '50%',
              position: 'absolute',
              top: '2px',
              left: this.state.isAnnualPlan ? '2px' : '32px',
              transition: 'left 0.3s ease',
            }}
          />
        </Box>

        <Typography
          variant="body1"
          onClick={() => this.setState({
            isAnnualPlan: false
          })}
          style={{
            cursor: 'pointer',
            fontWeight: !this.state.isAnnualPlan ? 'bold' : 'normal',
            color: !this.state.isAnnualPlan ? 'black' : '#888',
            marginLeft: '10px',
          }}
        >
          Monthly
        </Typography>
      </Box>)
  }

  SubscriptionStep1Web=()=>{
    const selectedPlan = this.state.isAnnualPlan ? "annual" : "monthly"
    
    return(
      <Grid container spacing={4} style={{width:"100%", margin:"0px"}}>
        <Grid item xs={12}
              style={{
                padding: "50px 0px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                gap: "25px",
          }}>
              <img
                src={newlogo}
                style={{ height: "45.59px",width: "249.88px", }}
              /> 
              <Box style={{ height: "34.59px", width: "277px",}}>
                <Stepper activeStep={this.state.stepperStep}
                  style={{display:"flex", paddingTop:"0px", gap:"5px"}}
                >
                  <Step>
                    <StepButton></StepButton>
                  </Step>
                  <Step>
                    <StepButton></StepButton>
                  </Step>
                  <Step>
                    <StepButton></StepButton>
                  </Step>
                </Stepper>
              </Box>
              <Typography style={{ fontSize: "20px", color: "#14362E", fontFamily: "MyFont !important", letterSpacing: "1.3", fontWeight:700 }}>KELLA Your Way</Typography>
            
            {this.PlanSwitchButton()}
          <Box style={{ height:"auto", width:"704px",}}>
            <Box style={{ justifyContent: "center", marginTop:"8px",display:"flex",}}>
            {
                    this.state.subscriptions && this.state.subscriptions
                    .filter((plans: any) => plans.interval === selectedPlan)
                    .map((plans: any) => {

                      const isSelected = this.state.selectedPlanId === plans.id;

                      return <Box
                        key={plans.id}
                        onClick={() => this.handlePlanSelection(plans.id)}
                        style={{ ...webStyle.box1LayoutStep1 as any, border: isSelected ? "1px solid #14362E" : "1px solid transparent", marginRight: "10px", cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                        alignItems: "center",
                        flexDirection: "column"
                         }}
                      >
                          <Box>
                          <Box style={{
                            marginLeft: "5px",
                            marginBottom: "10px",
                            marginTop: "10px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            height: "60px"
                          }}>

                            <Typography style={{
                              ...webStyle.boxTextFormat,
                              fontSize: "16px",
                            }}
                              data-test-id="plans-title">
                              {plans.name}
                            </Typography>
                            <Typography style={{
                              ...webStyle.boxTextFormat,
                              ...webStyle.textBolder
                            }}
                              data-test-id="plans-subtitle">{plans.title}
                            </Typography>
                          </Box>

                          <Box style={{ ...webStyle.flexContainer, ...webStyle.topPadding, ...webStyle.planBoxWrapper}}>
                            <Box style={{ ...webStyle.planBoxContainer }}>
                              {plans.features.map((item: { title: string }) =>
                                <Box style={{ ...webStyle.flexContainer, height: "50px" }}>
                                  <CheckCircleIcon style={webStyle.checkCircleIcon} />
                                  <Typography style={{ ...webStyle.boxTextFormat }}>
                                    {item.title}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                          </Box>
                          <Box style={{ ...webStyle.amountBox,}}>
                            <Box style={{ height: "40px"}}>
                            <Box style={{ ...webStyle.amountInnerBox, width: "212px" }}>
                              <CustomTypography style={webStyle.amountText}>&#x20AC;{plans.amount}</CustomTypography>
                              <Typography style={{ lineHeight: "16px", fontWeight: 400, fontSize: "11px", fontFamily: "MyFont", textAlign: "center", color: "#94A3B8", paddingTop: "6px" }}>+{plans.vat_amount}</Typography>
                              <Typography style={{ lineHeight: "22px", fontWeight: 500, fontSize: "14px", fontFamily: "MyFont", textAlign: "end", color: "#64748B", paddingTop: "2px" }}> / {plans.interval}</Typography>
                            </Box>
                          <Typography style={{
                            alignSelf: "flex-start",
                            fontSize: "11px",
                            display: (plans?.crossed_amount
                              && plans?.interval === "annual") ? "block" : "none",
                            fontFamily: "MyFont"
                          }}>
                            <span style={{textDecoration: "line-through"}}>€{plans?.crossed_amount}</span> only pay for 11 months
                          </Typography>
                          </Box>
                            <Box onClick={() => this.handleViewDrawer1(plans.id)} style={{ width: "154px", height: "22px", top: "3px" }}>
                              <Typography style={{
                                lineHeight: "22px",
                                fontWeight: 700,
                                color: "#14362E",
                                cursor: "pointer",
                                fontSize: "12px",
                                fontFamily: "MyFont",
                                textAlign: "end",
                              }}>View details</Typography>
                            </Box>
                          </Box>
                        </Box>
                    })
            }
            </Box>
          <Box sx={webStyle.subsciptionBenefitText}>
            <Typography style={{ ...webStyle.benefitTypography }}
              onClick={() => this.setState({
                isOpenBenefitDrawer: true
              })}
            >
              What's included with all subscriptions?
            </Typography>
          </Box>
          </Box>
            <Box style={{width:"320px",paddingTop:"40px"}}>
              {
                this.state.showButton === "Select plan" &&(
                  <Button variant="contained" style={{...webStyle.step1ButtonStyle, textTransform: "capitalize"}} onClick={() => this.handleViewDrawerClose()}>Select plan</Button>
                )
              }
              {
                this.state.showButton === "Confirm Plan" && (
                  <Button variant="contained" style={{...webStyle.step1ButtonStyle, textTransform: "capitalize"}} onClick={this.handlestep1}>Confirm Plan</Button>
                )
              }
            </Box>
          </Grid>
          {this.viewDetailDrawerweb()}
          {this.benefitsOfPlanDetailDrawerweb()}
        </Grid>
    )
  }

  SubscriptionStep1Mobile=()=>{
    const selectedPlan = this.state.isAnnualPlan ? "annual" : "monthly"

    return(
      <Grid container spacing={4} style={{width:"100%", margin:"0px"}}>
      <Grid item xs={12}
            style={{
              alignItems: "center",
              gap: "25px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
        }}>
            <img
              src={newlogo}
              style={{ width: "249.88px", height: "45.59px" }}
            /> 
             <Box style={{ height: "34.59px", width: "277px"}}>
              <Stepper activeStep={this.state.stepperStep}
              style={{display:"flex", paddingTop:"0px", gap:"5px"}}
              >
                <Step>
                  <StepButton></StepButton>
                </Step>
                <Step>
                  <StepButton ></StepButton>
                </Step>
                <Step>
                  <StepButton ></StepButton>
                </Step>
              </Stepper>
            </Box>
            <Typography style={{ fontSize: "20px", color: "#14362E", fontFamily: "MyFont !important", letterSpacing: "1.3", fontWeight:500 }}>KELLA Your Way</Typography>

            {this.PlanSwitchButton()}

            <Box style={{ 
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              width: "344px"
              }}>  
          <Box style={{ 
            justifyContent: "center",
            height:"auto", 
            width:"344px",
            marginTop:"8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            }}>
          {
                  this.state.subscriptions && this.state.subscriptions
                  .filter((plans: any) => plans.interval === selectedPlan)
                  .map((plans: any) => {

                      const isSelected = this.state.selectedPlanId === plans.id;

                     return <Box
                       key={plans.id}
                       onClick={() => this.handlePlanSelection(plans.id)}
                       style={{ ...webStyle.box1LayoutStep1 as any, border: isSelected ? "1px solid #14362E" : "1px solid transparent", cursor: "pointer",
                       display: "flex",
                       justifyContent: "space-between",
                       gap: "20px",
                       alignItems: "center",
                       flexDirection: "column"
                        }}
                     >
                      <Box>
                         <Box style={{ width: "100%",
                          height: "60px",
                           marginLeft: "5px",
                           marginBottom: "10px",
                           marginTop: "10px",
                           display: "flex",
                           flexDirection: "column",
                           gap: "8px",
                         }}>
                       <Typography style={{ ...webStyle.boxTextFormat, 
                          fontSize: "16px"
                          }}
                          data-test-id="plans-title">{plans.name}</Typography>
                         <Typography style={{
                           ...webStyle.boxTextFormat,
                           ...webStyle.textBolder
                         }}
                           data-test-id="plans-subtitle">{plans.title}
                         </Typography>
                         </Box>
                         <Box style={{ ...webStyle.flexContainer, ...webStyle.topPadding, ...webStyle.planBoxWrapper}}>
                         <Box style={{ ...webStyle.planBoxContainer }}>
                              {plans.features.map((item:any) =>
                                <Box style={{ ...webStyle.flexContainer, 
                                 height: "50.1px"}}>
                                  <CheckCircleIcon 
                                   style={webStyle.checkCircleIcon} />
                                  <Typography 
                                  style={{ ...webStyle.boxTextFormat }}>
                                    {item.title} </Typography>
                                </Box>
                              )}
                            </Box>
                         </Box>
                         <Box style={{ height :"40px", display: "flex" }}>
                         <Box sx={{ ...webStyle.amountBoxMobile}}>
                           <Box style={{ ...webStyle.amountInnerBox, gap: "5px" }}>
                             <CustomTypography style={webStyle.amountText}>&#x20AC;{plans.amount}</CustomTypography>
                             <Typography style={{ fontWeight: 400,paddingTop: "6px",lineHeight: "16px", fontFamily: "MyFont", textAlign: "center",fontSize: "11px", color: "#94A3B8",  }}>+{plans.vat_amount}</Typography>
                             <Typography style={{ textAlign: "end",fontWeight: 500, fontFamily: "MyFont", color: "#64748B", fontSize: "14px", paddingTop: "2px", lineHeight: "22px" }}> / {plans.interval}</Typography>
                           </Box>
                           <Typography style={{
                            alignSelf: "flex-start",
                            fontSize: "11px",
                            display: (plans?.crossed_amount
                              && plans?.interval === "annual") ? "block" : "none",
                            fontFamily: "MyFont"
                          }}>
                            <span style={{textDecoration: "line-through"}}>€{plans?.crossed_amount}</span> only pay for 11 months
                          </Typography>
                          </Box>
                           <Box onClick={() => this.handleViewDrawer1(plans.id)} style={{top: "3px", height: "22px"}}>
                             <Typography style={{
                               fontFamily: "MyFont",
                               color: "#14362E",
                               textAlign: "end",
                               lineHeight: "22px",
                               fontSize: "12px",
                               fontWeight: 700,
                               cursor: "pointer",
                             }}>View details</Typography>
                           </Box>
                         </Box>
                      </Box>
                     </Box>
                    })
            }
          </Box>
          <Box sx={webStyle.subsciptionBenefitText}>
            <Typography style={{ ...webStyle.benefitTypography }}
              onClick={() => 
                this.setState({
                isOpenBenefitDrawer: true
              })}
            >What's included with all subscriptions?</Typography>
          </Box>
          <Box style={{height:"96px", width:"344px"}}>
          {
                this.state.showButton === "Select plan" &&(
                  <Button variant="contained" style={{...webStyle.step1ButtonStyle as any, textTransform: "capitalize"}} onClick={() => this.handleViewDrawerClose()}>Select plan</Button>
                )
              }
              {
                this.state.showButton === "Confirm Plan" &&(
                  <Button variant="contained" style={{...webStyle.step1ButtonStyle as any,
                    textTransform: "capitalize"
                  }} onClick={this.handlestep1}>Confirm Plan</Button>
                )
              }
          </Box>
          </Box>
        </Grid>
        {this.viewDetailDrawerMobile()}
        {this.benefitsOfPlanDetailDrawerweb()}
      </Grid>
    )
  }

  benefitsOfPlanDetailDrawerweb = () => {
    return (
      <CustomDrawer
        anchor="right"
        open={this.state.isOpenBenefitDrawer}
        className="mainViewDetailDrawerStyle"
        onClose={this.handleSubscriptionBenefitDrawerClose}
      >

        <Box className="benefitDrawerBoxStyle">
          <Box style={{ height: "64.85px", borderBottom: "1.04px solid #E2E8F0", width: "auto", padding: "0px 4px" }}>
            <Box className="viewdrawerStyle">
              <Box style={{ width: "auto", height: "73.85px", padding: "12px 0px 12x 0px", display: "flex", justifyContent: "space-between", }}>
                <Box style={{ height: "32px", width: "386.07px" }}>
                  <Typography className="TextStyle">All Subscriptions</Typography>
                </Box>
                <Box style={{ height: "49.85px", width: "24.93px", paddingTop: "5px" }}>
                  <CloseIcon onClick={this.handleSubscriptionBenefitDrawerClose} style={{ height: "24.93px", width: "24.93px", cursor: "pointer" }} />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="contentWrapper" style={{
            padding: "16px 20px",
            border: "1px solid #F7D18A",
            borderRadius: "12px",
            margin: "25px 0px",
            paddingBottom: "30px"
          }}>
            <Box style={{
              display: "flex",
              flexDirection: "column",
              gap: "6px",
              marginBottom: "16px"
            }}>
              <Box style={{
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "24px"
              }}>{this.state.subscriptionsBenefitData?.title}</Box>
              <Box style={{
                color: "#64748B",
                fontSize: "12px",
                fontWeight: 500
              }}>{this.state.subscriptionsBenefitData?.sub_title}</Box>
            </Box>
            {
              this.state.subscriptionsBenefitData?.benifit_features?.map((item: any) => {
                return (<>
                  <Box style={{ marginTop: "20px" }}>
                    <Box style={{ display: "flex", gap: "6px" }}>
                      <CheckCircleIcon style={{ height: "16px", fill: "#F7D18A", width: "16px" }} />
                      <Typography style={{ ...webStyle.boxTextFormat }}>
                        {item?.title}
                      </Typography>
                    </Box>
                    <Typography component="div" style={{
                      ...webStyle.boxTextFormat, fontWeight: 500,
                      color: "#64748B", margin: "-12px 0 0 22px"
                    }}
                      dangerouslySetInnerHTML={{ __html: item?.description }} />
                  </Box>
                </>
                )
              })
            }
          </Box>
        </Box>
      </CustomDrawer>
    )
  }

  viewDetailDrawerweb=()=>{
    return(
      <CustomDrawer 
      anchor="right"
      open={this.state.viewDetailDrawer1}
      className="mainViewDetailDrawerStyle"
      onClose={this.handleViewDrawerClose}
      >
        {
            this.state.subscriptions.map((item:any) => {
               return (
                 item.id ==  this.state.drawerPlanId &&  <>  <Box style={{height: "74.85px", borderBottom: "1.04px solid #E2E8F0", width:"auto", padding: "0px 28px" }}>
                 <Box className="viewdrawerStyle">
                 <Box style={{width:"auto",height: "73.85px", padding: "12px 0px 12x 0px",display: "flex", justifyContent:"space-between",}}>
                   <Box style={{height: "32px", width:"386.07px"}}>
                     <Typography className="TextStyle">Plan details</Typography>
                   </Box>
                   <Box style={{height: "49.85px", width:"24.93px",padding:"12px 0px 12px 0px"}}>
                     <CloseIcon onClick={this.handleViewDrawerClose} style={{height: "24.93px", width:"24.93px", cursor:"pointer"}}/>
                   </Box>
                   </Box>
                 </Box>
               </Box>
               <Box style={{height: "auto", willChange: "auto", padding: "0px 28px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center"
               }}>
                 <Box className= "viewdrawerBoxStyle">
                   <Typography className="viewDrawerTypoStyle" style={{margin:"8px",height:"24px", width:"152px",}}>{ item?.name }</Typography>
                   <Typography
                   style={{
                     fontWeight:400,
                     fontSize:"11px",
                     fontFamily:"MyFont",
                     lineHeight:"16px",
                     color:"#000000",
                     margin:"8px",
                     marginTop: "-4px"
                   }}
                   >{item?.title}</Typography>
                   <Box style={{height:"auto", width:"396px", margin:"8px"}}>

                         <Box style={{ ...webStyle.planBoxContainer }}>
                           {item.features.map((item: any) =>
                             <Box style={{ gap: "12px", height: "auto", width: "396px", paddingTop: "2%", display: "flex", paddingBottom: "2px" }}>
                               <CheckCircleIcon style={{ fill: "#73A58E", width: "16px", marginTop: "-3px" }} />
                               <Box style={{ height: "auto", width: "368px" }}>
                                 <Typography style={{ ...webStyle.boxTextFormat, marginBottom: "-4px" }}>
                                   {item?.title}
                                 </Typography>
                                 <Typography style={{ ...webStyle.boxTextFormat, ...webStyle.textBolder, marginBottom: "-4px" }}>{item?.description}</Typography>
                               </Box>
                             </Box>
                           )}
                         </Box> 
                     <Box style={{ paddingTop:"2%",height:"68px", width:"396px",}}>
                       <Box style={{height:"28px", display:"flex", width:"212px", gap:"5px"}}>
                         {
                           this.state.viewPlan ? (
                             <CustomTypography style={{lineHeight:"28px", fontWeight:700,fontSize:"20px",fontFamily:"Montserrat"}}>&#x20AC;{ item.amount }</CustomTypography>
                           ):
                           (<Typography style={{lineHeight:"28px", fontWeight:700,fontSize:"20px",fontFamily:"Montserrat"}}>&#x20AC;3,465</Typography>)
                         }
                         <Typography style={{lineHeight:"16px", fontWeight:400,fontSize:"11px",fontFamily:"MyFont",textAlign:"center",color:"#94A3B8",paddingTop:"6px"}}>+{item.vat_amount} </Typography>
                         <Typography style={{lineHeight:"22px", fontWeight:500,fontSize:"14px",fontFamily:"MyFont",textAlign:"center",color:"#94A3B8",paddingTop:"2px"}}> / {item.interval}</Typography>
                       </Box>
                       <Box style={{height:"16px", width:"auto", display: (item?.crossed_amount && item?.interval === "annual") ? "flex" : "none"}}>
                         <Typography style={{textDecoration:"line-through" ,fontFamily:"MyFont",lineHeight:"16px", fontWeight:400,fontSize:"11px",}}>&#x20AC;{item?.crossed_amount}</Typography>
                         <Typography style={{lineHeight:"16px", fontWeight:400,fontSize:"11px",fontFamily:"MyFont",color:"#000000",marginLeft: "4px", }}>only pay for 11 months</Typography>
                       </Box>
                     </Box>
                   </Box>
                 </Box>
               <Box style={{height:"136px", width:"auto", padding:"20px 0px 50px 0px"}}>
                       <Button variant="contained" className="viewDrawerButton" onClick={() => this.handleViewDrawerClose()}>Select plan</Button>
                     </Box>
               </Box>
               </>
               )
            })
       }
      </CustomDrawer>
    )
  }

  viewDetailDrawerMobile=()=>{
    return(
      <CustomDrawer 
      anchor="right"
      open={this.state.viewDetailDrawer1}
      onClose={this.handleViewDrawerClose}
      >
        {
            this.state.subscriptions.map((item:any) => {
               return (
                 item.id ==  this.state.drawerPlanId &&  <> 
      <Box style={{width:"auto", borderBottom: "1.04px solid #E2E8F0"}}>
        <Box style={{
            height: "73.85px", 
            width:"auto",
            padding: "0px 25px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        }}>
          <Box>
            <Typography className="TextStyle">Plan details</Typography>
          </Box>
          <Box>
            <CloseIcon onClick={this.handleViewDrawerClose} style={{height: "24.93px", width:"24.93px", cursor:"pointer"}}/>
          </Box>
          </Box>
      </Box>
      <Box style={{height: "auto", width:"auto", padding: "0px 32px"}}>
        <Box className= "viewdrawerBoxStyle2">
          <Typography className="viewDrawerTypoStyle" style={{height:"24px", width:"152px",margin:"8px", fontWeight: 800}}>{ item?.name }</Typography>
          <Typography
          style={{
            fontWeight:600,
            fontSize:"11px",
            fontFamily:"MyFont",
            lineHeight:"16px",
            color:"#000000",
            margin:"8px",
            marginTop: "-4px"
          }}
          >{item?.title}</Typography>
          <Box style={{height:"auto", margin:"8px"}}>

          <Box style={{ ...webStyle.planBoxContainer }}>
                           {item.features.map((item: any) =>
                             <Box style={{ gap: "12px", height: "auto", width: "auto", paddingTop: "2%", display: "flex", paddingBottom :"2px" }}>
                               <CheckCircleIcon style={{ fill: "#73A58E", width: "16px", marginTop: "-3px" }} />
                               <Box style={{ height: "auto", width: "368px" }}>
                                 <Typography style={{ ...webStyle.boxTextFormat,
                                  marginBottom: "-4px"
                                  }}>
                                   {item?.title}
                                 </Typography>
                                 <Typography style={{ ...webStyle.boxTextFormat, ...webStyle.textBolder, marginBottom: "-4px" }}>{item?.description}</Typography>
                               </Box>
                             </Box>
                           )}
                         </Box> 
            <Box style={{height:"68px", paddingTop:"2%"}}>
              <Box style={{ display:"flex", height:"28px", width:"212px", gap:"5px"}}>
              {
                  this.state.viewPlan ? (
                    <CustomTypography style={{lineHeight:"28px", fontWeight:700,fontSize:"20px",fontFamily:"Montserrat"}}>&#x20AC;315</CustomTypography>
                  ):
                  (<Typography style={{lineHeight:"28px", fontWeight:700,fontSize:"20px",fontFamily:"Montserrat"}}>&#x20AC;3,465</Typography>)
                }
                <Typography style={{lineHeight:"16px",fontSize:"11px", fontWeight:400,fontFamily:"MyFont",textAlign:"center",color:"#94A3B8",paddingTop:"6px"}}>+VAT </Typography>
                <Typography style={{lineHeight:"22px", fontWeight:500,fontSize:"14px",fontFamily:"MyFont",textAlign:"center",color:"#94A3B8",paddingTop:"2px"}}> / annual</Typography>
              </Box>
              <Box style={{height:"16px", width:"auto", display: (item?.crossed_amount && item?.interval === "annual") ? "flex" : "none" }}>
                <Typography style={{textDecoration:"line-through" ,lineHeight:"16px", fontWeight:400,fontSize:"11px",fontFamily:"MyFont"}}>&#x20AC;{item?.crossed_amount}</Typography>
                <Typography style={{color:"#000000",marginLeft: "4px", lineHeight:"16px", fontWeight:400,fontSize:"11px",fontFamily:"MyFont"}}>only pay for 11 months</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
            <Box style={{ height:"190px", width:"auto",
             display: "flex", justifyContent: "center"}}>
              <Button variant="contained" className="viewDrawerButton2" onClick={() => this.handleViewDrawerClose()}>Select plan</Button>
            </Box>
      </Box>
      </>
            )})
        }
      </CustomDrawer>
    )
  }

  SubscriptionViewWeb=()=>{
    return(
          <Grid container spacing={4} style={{width:"100%", margin:"0px"}}>
             <Grid item xs={12}
              style={{
                padding: "50px 0px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "25px",
              }}>
              <img
                src={newlogo}
                style={{ height: "45.59px", width: "249.88px" }}
              /> 
               <Box style={{ width: "277px", height: "34.59px"}}>
                <Stepper activeStep={this.state.stepperStep}
                style={{display:"flex", paddingTop:"0px", gap:"5px"}}
                >
                  <Step>
                    <StepButton onClick={this.handlestepBack}></StepButton>
                  </Step>
                  <Step>
                    <StepButton ></StepButton>
                  </Step>
                  <Step>
                    <StepButton ></StepButton>
                  </Step>
                </Stepper>
              </Box>
              <Typography style={{ fontSize: "20px", color: "#14362E", fontFamily: "MyFont !important", letterSpacing: "1.3", fontWeight:700 }}>Subscription Billing</Typography>
            
            <Box style={{ justifyContent: "center", 
            height:"auto", width:"816px", marginTop:"-16px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column"
            }}>
                <Box style={{ width:"478px", gap:"10px", display:"flex",height:"52px",
                  justifyContent: "space-between", alignItems: "center"
                }}>
                  <Box style={{height:"24px"}}>
                    <Typography style={{...webStyle.textStyle }}>Membership plan</Typography>
                  </Box>
                  <Box style={{height:"24px"}}>
                    <Typography 
                      style={{...webStyle.textStyle, bottom:"22%", cursor: "pointer", textTransform: "capitalize"}}
                      onClick={this.handlestepBack}
                      >
                        Edit
                    </Typography>
                  </Box>
                </Box>
                <Box style={{ gap:"24px", display:"flex",height:"auto", width:"auto",}}>
                  <Box style={{...webStyle.boxLayout as any}}>
                    <Box style={{gap:"8px", height:"auto", width:"416px"}}>
                    <Typography className="viewDrawerTypoStyle" style={{margin:"8px",height:"24px", width:"152px",}}>{ this.state.selectedPlan?.name }</Typography>
                   <Typography
                   style={{
                     fontWeight:400,
                     fontSize:"11px",
                     fontFamily:"MyFont",
                     lineHeight:"16px",
                     color:"#000000",
                     margin:"8px",
                     marginTop: "-4px"
                   }}
                   >{this.state.selectedPlan?.title}</Typography>
                      {
                        this.state.selectedPlan?.length != 0 && this.state.selectedPlan?.features.map((content :any) => {
                           return (
                            <Box style={{display:"flex", gap:"12px",width:"416px" ,paddingTop:"2%" , height:"auto" }}>
                            <CheckCircleIcon style={{width:"16.1px" ,height:"16.1px" , fill:"#73A58E"}}/>
                            <Box style={{ width:"388px" ,height:"auto"}}>
                              <Typography style={{...webStyle.boxTextFormat}}>{content.title}</Typography>
                              <Typography style={{...webStyle.boxTextFormat, fontWeight:500,
                                 color:"#64748B"}}>{ content.description }</Typography>
                            </Box>
                          </Box>
                           )
                        })
                      }
                  
                    </Box>
                    <Box style={{height:"48px", width:"100%", paddingTop:"20px"}}>
                      <Box  style={{height:"28px", width:"100%", display:"flex", justifyContent: "space-between", alignItems: "center"}}>
                        <Box style={{height:"28px", width:"212px", display:"flex", gap:"5px"}}>
                          <Typography style={{fontWeight:700, lineHeight:"28px", fontSize:"20px",fontFamily:"Montserrat"}}>&#x20AC;{this.state.selectedPlan?.amount}&nbsp;</Typography>
                          <Typography style={{lineHeight:"16px",fontSize:"11px", fontWeight:400,fontFamily:"MyFont",textAlign:"center",color:"#94A3B8",paddingTop:"6px"}}>+{this.state.selectedPlan?.vat_amount}&nbsp;</Typography>
                          <Typography style={{ fontWeight:500,fontSize:"14px",fontFamily:"MyFont", lineHeight:"22px", textAlign:"center",color:"#64748B",paddingTop:"2px"}}>/&nbsp;{ this.state.selectedPlan?.interval}</Typography>
                        </Box>
                        <Box style={{height:"22px", marginLeft:"122px",top:"3px"}}>
                          <Typography style={{lineHeight:"22px", fontWeight:700,fontSize:"14px",fontFamily:"MyFont",textAlign:"center",color:"#14362E",cursor:"pointer"}} 
                          onClick={() => this.handleViewDrawer1(this.state.selectedPlanId)}
                          >View details</Typography>
                        </Box>
                      </Box>
                      <Box style={{height:"16px", width:"416px", display:(this.state.selectedPlan?.crossed_amount && this.state.selectedPlan?.interval === "annual") ? "flex" : "none"}}>
                      <Typography style={{textDecoration:"line-through" ,lineHeight:"16px", fontWeight:400,fontSize:"11px",fontFamily:"MyFont"}}>&#x20AC;{this.state.selectedPlan?.crossed_amount}</Typography>
                      <Typography style={{marginLeft: "4px", lineHeight:"16px", color:"#000000", fontWeight:400,fontSize:"11px",fontFamily:"MyFont"}}>only pay for { this.state.selectedPlan?.period_in_months } months</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
            </Box>
            {this.state.isValidPromoCode &&
              <Box style={{
                width: "441px",
                backgroundColor: "#D4E5DE",
                borderLeft: "4px solid #73A58E",
                color: "#14362E",
                // marginLeft: "-18px",
                display: "flex",
                borderRadius: "4px",
                padding: "12px 16px",
              }}
              >
                <Typography className="boxTextStyle">
                  Promo code: <span style={{ fontWeight: 900 }}>{this.state.orderSummaryDataState?.promo_code || ''}</span>
                </Typography>
              </Box>
            }
              <Box
                style={{
                  width: "484px",
                  height: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
              <Box style={webStyle.promoBox} onClick={this.handlePromo}>
                <Box style={{height:"24px", width:"100%", display:"flex", }}>
                  <CardGiftcardIcon style={{height:"24px", width:"24px"}}/>
                  <Box style={{height:"24px", width:"100%", display:"flex", justifyContent:"space-between"}}>
                    <Typography style={{paddingLeft:"10px", fontWeight:500,fontFamily:"MyFont",
                    color:"#0F172A", 
                    lineHeight:"24px", 
                    fontSize:"16px",
                    }}
                    >
                    Promo Code</Typography>
                  </Box>
                  <Box style={{height:"24px", width:"24px"}}>
                    <ArrowForwardIosIcon style={{height:"15.17px", width:"12.59px"}} />
                  </Box>
                </Box>
              </Box>
            </Box>
            {
              this.state.isBoxOpen ? (
                <Box style={{height:"auto", width:"468px"}}>
                  <Box style={{height:"30px", width:"468px",padding:"12px 0px 12px 0px"}}>
                    <Typography
                    style={{
                    fontWeight:700,
                    fontSize:"16px",
                    lineHeight:"24px", 
                    fontFamily:"MyFont",
                    color:"#334155",
                    height:"24px",
                    width:"468px",
                    }}
                    >
                      Billing Confirmation
                    </Typography>
                  </Box>
                  <Box style={{...webStyle.CheckoutBox, padding:"20px 16px", borderRadius:"16px", backgroundColor:"#F8FAFC" }}>
                    <Box style={{ ...webStyle.CheckoutBox,  width:"436px",height:"auto"}}>
                      <Box style={{height:"22px", width:"436px", justifyContent:"space-between", display:"flex"}}>
                        <Typography style={{height:"22px", width:"90px",
                          lineHeight:"22px", 
                          fontFamily:"MyFont",
                          fontWeight:400,
                          fontSize:"14px",
                        }}>
                        Payment date
                        </Typography>
                        <Typography style={{height:"22px", width:"90px",
                          lineHeight:"22px", 
                          fontFamily:"MyFont",
                          color:"#0F172A",
                          fontWeight:700,
                          fontSize:"14px",
                        }}>
                        {this.state.orderSummaryDataState?.payment_date}
                        </Typography>
                      </Box>
                      <Box style={{height:"22px", width:"436px", justifyContent:"space-between", display:"flex", paddingTop:"2%"}}>
                        <Typography style={{height:"22px", width:"90px",
                          lineHeight:"22px", 
                          fontWeight:400,
                          fontSize:"14px",
                          fontFamily:"MyFont",
                        }}>
                        Plan End date
                        </Typography>
                        <Typography style={{height:"22px", width:"90px",
                          lineHeight:"22px", 
                          fontWeight:700,
                          fontSize:"14px",
                          fontFamily:"MyFont",
                          color:"#0F172A"
                        }}>
                        {this.state.orderSummaryDataState?.plan_end_date}
                        </Typography>
                      </Box>
                      {this.state.orderSummaryDataState?.promo_code_discount !==0 && <Box style={{height:"22px", width:"436px", justifyContent:"space-between", display:"flex", paddingTop:"2%"}}>
                        <Typography style={{height:"22px", width:"91px",
                          lineHeight:"22px", 
                          fontWeight:400,
                          fontSize:"14px",
                          fontFamily:"MyFont",
                        }}>
                        Promo discount
                        </Typography>
                        <Typography style={{height:"22px", width:"90px",
                          lineHeight:"22px",
                          fontFamily:"MyFont",
                          fontWeight:700,
                          fontSize:"14px",
                          color:"#0F172A"
                        }}>
                        - �{this.state.orderSummaryDataState?.promo_code_discount}
                        </Typography>
                      </Box>}
                      <Box style={{height:"22px", width:"436px", justifyContent:"space-between", display:"flex", paddingTop:"2%"}}>
                        <Typography style={{height:"22px", width:"90px",
                          lineHeight:"22px", 
                          fontWeight:400,
                          fontSize:"14px",
                          fontFamily:"MyFont",
                        }}>
                        Total amount
                        </Typography>
                        <Typography style={{height:"22px", width:"90px",
                          fontSize:"14px",
                          fontFamily:"MyFont",
                          color: "#73A58E",
                          lineHeight:"22px", 
                          fontWeight:700,
                        }}>
                        &#x20AC; {this.state.orderSummaryDataState?.total_amount}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ):
              this.state.checkCount === 2 &&(
                this.handlestep2
              )
            }
              {
                this.state.checkCount === 2 &&(
                  this.handlestep2()
                )
              }
            <Box style={{display:"flex"}}>
            <Button variant="outlined" style={{...webStyle.backbuttonStyle as any,  marginRight:"5px",fontFamily: "MyFont ",}} 
            onClick={this.handlestepBack}
            >Back</Button>
            <Button variant="contained" style={{...webStyle.backbuttonStyle as any,
              fontFamily: "MyFont",
              backgroundColor:"#14362E",
             color:"#FFFFFF"}}
            //  onClick={this.handlestep2}
            onClick={this.handleBoxOpen}
             >Checkout</Button>
            </Box>
          </Grid>
          {this.subscriptionDrawerWeb()}
          {this.viewDetailDrawerweb()}

        </Grid>
    )
  }

  SubscriptionViewMobile=()=>{
    return(
      <Grid container spacing={4} style={{width:"100%", margin:"0px"}}>
             <Grid item xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                gap: "25px",
              }}>
              <img
                src={newlogo}
                style={{  height: "45.59px" , width: "249.88px",}}
              /> 
               <Box style={{ height: "34.59px", width: "277px"}}>
                <Stepper activeStep={this.state.stepperStep}
                style={{display:"flex", paddingTop:"0px", gap:"5px"}}
                >
                  <Step>
                    <StepButton onClick={this.handlestepBack}></StepButton>
                  </Step>
                  <Step>
                    <StepButton ></StepButton>
                  </Step>
                  <Step>
                    <StepButton ></StepButton>
                  </Step>
                </Stepper>
              </Box>
              <Typography style={{ fontWeight: 800, color: "#14362E", fontFamily: "MyFont", fontSize: "20px",letterSpacing: "1.3", }}>Subscription Billing</Typography>
            
            <Box style={{ justifyContent: "center", height:"auto", width:"350px", marginTop:"8px"}}>
                <Box style={{height:"48px", width:"350px", gap:"10px", display:"flex"}}>
                  <Box style={{height:"24px", width:"170px"}}>
                    <Typography style={{...webStyle.textStyle, textTransform: "capitalize" }}>Membership plan</Typography>
                  </Box>
                  <Box style={{height:"24px", width:"170px" }}>
                    <Typography style={{...webStyle.textStyle, 
                      paddingLeft:"80%", bottom:"22%", cursor: "pointer", textTransform: "capitalize"}}
                      onClick={this.handlestepBack}
                    >
                      Edit
                    </Typography>
                  </Box>
                </Box>
                <Box style={{height:"auto", width:"350px", gap:"24px", display:"flex"}}>
                  <Box style={{...webStyle.boxLayout2 as any}}>
                    <Box style={{height:"auto", width:"312px", gap:"8px"}}>
                    <Typography className="viewDrawerTypoStyle" style={{margin:"8px",height:"24px", width:"152px"}}>{ this.state.selectedPlan?.name }</Typography>
                   <Typography
                   style={{
                     fontWeight:400,
                     fontFamily:"MyFont",
                     fontSize:"11px",
                     color:"#000000",
                     lineHeight:"16px",
                     marginTop: "-4px",
                     margin:"8px",
                   }}>{this.state.selectedPlan?.title}</Typography>
                      {
                        this.state.selectedPlan?.length != 0 && this.state.selectedPlan?.features.map((content :any) => {
                           return (
                            <Box style={{height:"auto", paddingTop:"2%", gap:"12px", display:"flex", }}>
                            <CheckCircleIcon style={{fill:"#73A58E", height:"16.1px", width:"16.1px"}}/>
                            <Box style={{height:"auto", width:"388px"}}>
                              <Typography style={{...webStyle.boxTextFormat}}>{content.title}</Typography>
                              <Typography style={{...webStyle.boxTextFormat, fontWeight:500, color:"#64748B"}}>{ content.description }</Typography>
                            </Box>
                          </Box>
                           )
                        })
                      }
                    </Box>
                    <Box style={{height:"48px", width:"312px", marginTop:"20px"}}>
                      <Box  style={{height:"28px", width:"100%", display:"flex", justifyContent:"space-between", alignItems: "center"}}>
                        <Box style={{height:"28px", width:"212px", display:"flex", gap:"5px"}}>
                          <CustomTypography style={{lineHeight:"28px", fontWeight:700,fontSize:"20px",fontFamily:"Montserrat"}}>&#x20AC;{this.state.selectedPlan?.amount}</CustomTypography>
                          <Typography style={{lineHeight:"16px", fontWeight:400,fontSize:"11px",fontFamily:"MyFont",textAlign:"center",color:"#94A3B8",paddingTop:"6px"}}>+{this.state.selectedPlan?.vat_amount} </Typography>
                          <Typography style={{lineHeight:"22px", fontWeight:500,fontSize:"14px",fontFamily:"MyFont",textAlign:"center",color:"#94A3B8",paddingTop:"2px"}}> / { this.state.selectedPlan?.interval}</Typography>
                        </Box>
                        <Box style={{height:"22px", top:"3px"}}>
                          <Typography style={{lineHeight:"22px", fontWeight:700,fontSize:"12px",fontFamily:"MyFont",textAlign:"center",color:"#14362E", cursor:"pointer"}}
                          onClick={() => this.handleViewDrawer1(this.state.selectedPlanId)}
                          >View details</Typography>
                        </Box>
                      </Box>
                      <Box style={{height:"16px", width:"312px", display: (this.state.selectedPlan?.crossed_amount && this.state.selectedPlan?.interval === "annual") ? "flex" : "none"}}>
                      <Typography style={{lineHeight:"16px", fontWeight:400,fontSize:"11px",textDecoration:"line-through", fontFamily:"MyFont"}}>&#x20AC;{this.state.selectedPlan?.crossed_amount}</Typography>
                      <Typography style={{color:"#000000",marginLeft: "4px", lineHeight:"16px", fontWeight:400,fontSize:"11px",fontFamily:"MyFont"}}>only pay for { this.state.selectedPlan?.period_in_months }  months</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="promoclass" style={{ width :"350px"}}>
              {this.state.isValidPromoCode &&
              <Box style={{
                width: "auto",
                backgroundColor: "#D4E5DE",
                borderLeft: "4px solid #73A58E",
                color: "#14362E",
                display: "flex",
                borderRadius: "4px",
                padding: "12px 16px",
              }}
              >
                <Typography className="boxTextStyle">
                  Promo code: <span style={{ fontWeight: 900 }}>{this.state.orderSummaryDataState?.promo_code || ''}</span>
                </Typography>
              </Box>
            }
            <Box style={{height:"64px", width:"auto", display:"flex"}} 
            onClick={this.handlePromo}
            >
              <Box style={webStyle.promoBox2}>
                <Box style={{height:"24px", width:"auto", display:"flex"}}>
                  <CardGiftcardIcon style={{height:"24px", width:"24px"}}/>
                  <Box style={{height:"24px", width:"100%"}}>
                    <Typography style={{paddingLeft:"5%", lineHeight:"24px",
                    color:"#0F172A", 
                    fontWeight:500,
                    fontSize:"16",
                    fontFamily:"MyFont"}}
                    >
                    Promo Code</Typography>
                  </Box>
                  <Box style={{height:"24px", width:"24px"}}>
                    <ArrowForwardIosIcon style={{height:"20.17px", width:"12.59px"}} />
                  </Box>
                </Box>
              </Box>
            </Box>
            </Box>
            {
              this.state.isBoxOpen ? (
                <Box style={{height:"216px", width:"350px"}}>
                  <Box style={{height:"48px", width:"350px",}}>
                    <Typography
                    style={{
                    height:"24px",
                    width:"350px",
                    lineHeight:"24px", 
                    fontWeight:700,
                    fontSize:"16px",
                    fontFamily:"MyFont",
                    color:"#334155",
                    }}
                    >
                      Billing Confirmation
                    </Typography>
                  </Box>
                  <Box style={{...webStyle.CheckoutBox, padding:"20px 16px", borderRadius:"20px", backgroundColor:"#F8FAFC" }}>
                    <Box style={{ ...webStyle.CheckoutBox, height:"124px", width:"318px",}}>
                      <Box style={{height:"22px", width:"318px", justifyContent:"space-between", display:"flex", paddingTop:"4%"}}>
                        <Typography style={{height:"22px",
                          lineHeight:"22px",
                          fontFamily:"MyFont",
                          width:"318px",
                        }}>
                        Payment date
                        </Typography>
                        <Typography style={{height:"22px", width:"124px",
                          lineHeight:"22px", 
                          fontWeight:700,
                          fontSize:"14px",
                          fontFamily:"MyFont",
                          color:"#0F172A"
                        }}>
                        {this.state.orderSummaryDataState?.payment_date}
                        </Typography>
                      </Box>
                      <Box style={{height:"22px", width:"318px", justifyContent:"space-between", display:"flex", paddingTop:"2%"}}>
                        <Typography style={{
                          lineHeight:"22px", 
                          fontWeight:400,
                          fontSize:"14px",
                          fontFamily:"MyFont",
                          height:"22px", width:"96px",
                        }}>
                        Plan End date
                        </Typography>
                        <Typography style={{
                          lineHeight:"22px", 
                          fontWeight:700,
                          fontSize:"14px",
                          fontFamily:"MyFont",
                          color:"#0F172A",
                          height:"22px", width:"90px",
                        }}>
                        {this.state.orderSummaryDataState?.plan_end_date}
                        </Typography>
                      </Box>
                      {this.state.orderSummaryDataState?.promo_code_discount !==0 && <Box style={{height:"22px", width:"318px", justifyContent:"space-between", display:"flex", paddingTop:"2%"}}>
                        <Typography style={{height:"22px", width:"96px",
                          lineHeight:"22px", 
                          fontWeight:400,
                          fontSize:"14px",
                          fontFamily:"MyFont",
                        }}>
                        Promo discount
                        </Typography>
                        <Typography style={{
                          lineHeight:"22px", 
                          fontWeight:700,
                          fontSize:"14px",
                          fontFamily:"MyFont",
                          color:"#0F172A",
                          height:"22px", width:"90px",
                        }}>
                        - �{this.state.orderSummaryDataState?.promo_code_discount}
                        </Typography>
                      </Box>}
                      <Box style={{height:"22px", width:"318px", justifyContent:"space-between", display:"flex", paddingTop:"2%"}}>
                        <Typography style={{
                          lineHeight:"22px", 
                          fontWeight:400,
                          fontSize:"14px",
                          fontFamily:"MyFont",
                          height:"22px", width:"96px",
                        }}>
                        Total amount
                        </Typography>
                        <Typography style={{height:"22px", width:"90px",
                          lineHeight:"22px",
                          fontSize:"14px",
                          fontWeight:700,
                          fontFamily:"MyFont",
                          color: "#73A58E"
                        }}>
                        &#x20AC; {this.state.orderSummaryDataState?.total_amount}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ):
              this.state.checkCount === 2 &&(
                this.handlestep2
              )
            }
              {
                this.state.checkCount === 2 &&(
                  this.handlestep2()
                )
              }
            <Box style={{display:"flex",}}>
            <Button variant="outlined" style={{...webStyle.backbuttonStyle2 as any,  marginRight:"5px",fontFamily: "MyFont ",}} 
            onClick={this.handlestepBack}
            >Back</Button>
            <Button variant="contained" style={{...webStyle.backbuttonStyle2 as any,
              fontFamily: "MyFont",
              backgroundColor:"#14362E",
             color:"#FFFFFF"}} 
             onClick={this.handleBoxOpen}
             >Checkout</Button>
            </Box>
            <Box style={{height:"20px", width:"350px",}}></Box>
          </Grid>
          {this.SubscriptionDrawerMobile()}
          {this.viewDetailDrawerMobile()}
        </Grid>
        
    )
  }  

  subscriptionDrawerWeb=()=>{
    return(
      <CustomDrawer
          anchor="right"
          open={this.state.isPromoOpen}  
          onClose={this.handlepromoClose}
          >
            <Box style={{height: "74.85px", width:"auto", borderBottom: "1.04px solid #E2E8F0", padding: "0px 28px"}}>
              <Box style={{height: "74.85px", width:"auto",padding: "12px 0px 0px 0px",display: "flex", cursor:"pointer", justifyContent:"space-between" }}>
                <Box style={{height: "32px", width:"386.07px"}}>
                  <Typography className="TextStyle">Promo Code</Typography>
                </Box>
                <Box style={{height: "49.85px", width:"24.93px",}}>
                  <CloseIcon onClick={this.handlepromoClose} style={{height: "24.93px", width:"24.93px", cursor:"pointer"}}/>
                </Box>
              </Box>
            </Box>
            <Box style={{height: "78%", width:"auto", padding: "0px 28px"}}>
              <Box style={{
              width:"auto", 
              backgroundColor: this.state.isValidPromoCode ? "#D4E5DE" : "#EEC3BE",
              borderLeft: this.state.isValidPromoCode ? "4px solid #73A58E" : "4px solid #D1584C", 
              color: this.state.isValidPromoCode ? "#73A58E" : "#D1584C",
              display: this.state.isShowPromoCodeValidation ? "flex" : "none",
              borderRadius: "4px",
              padding: "0px 0px 0px 8px",
              height: "48px",
              justifyContent: "space-between",
              marginTop: "20px",
              alignItems: "center"
            }}
              >
                <Typography className="boxTextStyle">
                  {this.state.isValidPromoCode ?
                    "Promo code added" :
                    "Invalid promo code. Please try again with a valid promo code."
                  }
                </Typography>
                <Box style={{ height: "16px", width: "24px"}}
                  onClick={ () => this.setState({
                    isShowPromoCodeValidation: false
                  })}
                >
                  <CloseIcon style={{height: "16px", width:"16px", cursor:"pointer"}} />
                </Box>
              </Box>
              <Box style={{
                    height: "86px", width:"420px", gap:"12px", 
                    marginTop: "12px", display: "flex", flexDirection: "column"}}>
                <Typography className="Typostyle" style={{
                  width:"109px",height: "26px",
                  fontFamily:"Montserrat",
                  fontSize: "18px",
                  fontWeight: 700
                }}>Promo code</Typography>
                <Box style={{border: "1.07px solid #CBD5E1",
                    borderRadius: "8.55px",}}>
              <OutlinedInput
                data-test-id={"promoCodeInputName"}
                value={this.state.addPromeCode} 
                onChange={(e) => this.handleChangeAddPromoCode(e.target.value)}
                endAdornment={<CardGiftcardIcon
                  style={{ height: "20.31px", color: "#64748B",
                   width: "21.38x" }} />}
                   style={{
                    height: "50px", width:"auto", 
                    display:"flex",
                    border: "1.07px solid #CBD5E1",
                    borderRadius: "8.55px",
                   }}
              />
                </Box>
              </Box>
              <hr style={{width:"428x", border: "1px solid #CBD5E1", margin: "24px 0px"}}/>
              {this.state.isValidPromoCode && <Box style={{
                  height: "auto",
                  width:"auto",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column"
                  }}> 
                <Box style={{height: "48px", width:"428px",border:"0p 0px 1px 0px", padding:"12px, 0px, 12px, 0px"}}>
                  <Typography style={{height: "24px", width:"428px", fontWeight: 700, fontSize: "16px"}} className="Typostyle"
                  >Coupon details</Typography>
                </Box>
                <Box style={{height: "auto", width:"auto",
                  padding:"20px, 16px, 20px, 16px",
                  background: "#F8FAFC",
                  borderRadius: "16px"
                }}
                >
                  <Box style={{
                    justifyContent:"space-between", height: "18px", width:"auto", display:"flex", margin:"15px"}}>
                    <Box style={{height:"18px"}}>
                    <Typography className="boxTypoStyle1">Promo code</Typography>
                    </Box>
                    <Box style={{height:"18px"}}>
                    <Typography className="boxTypoStyle2">{this.state.orderSummaryDataState?.promo_code}</Typography>
                    </Box>
                  </Box>
                  <Box style={{display:"flex",margin:"15px", height: "18px", width:"auto",justifyContent:"space-between"}}>
                    <Typography className="boxTypoStyle1">Amount off</Typography>
                    <Typography className="boxTypoStyle2" style={{color:"#73A58E"}}>-&#x20AC;{this.state.orderSummaryDataState?.promo_code_discount}</Typography>
                  </Box>
                  <Box style={{height: "18px", display:"flex", width:"auto",justifyContent:"space-between", margin:"15px"}}>
                    <Typography className="boxTypoStyle1">Total Amount</Typography>
                    <Typography className="boxTypoStyle2">&#x20AC;{this.state.orderSummaryDataState?.total_amount}</Typography>
                  </Box>
                </Box>
              </Box>}
            </Box>
              <Button className="buttonStyle" variant="contained" 
              style={{
                margin: "0px 28px",
                width: "auto"
              }}
              onClick={this.postAddSubscriptionPromoData}>Add Promo Code</Button>
          </CustomDrawer>
    )
  }

  SubscriptionDrawerMobile=()=>{
    return(
      <CustomDrawer
          anchor="right"
          open={this.state.isPromoOpen}
          onClose={this.handlepromoClose}
          >
            <Box style={{width:"415px", borderBottom: "1px solid #E2E8F0"}}>
              <Box style={{height: "48px", width:"390px",padding: "10px 24px 0px 24px",justifyContent:"space-between",display: "flex", cursor:"pointer"}}>
                <Box style={{height: "48px", width:"48px",}}>
                  <ArrowBackIcon onClick={this.handlepromoClose} style={{height: "24px", width:"24px"}}/>
                </Box>
                <Box style={{height: "28px", width:"265px"}}>
                  <Typography className="TextStyle2">Promo Code</Typography>
                </Box>
              </Box>
            </Box>
            <Box style={{height: "78%", width:"auto", 
                paddingTop: "30px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "30px 20px 30px 20px",
                }}>
              <Box style={{height: "auto", width:"100%", borderRadius: "4px",
              backgroundColor: this.state.isValidPromoCode ? "#D4E5DE" : "#EEC3BE",
              borderLeft: this.state.isValidPromoCode ? "4px solid #73A58E" : "4px solid #D1584C", 
              color: this.state.isValidPromoCode ? "#73A58E" : "#D1584C",
              display: this.state.isShowPromoCodeValidation ? "flex" : "none",
              alignItems: "center",
              justifyContent: "center",
              padding: "12px 8px"
            }}
              >
                <Typography className="boxTextStyle" style={{width: "100%"}}>
                    {this.state.isValidPromoCode ?
                      "Promo code added" :
                      "Invalid promo code. Please try again with a valid promo code."
                    }
                  </Typography>
                <Box style={{width:"24px", paddingTop: "2px"}} 
                 onClick={ () => this.setState({
                  isShowPromoCodeValidation: false
                })}
                >
                  <CloseIcon style={{height: "9.33px", width:"9.33px", left:"3.33px", cursor:"pointer"}}/>
                </Box>
              </Box>
              <Box style={{ height: "89px", 
                width: "100%", gap: "12px", marginTop: "12px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                padding: "0px 20px"
                }}>
                <Typography className="Typostyle" style={{
                  height: "auto", width: "110px",
                  fontFamily: "MyFont",
                  alignSelf: "baseline"
                }}>Promo code</Typography>
                <OutlinedInput
                  data-test-id={"promoCodeInputName"}
                  value={this.state.addPromeCode} 
                  onChange={(e) => this.handleChangeAddPromoCode(e.target.value)}
                  endAdornment={<CardGiftcardIcon
                    style={{
                      height: "20.31px", color: "#64748B",
                      width: "21.38x"
                    }} />}
                  style={{
                    height: "50px", width: "100%",
                    display: "flex",
                    border: "1.07px solid #CBD5E1",
                    borderRadius: "8.55px",
                  }}
                />
              </Box>
              <hr style={{width:"100%", border: "1px solid #CBD5E1"}}/>
              {this.state.isValidPromoCode && <Box style={{height: "166px", width:"100%",margin:"10% 30px 0"}}> 
                <Box style={{height: "40px", width:"350px",border:"0p 0px 1px 0px", padding:"12px, 0px, 12px, 0px"}}>
                  <Typography style={{height: "24px", width:"350px", marginLeft:"0"}} className="Typostyle"
                  >Coupon details</Typography>
                </Box>
                <Box style={{height: "auto", width:"auto",
                  padding:"20px 16px",
                  background: "#F8FAFC",
                  borderRadius: "16px",
                  display: "flex",
                  flexDirection: "column",
                  gap :"12px",
                  justifyContent: "center"
                }}>
                  <Box style={{height: "13px", 
                  width:"auto",
                  justifyContent:"space-between",
                   display:"flex"}}>
                    <Typography className="boxTypoStyle1">Promo code</Typography>
                    <Typography className="boxTypoStyle2">{this.state.orderSummaryDataState?.promo_code}</Typography>
                    
                  </Box>
                  <Box style={{height: "13px", width:"auto",justifyContent:"space-between", display:"flex"}}>
                    <Typography className="boxTypoStyle1">Amount off</Typography>
                    <Typography className="boxTypoStyle2" style={{color:"#73A58E"}}>-&#x20AC;{this.state.orderSummaryDataState?.promo_code_discount}</Typography>
                  </Box>
                  <Box style={{width:"auto",justifyContent:"space-between",height: "13px",  display:"flex"}}>
                    <Typography className="boxTypoStyle1">Total Amount</Typography>
                    <Typography className="boxTypoStyle2">&#x20AC;{this.state.orderSummaryDataState?.total_amount}</Typography>
                  </Box>
                </Box>
              </Box>}
            </Box>
              <Box style={{
                display :"flex", justifyContent :"center"
              }}>
              <Button variant="contained" className="buttonStyle2"
               onClick={this.postAddSubscriptionPromoData}
              >Add Promo Code</Button>
              </Box>
          </CustomDrawer>
    )
  }

  SubscriptionViewStep3Web=()=>{
    return(
      <Grid container spacing={4} style={{width:"100%", margin:"0px"}}>
        <Grid item xs={12}
        style={{
          justifyContent: "center",
          alignItems: "center",
          gap: "25px",
          padding: "50px 0px",
          display: "flex",
          flexDirection: "column",
        }}
        >
          <img
                src={newlogo}
                style={{  height: "45.59px" , width: "249.88px",}}
              />
               <Box style={{ height: "34.59px", width: "277px" }}>
                <Stepper alternativeLabel activeStep={this.state.stepperStep} style={{display:"flex", paddingTop:"0px", gap:"5px"}}>
                  <Step>
                    <StepButton onClick={this.handlestepBack}></StepButton>
                  </Step>
                  <Step>
                    <StepButton onClick={this.handlestepBack} ></StepButton>
                  </Step>
                  <Step>
                    <StepButton></StepButton>
                  </Step>
                </Stepper>
              </Box>
              <Typography style={{ fontSize: "20px", color: "#14362E", fontFamily: "MyFont !important", letterSpacing: "1.3", fontWeight:500 }}>Payment Approved</Typography>
              <Box style={{ height: "155px", marginTop:"50px"}}>
                <CheckCircleOutlinedIcon style={{ height:"129.17px", width:"1279.17px", color:"#73A58E"}}/>
              </Box>
              <Box  style={{ height: "80px", width:"350px", marginTop:"20px"}}>
                <Typography style={{...webStyle.ThankyouBoxStyle as any, fontFamily:"serif"}}>Thank You for Choosing KELLA! </Typography>
              </Box>
              <Box  style={{ height: "26px",  width:"469px"}}>
                <Typography style={{fontSize:"18px",
                fontFamily: "MyFont !important",
                fontWeight:400,
                lineHeight:"26px",
                textAlign:"center",
                color:"#0F172A",
                }}>Your payment has been accepted. Welcome to KELLA </Typography>
              </Box>
              <Box style={{height:"56px", width:"350px",padding:"8%", borderRadius:"8px"}}>
                <Button variant="contained" style={{
                  fontSize:"16px",
                  color:"#FFFFFF",
                  height:"56px",
                  width:"350px",
                  fontFamily: "MyFont !important",
                  fontWeight:500,
                  lineHeight:"24px",
                  textAlign:"center",
                  backgroundColor:"#14362E",
                }}>Continue</Button>
              </Box>
        </Grid>
      </Grid>
    )
  }
  SubscriptionViewStep3Mobile=()=>{
    return(
      <Grid container spacing={4} style={{width:"100%", margin:"0px"}}>
        <Grid item xs={12}
        style={{
          gap: "25px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
        >
          <img
                src={newlogo}
                style={{ width: "249.88px", height: "45.59px" }}
              />
               <Box style={{ width: "277px",height: "34.59px"}}>
                <Stepper activeStep={this.state.stepperStep} 
                style={{display:"flex", paddingTop:"0px", gap:"5px"}}
                >
                  <Step>
                    <StepButton onClick={this.handlestepBack}></StepButton>
                  </Step>
                  <Step>
                    <StepButton onClick={this.handlestepBack} ></StepButton>
                  </Step>
                  <Step>
                    <StepButton></StepButton>
                  </Step>
                </Stepper>
              </Box>
              <Typography style={{ fontSize: "20px",fontFamily: "MyFont !important",  color: "#14362E", letterSpacing: "1.3", fontWeight:500 }}>Payment Approved</Typography>
              <Box style={{ height: "155px", marginTop:"50px"}}>
                <CheckCircleOutlinedIcon style={{ height:"129.17px", color:"#73A58E", width:"1279.17px",}}/>
              </Box>
              <Box  style={{ height: "80px", width:"298px", marginTop:"20px"}}>
                <CustomTypography className="font" style={{...webStyle.ThankyouBoxStyle as any, fontFamily:"MyFont"}}>Thank You for Choosing KELLA! </CustomTypography>
              </Box>
              <Box  style={{ height: "80px",  width:"350px"}}>
                <Typography style={{fontSize:"18px",
                lineHeight:"26px",
                textAlign:"center",
                color:"#0F172A",
                fontFamily: "MyFont ",
                fontWeight:400,
                }}>Your payment has been accepted. Welcome to KELLA </Typography>
              </Box>
              <Box style={{height:"56px", width:"350px",padding:"8%", borderRadius:"8px"}}>
                <Button variant="contained" style={{
                  fontWeight:500,
                  lineHeight:"24px",
                  textAlign:"center",
                  backgroundColor:"#14362E",
                  color:"#FFFFFF",
                  fontSize:"16px",
                  fontFamily: "MyFont ",
                  height:"56px",
                  width:"350px"
                }}>Continue</Button>
              </Box>
        </Grid>
      </Grid>
    )
  }

  // Customizable Area End

  render() {
    console.log("call--",this.state.isOpenBenefitDrawer);
    
    return (
      // Customizable Area Start

      <ThemeProvider theme={theme}>
        <Container maxWidth={false} style={{padding: "10px"}}>

        {this.state.stepperStep === 0 && (
          <>
            <Hidden smDown>{this.SubscriptionStep1Web()}</Hidden>
            <Hidden mdUp>{this.SubscriptionStep1Mobile()}</Hidden>
          </>
        )}
        {this.state.stepperStep === 1 && (
          <>
            <Hidden smDown>{this.SubscriptionViewWeb()}</Hidden>
            <Hidden mdUp>{this.SubscriptionViewMobile()}</Hidden>
          </>
        )}
      </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    textTransform: "capitalize",
    fontFamily:"MyFont !important",
  },
  textStyle: {
    color: "#334155",
    fontSize:"16px",
    fontFamily: "MyFont !important",
    fontWeight:700,
  },
  CheckoutBox:{
    backgroundColor:"#F8FAFC !important",
    borderRadius:"16px",
  },
  box1LayoutStep1:{
    height: "auto",
    width: "334px",
    boxShadow: "0px 8px 32px 0px #0000000F",
    borderRadius: "8px 8px 32px 8px",
    padding:"16px",
    gap:"15px",
  },
  box1LayoutStep2:{
    height: "215px",
    width: "334px",
    boxShadow: "0px 8px 32px 0px #0000000F",
    borderRadius: "8px 8px 32px 8px",
    padding:"16px",
    gap:"15px",
  },
  boxLayout:{
    height: "auto",
    width: "448px",
    border: "1px solid #14362E",
    borderRadius: "8px 8px 32px 8px",
    padding:"16px",
    gap:"16px",
  },
  boxLayout2:{
    height: "auto",
    width: "344px",
    border: "1px solid #14362E",
    borderRadius: "8px 8px 32px 8px",
    padding:"10px",
    gap:"16px",
  },
  boxTextStyle:{
    fontSize:"16px",
    fontFamily: "MyFont !important",
    fontWeight:700,
    letterSpacing:"8%",
    lineHeight:"24px",
  },
  boxTextFormat:{
    fontSize:"12px",
    fontFamily: "MyFont ",
    fontWeight:700,
    letterSpacing:"8%",
    lineHeight:"18px",
  },
  promoBox:{
    height: "24px",
    width: "100%",
    borderTop: "1px solid #E2E8F0",
    borderBottom: "1px solid #E2E8F0",
    padding:"20px 0px 20px 0px",
    gap:"10px !important",
    cursor:"pointer"
  },
  promoBox2:{
    height: "24px",
    width: "350px",
    borderTop: "1px solid #E2E8F0",
    borderBottom: "1px solid #E2E8F0",
    padding:"20px 0px 20px 0px",
    gap:"10px !important",
    cursor:"pointer"
  },
  backbuttonStyle:{
    height: "56px",
    width: "234px",
    border: "1px solid #73A58E",
    padding: "10px",
    borderRadius: "12px",
    fontSize:"16px",
    fontFamily: "MyFont !important",
    fontWeight:700,
    textTransform: "capitalize",
  },
  backbuttonStyle2:{
    height: "56px",
    width: "169px",
    border: "1px solid #73A58E",
    padding: "10px",
    borderRadius: "16px",
    fontSize:"16px",
    fontFamily: "MyFont !important",
    fontWeight: 700,
    textTransform: "capitalize",
  },
  step1ButtonStyle:{
    height: "56px",
    // marginTop:'90px',
    padding:"16px",
    width: "344px",
    borderRadius: "10px",
    backgroundColor: "#14362E",
    color:"#FFFFFF",
    fontFamily: "MyFont !important",
    fontWeight:700,
    border: "1px solid #73A58E",
  },
  fontStyle:{
    fontFamily: "MyFont ",
  },
  ThankyouBoxStyle:{
    fontSize:"30px",
    fontFamily: "MyFont",
    fontWeight:700,
    lineHeight:"40px",
    textAlign:"center",
    color:"#14362E",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    gap: "23px",
  },
  flexContainer: {
    display: "flex",
    gap: "12px",
  },
  textBolder: {
    fontWeight: 500,
    color: "#64748B"
  },
  planBoxContainer: {
    height: "auto",
    width: "284px"
  },
  planBoxWrapper: {
    height: "auto",
    width: "312px"
  },
  benefitTypography: {
    color: "#64748B",
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "MyFont",
    lineHeight: "18px",
    cursor: "pointer",
    textDecoration: "underline",
    width: "230px"
  },
  topPadding: {
    paddingTop: "2%",
  },
  contentPadding: {
    paddingTop: "10%",
  },
  amountBox: {
    display: "flex",
    height: "auto",
    width: "100%",
    justifyContent: "space-between"
  },
  amountBoxMobile: {
    display: "flex",
    height: "auto",
    width: "260px",
    justifyContent: "space-between",
    flexDirection: "column"
  },
  amountInnerBox: {
    display: "flex",
    gap: "2px",
  },
  amountText: {
    lineHeight: "28px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "20px",
  },
  intervalText: {
    lineHeight: "22px",
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "MyFont",
    textAlign: "end",
    color: "#64748B",
    paddingTop: "2px",
  },
  viewDetailsText: {
    lineHeight: "22px",
    fontWeight: 700,
    color: "#14362E",
    cursor: "pointer",
    fontSize: "12px",
    fontFamily: "MyFont",
    textAlign: "end",
  },
  checkCircleIcon: {
    fill: "#73A58E",
    width: "16px",
    marginTop: "-2px"
  },
  subsciptionBenefitText: {
    width: "704px",
    marginTop: "10px",
    '@media (max-width: 500px)': {
      marginTop: "-10px",
  }
  }
};

const CustomTypography = styled(Typography)({
  '& .MuiTypography-body1': {
    fontFamily: 'MyFont',
  },

});

const CustomDrawer = styled(Drawer)({
  '& .MuiDrawer-paper': {
    width: 'auto',
    borderRadius: "24px 0px 0px 0px",
    overflowX:"hidden",
    '@media (max-width: 601px)': {
      width: '100%',
      padding: '0px',
    },
  },
  '& .TextStyle': {
    fontSize:"22px",
    fontFamily: "MyFont !important",
    fontWeight: 800,
    lineHeight:"32px",
    color: "#14362E"
  },
  '& .TextStyle2': {
    fontSize:"20px",
    fontFamily: "MyFont !important",
    fontWeight: 700,
    lineHeight:"28px",
  },
  '& .boxTextStyle': {
    height: "auto",
    width: "372px",
    marginLeft:"5px",
    fontSize:"14px",
    fontFamily: "MyFont !important",
    fontWeight:400,
    lineHeight:"18px",
  },
  '& .Typostyle':{
    fontFamily:"MyFont !imporatnt",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    color: "#334155",
  },
  '& .boxTypoStyle1':{
   
    fontFamily:"MyFont !important",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "8px",
  },
  '& .boxTypoStyle2':{
    fontFamily:"MyFont !important",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "8px",
  },
  '& .buttonStyle':{
    height: "56px",
    width: "100%",
    borderRadius:"8px",
    backgroundColor: "#14362E",
    color:"#FFFFFF",
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "MyFont !important",
  },
  '& .buttonStyle2':{
    height: "56px",
    margin:'15px',
    width: "350px",
    borderRadius:"8px",
    backgroundColor: "#14362E",
    color:"#FFFFFF",
    textTransform: "capitalize",
    fontWeight: 700,
    fontFamily: "MyFont !important"
  },
  '& .mainViewDetailDrawerStyle':{
    height: "1024px",
    width: "484px",
    left:"956px",
    borderRadius:"24px, 0px, 0px, 0px",
    padding: "0px 0px 0px 25px",
    Gap:"24px",
    boxShadow: "0px 8.31px 33.24px 0px #0000000F",
  },
  
  '& .viewdrawerStyle':{
    height: "73px",
    width: "auto",
    padding: "12px 0px 12px 0px !important"
  },
  '& .benefitDrawerBoxStyle':{
    height: "468px",
    width: "428px",
    // marginTop:"25px",
    gap: "6px !important",
    border : "1px solid linear-gradient(180deg, #E0B667 0%, #F7D18A 20.5%, #FBE6C0 47%, #F7D18A 72%, #FBE6C0 100%)",
    borderRadius: '8px 8px 32px 8px',
    padding:"16px",
    '@media (max-width: 500px)': {
        width: 'auto',
    }
  },
  '& .viewdrawerBoxStyle':{
    height: "auto",
    width: "428px",
    marginTop:"25px",
    gap: "6px !important",
    border : "1px solid #73A58E",
    borderRadius: '8px 8px 32px 8px',
    padding:"16px"
  },
  '& .viewdrawerBoxStyle2':{
    height: "auto",
    width: "auto",
    marginTop:"25px",
    gap: "6px !important",
    border : "1px solid #73A58E",
    borderRadius: '8px 8px 32px 8px'
  },
  '& .viewDrawerTypoStyle':{
    fontFamily:"MyFont !important",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  '& .viewDrawerButton':{
    height: "56px",
    margin:'15px',
    width: "385px",
    borderRadius: "10px",
    backgroundColor: "#14362E",
    color:"#FFFFFF",
    top:"79%",
    textTransform: "capitalize",
    fontFamily: "MyFont !important",
    fontWeight:600,
    border: "1px solid #73A58E",
  },
  '& .viewDrawerButton2':{
    height: "56px",
    width: "346px",
    borderRadius: "10px",
    backgroundColor: "#14362E",
    color:"#FFFFFF",
    top:"79%",
    textTransform: "capitalize",
    fontFamily: "MyFont !important",
    fontWeight:600,
    border: "1px solid #73A58E",
  },
  '@media (max-width: 601px)': {
    '& .MuiDrawer-paper': {
      width: '100%',
    },
  }
})
// Customizable Area End
