import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("./config");
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;

  isPromoOpen: boolean;
  stepperStep: number;
  viewDetailDrawer1 : boolean;
  showButton: string;
  isBoxOpen:boolean;
  selectdPlan:number;
  selectdPlan2:boolean;
  checkCount:number;
  viewPlan:boolean;
  subscriptions:any[];
  subscriptionsBenefitData: any;
  drawerPlanId: string;
  selectedPlanId: string,
  addPromeCode: string,
  selectedPlan: any,
  isShowPromoCodeValidation: boolean,
  isValidPromoCode:  boolean,
  orderSummaryDataState: any,
  isAnnualPlan: boolean,
  isOpenBenefitDrawer: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Subscriptionbilling2Controller extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apisubscriptionCallId: string = ''
  apisubscriptionShowCallId: string = '';
  apiAddSubscriptionPromoDataId: string = '';
  apiGetOrderSummaryDataId: string = '';
  apiCheckoutSubscriptionDataId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",

      isPromoOpen: false,
      stepperStep: 0,
      viewDetailDrawer1: false,
      showButton: "Select plan",
      isBoxOpen: false,
      selectdPlan:0,
      selectdPlan2:false,
      checkCount:0,
      viewPlan:false,
      subscriptions: [],
      subscriptionsBenefitData: {},
      drawerPlanId: "",
      selectedPlanId: "",
      addPromeCode: "",
      isShowPromoCodeValidation: false,
      isValidPromoCode: false,
      selectedPlan: [],
      orderSummaryDataState: {},
      isAnnualPlan: true,

      isOpenBenefitDrawer: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      this.handleSessionResponse(message);
    } else if (this.isRestApiResponse(message, responseJson)) {
      this.handleRestApiResponse(apiRequestCallId, responseJson);
    }
    // Customizable Area End

  }

  // Customizable Area Start
  async componentDidMount() {
    let accountId = await getStorageData("account_id")
    const token = localStorage.getItem("accessToken")
    console.log("token",token,token?.length);
    this.subscriptionData() 
  }

  subscriptionData() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken"),
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.apisubscriptionCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subscriptionAPiEndPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  subscriptionPlanData(planId: string) {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken"),
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.apisubscriptionCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subscriptionAPiEndPoint + planId
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  subscriptionShowData = async (planId : string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("accessToken")

    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.apisubscriptionShowCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subscriptionShowAPiEndPoint + planId
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  postAddSubscriptionPromoData = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("accessToken")
    };

    const httpBody = {
      plan_id: this.state.selectedPlanId,
      promo_code: this.state.addPromeCode
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAddSubscriptionPromoDataId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subscriptionPromoAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  postCheckoutSubscriptionData = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("accessToken")
    };

    const httpBody = {
      plan_id: this.state.selectedPlanId,
      payment_date: this.state.orderSummaryDataState?.payment_date || '',
      plan_end_date: this.state.orderSummaryDataState?.plan_end_date || '',
      plan_discount: this.state.orderSummaryDataState?.plan_discount || '',
      total_amount: this.state.orderSummaryDataState?.total_amount || '',
      promo_code: this.state.orderSummaryDataState?.promo_code || '',
      promo_code_discount: this.state.orderSummaryDataState?.promo_code_discount || ''
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCheckoutSubscriptionDataId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subscriptionCheckoutAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getOrderSummaryData(promeCode?:any, discount?: any) {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken"),
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.apiGetOrderSummaryDataId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.orderSummaryAPiEndPoint+`?plan_id=${this.state.selectedPlanId}&promo_code=${promeCode}&discount=${discount}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  
  handleSessionResponse(message: Message) {
    const token: string = message.getData(getName(MessageEnum.SessionResponseToken));
    runEngine.debugLog("TOKEN", token);
  }

  isRestApiResponse(message: Message, responseJson: any): boolean {
    return responseJson && getName(MessageEnum.RestAPIResponceMessage) === message.id;
  }

  handleRestApiResponse(apiRequestCallId: string, responseJson: any) {
    switch (apiRequestCallId) {

      case this.apisubscriptionCallId:
         this.setState({
            subscriptions: responseJson.plans,
            subscriptionsBenefitData: responseJson?.benefit
         })
         break
      case this.apisubscriptionShowCallId:
        this.setState({
           selectedPlan: responseJson?.plan
        })
         break;
      case this.apiAddSubscriptionPromoDataId:
        this.setState({
          isShowPromoCodeValidation: true
        })

        if (responseJson.error) {
          this.setState({
            isValidPromoCode: false
          })
          this.getOrderSummaryData()
        } else {
          this.setState({
            isValidPromoCode: true
          })
          this.getOrderSummaryData(responseJson?.promo_code, responseJson?.discount)
        }
        break;
      case this.apiGetOrderSummaryDataId:
        this.setState({
          orderSummaryDataState: responseJson?.order_summary || {}
        })
        break;
      case this.apiCheckoutSubscriptionDataId:
        if (responseJson?.session_url) {
          window.location.href = responseJson?.session_url
        }
        break;
      default:
        break;
    }
  }

  handleViewDrawer1=( planId: string )=>{
    this.setState({ viewPlan: true, viewDetailDrawer1: true, drawerPlanId: planId});
  }

  handlePlanSelection = (planId: string) => {
    this.setState({ selectedPlanId: planId, showButton: "Confirm Plan" });
  };

  handleViewDrawer2=()=>{
    this.setState({ viewPlan: false ,viewDetailDrawer1: true, });
  }

  handleViewDrawerClose=()=>{
    this.setState({viewDetailDrawer1: false,});
  }
  toggleDrawer=(open: boolean)=>{
    this.setState({isPromoOpen:open})
  }

  handlePromo=()=>{
    this.toggleDrawer(true);
  }

  handlepromoClose=()=>{
    this.toggleDrawer(false);
  }

  handlebutton=()=>{
    this.setState({showButton: "Confirm Plan"});
  }
  handlestep1=()=>{
    this.setState({stepperStep: 1});
    this.subscriptionShowData(this.state.selectedPlanId)
  }
  handlestep2=()=>{
    this.postCheckoutSubscriptionData()
    this.setState({stepperStep: 2});
  }
  handlestep3=()=>{
    this.setState({stepperStep: 3});
  }
  handlestepBack=()=>{
    this.setState((prevState)=>({
      stepperStep : prevState.stepperStep - 1,
    }))
  }
  handlePlanSelect=()=>{
    this.setState({showButton:"Confirm Plan", selectdPlan: this.state.selectdPlan === 1? 2:1 });
  }
  handleBoxOpen=()=>{
    if (!this.state.orderSummaryDataState?.promo_code) {
      this.getOrderSummaryData()
    }
    
    this.setState({isBoxOpen: true, checkCount: this.state.checkCount +1});
  }
  handlePlan=()=>{
    this.setState({viewPlan: true});
  }

  addPromeCode = (promoCode: string) => {
      this.setState({
         addPromeCode: promoCode
      })
  }

  handleChangeAddPromoCode = (promoCode: string) => {
    this.setState({
       addPromeCode: promoCode
    })
}

  handleSelectPlanToggle = () => {
    this.setState({
      isAnnualPlan: !this.state.isAnnualPlan
    })
  }

  handleSubscriptionBenefitDrawerClose=()=>{
    this.setState({isOpenBenefitDrawer: false});
  }

  
  // Customizable Area End
  
}
