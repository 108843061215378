import React from "react";
import OTPInputAuthController, { Props } from "./OTPInputAuthController";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { logo, rightbanner, stepper } from "./assets";
import OtpInput from 'react-otp-input';
import Alert from "@material-ui/lab/Alert";

// Customizable Area Start
export default class OtpInputAuthBlock extends OTPInputAuthController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const useremail = localStorage.getItem("useremail");
        return (
            // Required for all blocks
            <>
                {/* main content */}

                {/* <Container> */}
                <Box
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        // padding: "10px 0px",
                        height: "100%",
                        fontFamily: "MyFont"
                    }}
                >
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={12} md={8} style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            height: "100%",
                        }}>
                            {/* first column */}
                            <Box style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                height: "100%",
                            }}>
                                <Box sx={{ width: "100%", paddingTop: "20px" }}>
                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                        <Box sx={{ marginTop: { xs: "none", md: "50px", lg: "50px" } }}>
                                            <img src={logo} alt="" />
                                        </Box>
                                        <Box sx={{ display: { lg: "block", md: "block", }, marginTop: "18px" }}>
                                            <Typography variant="subtitle1" component="div" >
                                                <img src={stepper} alt="" /> {/*UI Engine::From Sketch*/}
                                            </Typography>
                                            <Typography style={{ textAlign: "center", marginTop: "20px", fontFamily: "MyFont", fontWeight: "bold", letterSpacing: "1", fontSize: "20px" }}>Email Verification</Typography>
                                        {
                                        this.state.showAlertError ? <Alert style={{ fontSize: "11px", margin: "20px 0px",fontFamily: "MyFont", letterSpacing: "0.8px" }} severity="error">{this.state.alertErrorMessage}</Alert> : ""
                                        }   
                                        {
                                            this.state.resenOtpSucess ? <Alert style={{ fontSize: "12px", margin: "20px 0px",fontFamily: "MyFont", letterSpacing: "0.8px"}} severity="success">Otp Send Succesfully</Alert>:""
                                        }    
                                        </Box>
                                        <Typography style={{ fontFamily: "MyFont", marginTop: "20px", letterSpacing: "0.7", fontSize: "14px" }}>A 6-digit verification code was sent to</Typography>
                                        <Typography style={{ fontFamily: "MyFont", marginTop: "12px", letterSpacing: "1", fontSize: "17px" ,color:"#0F172A",fontWeight:"bold"}}>{useremail}</Typography>
                                        <OtpInput
                                            inputStyle={{ width: "40px", marginTop: "16px" ,padding:'10px',borderRadius:"8px",border:this.state.showAlertError ? `1px solid red`:'1px solid gray'}}
                                            value={this.state.otpvalue}
                                            onChange={this.handlesetOtp}
                                            numInputs={6}
                                            renderSeparator={<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                                            renderInput={(props: any) => <input {...props} />}
                                        />
                                        <Box sx={{ display: 'flex', justifyContent: "spaceBetween", alignItems: "center" }}>
                                            <Box
                                                data-test-id={"btnSignup"}
                                                sx={{
                                                    display: "flex",
                                                    fontSize: "15px",
                                                    fontWeight: 500,
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: "10px 0px",
                                                    marginTop: "10px",
                                                    fontFamily: "MyFont",
                                                    letterSpacing: "0.6px",
                                                }}
                                            >
                                                 Didn’t get any code?  &nbsp;
                                            </Box>
                                            <span style={{ fontWeight: "bold", fontFamily: "MyFont", fontSize: "14px", letterSpacing: "0.8px", marginTop: "10px", cursor: "pointer !important" }} onClick={this.resentOtpWeb} >Resend</span>

                                        </Box>
                                    </Box>

                                </Box>
                                {/* 3 column  */}
                                <Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            paddingTop: { xs: "280px", md: "200px", lg: "200px" },
                                            padding:"0 15px"
                                            // padding: "310px 0px 10px 0px",
                                        }}
                                    >
                                        <Box style={{ display: "flex", justifyContent: "space-around" }} sx={{width:{xs:"350px",md:"350px",lg:"400px"}}}>
                                            <Button
                                                data-test-id={"btnEmailLogIn"}
                                                variant="contained"
                                                style={{
                                                    background: "white",
                                                    color: "black",
                                                    fontWeight: 500,
                                                    width: "150px",
                                                    textTransform: "capitalize",
                                                    fontFamily: "MyFont",
                                                    fontSize: "16px"
                                                }}
                                                fullWidth
                                                href="/EmailAccountRegistration"
                                            >
                                                Back
                                            </Button>
                                            <Button
                                                data-test-id={"btnOtpVerify"}
                                                variant="contained"
                                                style={{
                                                    background: this.state.otpvalue.length == 6 ? "black": "#E2E8F0",
                                                    color: "white",
                                                    fontWeight: 500,
                                                    width: "150px",
                                                    textTransform: "capitalize",
                                                    fontFamily: "MyFont",
                                                    fontSize: "16px"
                                                }}
                                                fullWidth
                                                onClick={this.submitOtpWeb}
                                                disabled={this.state.otpvalue.length < 6 ? true:false}
                                            >
                                                Verify
                                            </Button>
                                        </Box>

                                        <Box sx={{ display: 'flex', justifyContent: "spaceBetween", alignItems: "center" }}>
                                            <Box
                                                data-test-id={"btnSignup"}
                                                sx={{
                                                    display: "flex",
                                                    fontSize: "15px",
                                                    fontWeight: 500,
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: "10px 0px",
                                                    marginTop: "10px",
                                                    fontFamily: "MyFont",
                                                    letterSpacing: "0.6px"
                                                }}
                                            >
                                                Already have an account?&nbsp;
                                            </Box>
                                            <span style={{ fontWeight: "bold", fontFamily: "MyFont", fontSize: "14px", letterSpacing: "0.8px", marginTop: "10px", cursor: "pointer" }} >Log in</span>
                                        </Box>

                                    </Box>
                                </Box>
                            </Box>

                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box
                                sx={{
                                    display: { xs: "none", md: "flex", lg: "flex" },
                                }}
                            >
                                <img
                                    src={rightbanner}
                                    alt="login-screen"
                                    style={{
                                        maxWidth: "100%",
                                        width: "100%",
                                        height: "100%",
                                    }}
                                />
                            </Box>
                        </Grid>

                    </Grid>


                </Box>
                {/* </Container> */}

            </>
        );
    }
}
// Customizable Area End