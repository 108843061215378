import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  logoutPopup: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class SettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [ getName(MessageEnum.RestAPIResponceMessage), 
        getName(MessageEnum.SessionResponseMessage), 
        getName(MessageEnum.CountryCodeMessage), ];

    this.state = {
        logoutPopup: false,
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.checkingToken()
  }

  checkingToken = () => {
    const token = localStorage.getItem("accessToken")
    if (!token) {
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), 'EmailAccountLoginBlock');
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg)
    }
  }

  handleLogoutOpen = () => {
    this.setState({ logoutPopup: true });
  };

  
  navigateToHome = () => {
    window.location.href = "NewsFeed";
  };
  
  handleLogoutClose = () => {
    this.setState({ logoutPopup: false });
  };
  navigateToSignup = () => {
    window.location.href = "EmailAccountRegistration";
  };
  // Customizable Area End
}
