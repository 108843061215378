import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import {TGetPlans, TPlan, TApplyPromo, TApplyPromoResponse, TChangePlanResponse} from "./types"
import React from "react";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isPromoOpen: boolean;
  step: number;
  viewDetailDrawer1 : boolean;
  viewPlan:boolean;
  cancelModal: boolean;
  plans: TPlan[]
  selectedPlan: number;
  viewDrawerId: number | null
  promoCode: string;
  couponDetails: null | TApplyPromo;
  isShowMessage: boolean;
  error: string;
  successModal: boolean;
  changeError: string;
  isMonthly: boolean;
  benefit: TGetPlans['benefit'] | null
  viewBenefitDrawer: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  getPlansApiCallId: string
  applyPromoApiCallId: string;
  changeSubscriptionApiCallId: string
  cancelSubscriptionApiCallId: string
  // Customizable Area End
}

export default class EditPaymentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPlansApiCallId = ''
  applyPromoApiCallId = '';
  changeSubscriptionApiCallId = '';
  cancelSubscriptionApiCallId = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.applyPromocode = this.applyPromocode.bind(this);
    this.handleChangeSubscription = this.handleChangeSubscription.bind(this);
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isPromoOpen: false,
      step: 1,
      viewDetailDrawer1: false,
      viewPlan:false,
      cancelModal: false,
      plans: [],
      selectedPlan: 0,
      viewDrawerId: null,
      promoCode: '',
      couponDetails: null,
      isShowMessage: false,
      error: '',
      successModal: false,
      changeError: '',
      isMonthly: false,
      benefit :null,
      viewBenefitDrawer: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getPlansApiCallId) {
          this.handleGetPlans(responseJson as TGetPlans)
      } else if(apiRequestCallId === this.applyPromoApiCallId) {
        if(responseJson.error){
          this.setState({error: responseJson.error})
        }else {
          this.handleApplyPromo(responseJson as TApplyPromoResponse)
        }
      } else if( apiRequestCallId === this.changeSubscriptionApiCallId) {
        this.handleChangeSubscriptionResponse(responseJson)
      } else if(apiRequestCallId === this.cancelSubscriptionApiCallId) {
        this.handleCancelSubscriptionResponse();
      }
    }


    // Customizable Area End
  }
 
  
  // web events
 


  // Customizable Area Start
  async componentDidMount() {
    this.getPlansData();
  }
  getPlansData () {

  const headers = {
      "Content-Type": 'application/json',
      token: localStorage.getItem("accessToken")
    };
    
    const getPlansMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPlansApiCallId = getPlansMsg.messageId;

    getPlansMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_stripe_integration/plans'
    );

    getPlansMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getPlansMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getPlansMsg.id, getPlansMsg);
  }
  applyPromocode () {
    if(this.state.couponDetails){
      return this.handlepromoClose();
    }
    const headers = {
        "Content-Type": 'application/json',
        token: localStorage.getItem("accessToken")
      };
      
      const applyPromoMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.applyPromoApiCallId = applyPromoMsg.messageId;
  
      applyPromoMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'bx_block_stripe_integration/plans/apply_promo'
      );
  
      applyPromoMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      applyPromoMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'POST'
      );
      applyPromoMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({promo_code: this.state.promoCode.trim(), plan_id: this.state.selectedPlan})
      );
      runEngine.sendMessage(applyPromoMsg.id, applyPromoMsg);
  }
  handleChangeSubscription () {

    const headers = {
        "Content-Type": 'application/json',
        token: localStorage.getItem("accessToken")
      };
    const body = {
      plan_id: this.state.selectedPlan,
      total_amount: this.state.couponDetails?.order_summary.total_amount,
      promo_code: this.state.couponDetails?.promo_code,
      promo_code_discount:  this.state.couponDetails?.discount || null
    }
      const changeSubscriptionMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.changeSubscriptionApiCallId = changeSubscriptionMsg.messageId;
  
      changeSubscriptionMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'bx_block_stripe_integration/plans/create_subscription_change_request'
      );
  
      changeSubscriptionMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      changeSubscriptionMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'POST'
      );
      changeSubscriptionMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      runEngine.sendMessage(changeSubscriptionMsg.id, changeSubscriptionMsg);
  }
  handleCancelSubscription () {

    const headers = {
        "Content-Type": 'application/json',
        token: localStorage.getItem("accessToken")
      };

      const cancelSubscriptionMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.cancelSubscriptionApiCallId = cancelSubscriptionMsg.messageId;
  
      cancelSubscriptionMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'bx_block_stripe_integration/plans/cancel_subscription'
      );
  
      cancelSubscriptionMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      cancelSubscriptionMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'POST'
      );
      runEngine.sendMessage(cancelSubscriptionMsg.id, cancelSubscriptionMsg);
  }
  handleCancelSubscriptionResponse = () => {
    this.handleChangeCancelModal(false)
  }
  handleChangeSubscriptionResponse = (responseJson: TChangePlanResponse)  =>{
    if(responseJson?.error){
      this.setState({changeError: responseJson.error})
    }else {
      this.closeChangeError();
      this.openSuccessModal()
    }
  }
  openSuccessModal =() =>{
    this.setState({successModal: true});
  }
  closeChangeError = () => {
    this.setState({changeError: ''})
  }
  closeSuccessModal = () => {
    this.setState({successModal: false});
  }
  handleCloseError = () => {
    this.setState({error: ''})
  }
  handleApplyPromo(response: TApplyPromoResponse) {
    this.setState({couponDetails:response, isShowMessage: true})
  }
  handleSelectPlan(value: number) {
    this.setState(prev => ({selectedPlan: prev.selectedPlan === value ? 0 : value}))
  }
  handleGetPlans(responseJson: TGetPlans){
    this.setState({plans: responseJson.plans, benefit: responseJson.benefit})
  }
  handleViewDrawer1=(id: number, event:React.MouseEvent)=>{
    event.stopPropagation();
    this.setState({ viewPlan: true, viewDetailDrawer1: true, viewDrawerId: id});
  }
 
  handleViewDrawerClose=()=>{
    this.setState({viewDetailDrawer1: false,});
  }
  toggleDrawer=(open: boolean)=>{
    this.setState({isPromoOpen:open})
  }
  handleChangePromoCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(({promoCode: event.target.value, error: '', couponDetails: null} ))
  }
  handleCloseMessage = () => {
  this.setState({isShowMessage: false})
  }
  handlePromo=()=>{
    this.toggleDrawer(true);
  }

  handlepromoClose=()=>{
    this.toggleDrawer(false);
  }

  handleNextStep=()=> {
    this.setState({
      step: 2
    })
  }
  handlestepBack=()=>{
    this.setState((prevState)=>({
      step : prevState.step - 1,
    }))
  }
  handlePlanSelect=(id?: number)=>{
    if(id){
      this.setState({selectedPlan: id})
    }
    this.handleViewDrawerClose();
    this.handleNextStep();
  }
  handleGoBack() {
    window.history.back();
}
  handleChangeCancelModal = (value: boolean)=> {
    this.setState({cancelModal: value})
  }
  handleChangePeriod = () => {
    this.setState(prev => ({isMonthly: !prev.isMonthly}));
  }
  handleCloseBenefitDrawer = () => {
    this.setState({viewBenefitDrawer: false})
  }
  handleOpenBenefitDrawer = () => {
    this.setState({viewBenefitDrawer: true})
  }
  // Customizable Area End
}
