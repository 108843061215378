import React from "react";
// Customizable Area Start

import PostCreationController, { Props } from "./PostCreationController.web";
import { postHeader, categoryIcon, LinkImg, Media, Pinimg } from "../src/assets";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Card, CardContent, Container, Divider, Drawer, IconButton, Grid, InputAdornment, InputLabel, Radio, TextField, Typography, withStyles, RadioProps, styled } from "@material-ui/core";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { AppFooter } from "../../../components/src/AppFooter.web";
import { Toaster } from 'react-hot-toast';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import BackupIcon from '@material-ui/icons/Backup';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
// Customizable Area End

// Customizable Area Start
export default class PostCreation extends PostCreationController {
  constructor(props: Props) {
    super(props);
  }

  postCreationView() {
    const sharebttn = {
      background: this.state.body.length > 0 ? "black" : "#F1F5F9",
      color: "#CBD5E1",
      width: "90px",
      fontFamily: "MyFont"
    }
    const profile = localStorage.getItem("userprofile");
    const { body, selectedSubcategories, buttonenable, editPostId } = this.state;
    const isButtonEnabled = body.length > 0 && selectedSubcategories.length > 0 && !buttonenable;
    const buttonBackground = isButtonEnabled ? "black" : "#F1F5F9";
    return (
      <>
        <Container>
          <Box style={webStyle.postmainparent as any}>
            <Box style={webStyle.postmainbox as any}>
              <Avatar src={profile as any}></Avatar>
              <Typography style={webStyle.postheader as any}>New Post</Typography>
            </Box>
            <Box>
              <Button
                data-test-id="shareUpdateId"
                style={{
                  background: buttonBackground,
                  color: "#CBD5E1",
                  width: "90px",
                  fontFamily: "MyFont",
                  textTransform: "capitalize",
                  fontSize: "14px",
                  letterSpacing: "0.8px",
                  fontWeight: "bold"
                }}
                disabled={!isButtonEnabled}
                onClick={this.createPostCreation}
              >
                {editPostId ? "Update" : "Share"}
              </Button>
            </Box>
          </Box>

          <Box style={webStyle.categoryMainBox as any} data-test-id="showCategory" onClick={this.toggleDrawer.bind(this, true, "category")}>
            <Box style={webStyle.categoryFirstBox as any}>
              <Box style={{ marginRight: "5px" }}>
                <img src={categoryIcon} alt="" />
              </Box>
              <Box style={{ cursor: "pointer", fontFamily: "MyFont !important" }}>
                <Typography style={{ fontFamily: "MyFont" }}>Category</Typography>
                <div style={{ display: "flex" }}>
                  {this.getSelectedSubcategoryNames() && this.getSelectedSubcategoryNames().length > 0 ? (
                    <Typography style={{ fontFamily: "MyFont !important" }}>
                      {this.getSelectedSubcategoryNames().slice(0, 3).join(', ')}
                    </Typography>
                  ) : (
                    <Typography style={{ fontFamily: "MyFont !important" }}>
                      Choose a Category*
                    </Typography>
                  )}
                </div>
              </Box>
            </Box>
            <Box style={{ fontSize: "22px",color:"#475569",marginRight:"10px" }}>
              {">"}
            </Box>
          </Box>

          <Box>
            <textarea value={this.state.newBody} tabIndex={1} placeholder="Share your thoughts..." onChange={(e) => this.handleBodyChange(e, 'post')} style={webStyle.textareastyle as any}></textarea>
          </Box>

          {
            (
              <>
                <Box>
                  <Box style={webStyle.selectedImg as any}>

                    {
                      this.state.upImagesUpload.length > 0 && this.state.upImagesUpload.map((element: any, index: any) => {
                        return (
                          <>
                            <img key={index} src={element.base64convert} style={webStyle.selectedimgstyle} alt="" />
                            <span><i className="fa-solid fa-xmark" style={{
                              marginLeft: "-12px",
                              color: "#64748B",
                              background: "white",
                              borderRadius: "50%",
                              padding: "2px 5px",
                              marginRight: "10px",
                              border: "1px solid #E2E8F0",
                              cursor: 'pointer'
                            }}
                              onClick={this.handlefileDelete.bind(this, index, element)}></i></span>
                          </>
                        )
                      })
                    }
                  </Box>
                </Box>
              </>
            )
          }

          {
            <>
              <Box style={webStyle.selectedImg as any}>
                {
                  this.state.upImagesUpload2.map((element: any, index: any) => {
                    return (
                      <>
                        <img key={index} src={element.base64convert} style={webStyle.selectedimgstyle as any} alt="" />
                        <span><i className="fa-solid fa-xmark" style={{
                          marginLeft: "-12px",
                          color: "#64748B",
                          background: "white",
                          borderRadius: "50%",
                          padding: "2px 5px",
                          marginRight: "10px",
                          border: "1px solid #E2E8F0",
                          cursor: 'pointer'
                        }} onClick={this.handleImgDelete.bind(this, index, element)}></i></span>
                      </>
                    )
                  })
                }
              </Box>
            </>
          }


          {
            this.state.linkset == "true" || this.state.editPostId && this.state.linktitle ?
              (
                <>
                  <Box style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", display: "flex", justifyContent: "space-between", padding: "4px", alignItems: "center", height: "60px", marginBottom: "10px", marginTop: "10px" }}>
                    <Box style={{ display: "flex", borderRadius: "10px", alignItems: "center" }}>
                      <img src={LinkImg} alt="" />
                      <Box style={{ marginLeft: "10px" }}>
                        <p>{this.state.linktitle}</p>
                      </Box>
                    </Box>
                    <span style={{width:"50px",display:"flex",justifyContent:"space-between"}}>
                      <i className="fa-solid fa-pen" style={{cursor:"pointer",color:"#64748B"}} onClick={this.handlelinkupdate}></i>
                      <i className="fa-solid fa-xmark" style={{cursor:"pointer",marginRight:"5px",color:"#64748B"}} onClick={this.handleLinkremove}></i>
                    </span>
                  </Box>
                </>
              ) : ""
          }

          {
            this.state.storeTagSet && this.state.storeselectedTagData.length > 0 && (
              <>
                <Box >
                <Typography style={{ display: "flex" }}><span style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px", marginTop: "15px" }}>Tags</span><span style={{ fontSize: "13px", marginTop: "15px" }}>:</span>
                    <Box style={{ display: "flex",marginTop: "15px" ,width:"300px",overflow:"auto" }}>
                      {
                        this.state.storeselectedTagData.map((element: any, index: any) => {
                          return (
                            <>
                              <Box style={{ position: "relative", display: "inline-flex", alignItems: "center" }}>
                                <span key={index} style={{
                                  fontFamily: "MyFont",
                                  fontWeight: "bold",
                                  letterSpacing: "0.8px",
                                  paddingRight: "14px", 
                                  paddingLeft: "5px"
                                }}>
                                  {`${element.first_name}`}
                                </span>
                                <span
                                  onClick={this.handleselectTagDelete.bind(this, index)}
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    right: "0",
                                    marginLeft: "10px",
                                    cursor: "pointer"
                                  }}
                                >
                                  <i className="fa-solid fa-xmark"
                                    style={{
                                      fontSize: "11px",
                                      color: "white",
                                      background: "black",
                                      borderRadius: "50%",
                                      padding: "2px",
                                      cursor: 'pointer'
                                    }}>
                                  </i>
                                </span>

                              </Box>
                            </>
                          )
                        })
                      }
                    </Box>
                  </Typography>
                </Box>
              </>
            )
          }
          <Box style={webStyle.selectCircle as any}>
            <Box>
              <Typography style={{ fontFamily: "MyFont", color: "#64748B", fontSize: "14px", letterSpacing: "0.8px" }}>Who can see this?</Typography>
            </Box>
            <Box>
              <select id="cars" style={{ border: "none", background: "#F8FAFC", fontWeight: "500", fontSize: "12px", fontFamily: "MyFont", letterSpacing: "0.8px", width: "80px",cursor:"pointer" }} onChange={this.handleChangepermission}>
                <option value="Public" style={{ fontFamily: "MyFont", letterSpacing: "0.8px", padding: "10px", color: "3C3E49",cursor:"pointer" ,fontSize: "13px" }}>Public</option>
                <option value="Private" style={{ fontFamily: "MyFont", letterSpacing: "0.8px", padding: "10px", color: "3C3E49", fontSize: "13px", width: "50px",cursor:"pointer" }}>My Circle</option>
              </select>
            </Box>
          </Box>

          <Box style={webStyle.mediaselect as any}>
            <Box onClick={this.toggleDrawer.bind(this, true, "Image")} style={{ cursor: "pointer", paddingBottom: "2px" }}><i className="fa-regular fa-image"></i></Box>
            <Box onClick={this.toggleDrawer.bind(this, true, "tag")} style={{ cursor: "pointer", fontSize: "19px" }}><i className="fa-solid fa-at"></i></Box>
            <Box onClick={this.toggleDrawer.bind(this, true, "Document")} style={{ cursor: "pointer", transform: "rotate(-43deg)" }}><i className="fa-solid fa-paperclip"></i></Box>
            <Box onClick={this.toggleDrawer.bind(this, true, "Link")} style={{ cursor: "pointer" }}>
              <img src={Pinimg} alt="" />
            </Box>
          </Box>
        </Container>
      </>
    )
  }

  checkRecommendationValidation() {
    if(this.state.recommendationDescription === '' || this.state.selectedValue == '' || this.state.recomendTitle === ""){
      return true;
    } else {
      return false;
    }
  }

  postrecommendationsView() {
    
    let sharecss = {
      textTransform: "capitalize",
      background: this.checkRecommendationValidation() ?
         "#F1F5F9" : "black", color: "#CBD5E1", width: "90px", letterSpacing: "0.8px",
         fontSize: "14px",  fontWeight: "bold", fontFamily: "'MyFont', 'Halyard Display'",
    }
    return (
      <Discription>
        <Grid spacing={1}>
          <Box style={webStyle.postmainparent as any}>
            <Box style={webStyle.postmainbox as any}>
              <Avatar src={this.state.userprofile}></Avatar>
              <Typography style={webStyle.postheader as any}>New Recommendations</Typography>
            </Box>
            <Box>
              <Button style={sharecss as any}  data-test-id="recmendPostId"  disabled={this.checkRecommendationValidation()} onClick={this.recPostCreation}> {this.state.editPostId ? "Update" : "Share"}</Button>
            </Box>
          </Box>
          <Box style={webStyle.titlbox}>
            <InputLabel htmlFor="Title" style={webStyle.InputLabelStyle}>Title</InputLabel>
            <StyledTextField
              fullWidth
              data-test-id="webfirstName"
              type="text"
              variant="outlined"
              placeholder="Title here"
              value={this.state.recomendTitle}
              onChange={this.handletitlechange}
            />
          </Box>
          <Discription>
            <textarea value={this.state.recommendationDescription} data-test-id="textareaId" className="textarea-custom" tabIndex={1} placeholder="Description" onChange={(e) => this.handleBodyChange(e, 'recommendation')} style={webStyle.textareastyle}></textarea>
            <Typography className="typotext" >All recommendations are public</Typography>
          </Discription>
          <Box style={webStyle.titlbox}>
            <InputLabel style={webStyle.InputLabelStyle}>
              City
            </InputLabel>
            <StyledTextField
              onClick={this.toggleDrawer.bind(this, true, "city")}
              type="text"
              variant="outlined"
              data-test-id="citysidebarId"
              placeholder="Select city"
              value={this.state.selectedCity}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment style={webStyle.InputAdornmcss} position="end">
                    <IconButton>
                      <ArrowDropDownIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box style={webStyle.titlbox}>
            <InputLabel style={webStyle.InputLabelStyle}>
              Type
            </InputLabel>
            <StyledTextField
              onClick={this.toggleDrawer.bind(this, true, "types")}
              type="text"
              data-test-id="typelabelId"
              variant="outlined"
              placeholder="Select an option"
              value={this.state.selectedType}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment style={webStyle.InputAdornmcss} position="end">
                    <IconButton>
                      <ArrowDropDownIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {
            <>
              <Box style={webStyle.selectedImg}>
                {
                  this.state.upImagesUpload.map((element: any, index: any) => {
                    return (
                      <>
                        <img key={index} src={element.base64convert} style={webStyle.selectedimgstyle as any} alt="" />
                        <span><i className="fa-solid fa-xmark" style={webStyle.xmarkicon} onClick={this.handleImgDelete.bind(this, index, element)}></i></span>
                      </>
                    )
                  })
                }
              </Box>
            </>
          }
          {
            this.state.upImagesUpload2.length == 0 ? (
              <Box
                gridGap={0}
                sx={{
                  marginTop: '10px',
                  border: '2px dashed #D3D3D3',
                  borderRadius: '4px',
                  padding: { xs: '8px', sm: '12px', md: '16px' },
                  textAlign: 'center',
                  maxWidth: { xs: '100%', sm: '400px', md: '800px' },
                  margin: 'auto',
                  fontSize: { xs: '14px', sm: '16px', md: '18px' },
                }}
              >
                <IconButton>
                  <BackupIcon style={webStyle.buckupicon} />
                </IconButton>
                <Box onClick={this.toggleDrawer.bind(this, true, "Document")} style={webStyle.mediabox}>
                  <IconButton>
                    <AddCircleOutlineIcon style={webStyle.plusIcon} />
                  </IconButton>
                  <h6 style={webStyle.uploadmedia}>
                    Upload media
                  </h6>
                </Box>
                <h6 style={webStyle.desxcriptiontext}>
                  Description of supported files.
                </h6>
              </Box>
            ) : (
              <Box onClick={this.toggleDrawer.bind(this, true, "Document")} style={webStyle.mediabox}>
                <IconButton>
                  <AddCircleOutlineIcon style={webStyle.plusIcon} />
                </IconButton>
                <h6 style={webStyle.uploadmedia}>
                  Upload media
                </h6>
              </Box>
            )
          }
        </Grid>
      </Discription>
    )
  }

  selectCategorySidebar() {
    return (
      <BoxMain>
        <Box style={webStyle.categoryDropwdown}>
          <Typography style={webStyle.selectCategoryBold}>Select Category</Typography>
          <span onClick={this.drawerClose} style={{ cursor: "pointer" }}><i className="fa-solid fa-xmark"></i></span>
        </Box>

        <Box style={webStyle.borderBottomstyle}></Box>

        <Box style={webStyle.selectctegoryParent}>
          {
            this.state.AllCategory.length > 0 && this.state.AllCategory.map((element: any, index: any) => {
              return (
                <>
                  <Accordion key={index} style={{ marginBottom: "20" ,boxShadow:"none",border:"1px solid #E2E8F0",borderRadius:"5px"}} onClick={this.handleCatChangeChange.bind(this, element)}>
                    <AccordionSummary
                      expandIcon={<i style={{fontSize:"13px"}} className="fa-solid fa-chevron-down"></i>}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Box style={webStyle.selectcategory as any}>
                        <Typography style={webStyle.selectCategoryCss}>{element.attributes && element.attributes.name}</Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box style={webStyle.subcategoryparent as any}>
                        {
                          element.attributes?.sub_categories.map((ele: { id: string; attributes: { name: string } }, ind: number) => {
                            const mergecatsub = { ...element, ele }
                            const isSelected = this.state.selectedSubcategories.includes(ele.id);
                            return (
                              <Typography
                                key={ind}
                                style={webStyle.seletionCat(isSelected)}
                                onClick={this.handleselectCategoryData.bind(this, mergecatsub)}
                              >
                                {ele.attributes?.name}
                              </Typography>
                            )
                          })
                        }

                      </Box>
                    </AccordionDetails>
                  </Accordion>

                </>
              )
            })
          }
        </Box >
          
        <Container className="button-container" style={{ position: "fixed", width: "inherit", bottom: "0", padding: "5px", marginBottom: "5px", }}>
          <Grid style={webStyle.btnSpacing}>
            <Button data-test-id="continueBtn" variant="contained" className="selectbtn" onClick={this.toggleDrawer.bind(this, false, "")}>Save</Button>
          </Grid>
        </Container>
      </BoxMain>
    )
  }

  AddDocumentSidebaar() {
    return (
      <>
        <BoxMain>
          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px" }} key={1}>
            <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontWeight: "bold", fontSize: "20px" }}>Add Documents</Typography>
            <span onClick={this.drawerClose} style={{ cursor: "pointer" }}><i className="fa-solid fa-xmark"></i></span>
          </Box>

          <Box style={webStyle.borderBottomstyle as any}></Box>

          <Box key={5} style={{ padding: "0 15px" }}>
            <Box style={webStyle.adddocumentStyle as any} key={1}>
              <label htmlFor="openupload"><i className="fa-solid fa-upload" style={{cursor: 'pointer'}}></i></label>
              <label style={{ display: "flex", cursor: 'pointer' }} htmlFor="openupload">
                <p style={{ border: "2px solid black", width: "15px", borderRadius: "55%", padding: "2px", marginRight: "5px", cursor: 'pointer' }}><i className="fa-solid fa-plus"></i></p>
                <label htmlFor="openupload">
                  <p style={{ letterSpacing: "0.8px" }}>Upload media</p>
                </label>
                <input style={{ color: "transparent", display: "none" }} id="openupload" type="file" multiple={true} onChange={this.handleMediaupload} name="" />
              </label>
              <p style={{ width: "150px", textAlign: "center" }}>We support PNG,  JPG,  MP4, MKV, AVI, MOV</p>
            </Box>

            <Container>
              <Box style={{height: "40%",  maxHeight: "40%", overflowY: "auto", marginTop: '5px', padding: '5px'}}>
              {
                this.state.uploadedImages.length > 0 && this.state.uploadedImages.map((element: any, index: any) => {
                  return (
                    <>
                      <Box key={index} style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", display: "flex", justifyContent: "space-between", padding: "4px", alignItems: "center", height: "40px", marginBottom: "8px", }}>
                        <Box style={{ display: "flex", borderRadius: "10px", alignItems: "center" }} key={index}>
                          <img src={element.base64convert} style={{ width: "50px", height: "50px", borderRadius: "5px", marginRight: "10px" }} alt="" />
                          <Box key={`${element.store?.id}-${index}`}>
                            <p>{element.store?.name}</p>
                            <p>{element.store?.size}</p>
                          </Box>
                        </Box>
                        <span><i className="fa-solid fa-xmark" style={{cursor: 'pointer'}} onClick={this.handlefileDelete.bind(this, index)}></i></span>
                      </Box>
                    </>
                  )
                })
              }
              </Box>
              {
                this.state.uploadedImages.length > 0 ? <Box style={{ position: "absolute", width: "90%", bottom: "0", padding: "5px", marginBottom: "10px" }}><Button key={4} variant="contained" style={{ width: "100%", background: "black", color: "white", textTransform: "capitalize", fontFamily: "MyFont", cursor: 'pointer', letterSpacing: '0.8px' }} onClick={this.handlefileuploadClose}>Upload</Button></Box> :
                  <Box style={{ position: "absolute", width: "100%", bottom: "0", padding: "5px", marginBottom: "5px" }}><Button key={4} variant="contained" style={{ width: "100%", background: "#E2E8F0", color: "white", textTransform: "capitalize", fontFamily: "MyFont", cursor: 'pointer', letterSpacing: '0.8px' }} >Upload</Button></Box>
              }
            </Container>

          </Box>
        </BoxMain>
      </>
    )
  }


  ImgSIdebaar() {
    return (
      <BoxMain>
        <Box className="mediatitle" >
          <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontWeight: "bold" }}>Media</Typography>
          <span onClick={this.drawerClose} style={{ cursor: "pointer" }}><i className="fa-solid fa-xmark"></i></span>
        </Box>

        <Box style={webStyle.borderBottomstyle as any}></Box>

        <Box style={{ width: "100%", marginBottom: "10px" }}>
          <label htmlFor="mediaselect">
            <img src={Media} alt="" />
          </label>
          <input type="file" name="image" accept="image/*" capture="camera" style={{ display: "none", width: "100%", margin: "auto", textAlign: "center", marginTop: "10px" }} multiple={true} onChange={this.handleImgUpload} id="mediaselect" />
        </Box>
        <>
          <Box style={{marginTop: "20px",display: "flex",flexWrap: "wrap" as "wrap",gap: "10px",height:"300px",overflow:"auto"}}>
            {
              this.state.uploadedImagesformedia.map((element: any, index: any) => {
                return (
                  <>
                    <img key={index} src={element.base64convert} style={webStyle.selectedimgstyle} alt="" />
                    <span><i className="fa-solid fa-xmark" style={webStyle.xmarkicon} onClick={this.handleImgDelete.bind(this, index, element)}></i></span>
                  </>
                )
              })
            }
          </Box>
        </>
        {
          this.state.uploadedImagesformedia.length > 0 ? <Box style={{ position: "absolute", width: "100%", padding: "5px", bottom: "0", marginBottom: "10px" }}> <Button variant="contained" style={{ width: "100%", background: "black", color: "white", fontFamily: "MyFont", letterSpacing: "0.8px", textTransform: "capitalize" }} onClick={this.handleImgUploadClose}>Add&nbsp;({this.state.uploadedImagesformedia?.length})</Button></Box> :
            <Box style={{ position: "absolute", width: "100%", padding: "5px", bottom: "0", marginBottom: "10px" }}><Button variant="contained" style={{ width: "100%", background: "#E2E8F0", color: "white", fontFamily: "MyFont", letterSpacing: "0.8px", textTransform: "capitalize" }} >Add</Button></Box>
        }
      </BoxMain>
    )
  }

  LinkSidebaar() {
    return (
      <>
        <BoxMain>
          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px", fontFamily: "MyFont" }} key={1}>
            <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontWeight: "bold",padding:"0px 9px" }}>Add Link</Typography>
            <span onClick={this.drawerClose} style={{ cursor: "pointer" }}><i className="fa-solid fa-xmark"></i></span>
          </Box>
          <Box style={webStyle.borderBottomstyle as any}></Box>
          <Container>

            <Box style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "18px" }}>
              <Box style={{ width: "100%", marginBottom: "10px", fontFamily: "MyFont" }}>
                <label htmlFor="" style={{ fontFamily: "MyFont", letterSpacing: "0.9px", fontWeight: "bold" }}>External link</label> <br />
                <input type="text" value={this.state.externalLink} style={{ width: "100%", padding: "10px 5px", borderRadius: "5px", border: "1px solid #CBD5E1" }} onChange={this.handleexternallink} name="exlink" placeholder="www.example.com" id="" />
              </Box>
              <Box style={{ width: "100%", marginTop: "8px" }}>
                <label htmlFor="" style={{ fontFamily: "MyFont", letterSpacing: "0.9px", fontWeight: "bold" }}>Link title</label><br />
                <p style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontWeight: "normal", fontSize: "11px", color: "#64748B" }}>You can customise the link title will appear on this post</p>
                <input type="text" value={this.state.linktitle} style={{ width: "100%", padding: "10px 5px", borderRadius: "5px", border: "1px solid #CBD5E1" }} onChange={this.handleLinktitle} name="linktitle" placeholder="Enter a name" id="" />
              </Box>
            </Box>
          </Container>


          <Container>
            <Box style={{ position: "absolute", width: "100%", padding: "5px", marginBottom: "5px", bottom: "0px" }}>
              <Button variant="contained" style={{ width: "100%", background: "black", color: "white", textTransform: "capitalize",fontFamily:"MyFont",letterSpacing:"0.8px" }} onClick={this.handleLinkClose}>Save</Button>
            </Box>
          </Container>
        </BoxMain>
      </>
    )
  }

  TagSidebaar() {
    return (
      <>
        <BoxMain>
          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px" }}>
            <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.9px", fontWeight: "bold" }}>Tag Someone</Typography>
            <span onClick={this.drawerClose} style={{ cursor: "pointer" }}><i className="fa-solid fa-xmark"></i></span>
          </Box>

          <Box style={webStyle.borderBottomstyle as any}></Box>

          <Container>
            <Box style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "14px",position:"relative" }}>
              <Box style={{ width: "100%", marginBottom: "10px", border: "1px solid #CBD5E1", marginTop: "20px", display: "flex", alignItems: "center", padding: "2px 4px" }}>
                <i className="fa-solid fa-magnifying-glass" style={{ color: "#DADADA" , cursor: 'pointer'}}></i>
                <input type="text" value={this.state.tagmembervalue} style={{ minWidth: "280px", width: "100%", padding: "10px 5px", borderRadius: "5px", border: "none", outline: "none" }} onChange={this.handlesearchStore} name="exlink" id="" />
              </Box>
              <i className="fa-solid fa-xmark" onClick={this.removevalue} style={{ color: "#0F172A", marginRight: "5px", cursor: "pointer",position:"absolute",right:"10px",top:"45px" }}></i>

            </Box>

            <Box style={{ display: "flex", alignItems: "center", margin: "0 auto",flexWrap:"wrap",padding:"0px 10px" }}>
              {
                this.state.storeselectedTagData && this.state.storeselectedTagData.map((element: any, index: any) => (
                  <Box key={index} style={{ borderRadius: "20px", display: "flex", alignItems: "center", width: "auto", background: "#F1F5F9", flexWrap: "wrap", marginBottom: "15px" }}>
                    <Typography style={{ fontSize: "10px", marginRight: "8px", color: "#475569", padding: "5px" }}>{element.first_name}{" "}{element.last_name}</Typography>
                    <span onClick={this.handleselectTagDelete.bind(this, index)}><i className="fa-solid fa-xmark" style={{ fontSize: "10px", cursor: 'pointer' }}></i></span>
                  </Box>
                ))
              }
            </Box>
            <Box style={{ padding: "10px",height:"200px",overflow:"auto" }}>
              {
                this.state.storeTagData.length > 0 && this.state.storeTagData.map((element: any, index: any) => {
                  const checkdata = this.state.storeselectedTagData.map((ele: any) => ele.id == element.id)
                  return (
                    <>
                      <Box key={index} style={{ display: "flex", justifyContent: "space-between", padding: "4px", alignItems: "center", minHeight: "60px", marginBottom: "10px", marginTop: "10px",borderBottom:"1px solid #E2E8F0",cursor:"pointer" }}>
                        <Box style={{ display: "flex", borderRadius: "10px", alignItems: "center" }} onClick={this.selectTagListdata.bind(this,{ target: { checked: !checkdata.some((ele: any) => ele) } }, element)}>
                          <img src={element.profile_picture?.url} style={{ width: "40px", height: "40px", borderRadius: "50%" }}  alt="" />
                          <Box style={{ marginLeft: "10px"}}>
                            <p>{element.first_name}{" "}{element.last_name}</p>
                            <p>{element.role}</p>
                          </Box>
                        </Box>
                        <span><input type="checkbox" value={element.id} checked={checkdata.some((eleme:any)=>eleme)} onClick={(elementvalue) => this.selectTagListdata(elementvalue, element)} name="" id="" /></span>
                      </Box>
                    </>
                  )
                })
              }

            </Box>
            <Container>
              <Box style={{ position: "fixed", maxWidth: "400px", width: "100%", padding: "5px", marginBottom: "5px", bottom: "0px", color: "white" }}>
                <Button style={{ width: "100%", background: "black", color: "white", textTransform: "capitalize",fontFamily:"MyFont",letterSpacing:"0.8px" }} onClick={this.handleTagClose}>Save</Button>
              </Box>
            </Container>
          </Container>
        </BoxMain>
      </>
    )
  }

  TypesSidebaar() {
    const GreenRadio = withStyles({
      root: {
        color: "gray",
        '&$checked': {
          color: "#73A58E"
        },
      },
      checked: {},
    })((props: RadioProps) => <Radio color="default" {...props} />);
    return (
      <BoxMain>
        <Grid style={webStyle.CategoryHeight}>
        <Container>
          <Box style={webStyle.categoryDropdown}>
            <Typography className="selecttype">Select Type</Typography>
            <span onClick={this.drawerClose} >
              <i className="fa-solid fa-xmark" style={{cursor: 'pointer'}}></i>
            </span>
          </Box>
          <Box style={webStyle.borderBottomStyle}></Box>
          <Container>
            <Box style={webStyle.typeBox}>
              <form id="myForm">
                {
                  this.state.recommedTypes[0]?.data ? (this.state.recommedTypes[0]?.data.map((item) => {
                    return (
                      <>
                        <Box key={item?.id} className="mainBox">
                          <label className="sideBarlabel" htmlFor="C">{item?.attributes.name}</label>
                          <GreenRadio
                            key={item?.id}
                            data-test-id="handleChangeTitle"
                            checked={this.state.selectedValue === `${item.attributes.id}`}
                            onChange={this.handleChange}
                            value={item.attributes.id}
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'C' }}
                          />
                        </Box>
                        <Divider className="mainBoxDivider" />
                      </>)
                  })) : ""
                }
              </form>
            </Box>
          </Container>
        </Container>
        </Grid>
        
        <Container style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', position: 'absolute', borderRadius: '8px', bottom: '0px', marginBottom: '10px'}}>
          <Button variant="contained" className="selectbtns" onClick={this.drawerClose}>Select</Button>
        </Container>
      </BoxMain>
    )
  }

  CitySidebaar() {
    const GreenRadio = withStyles({
      root: {
        color: "gray",
        '&$checked': {
          color: "#73A58E",
        },
      },
      checked: {},
    })((props: RadioProps) => <Radio color="default" {...props} />);
    return (
      <BoxMain>
        <Grid style={webStyle.CategoryHeight}>
        <Box style={webStyle.categoryDropdown}>
          <Typography className="selecttype" >Select City</Typography>
          <span onClick={this.drawerClose} >
            <i className="fa-solid fa-xmark" style={{cursor: 'pointer'}}></i>
          </span>
        </Box>
        <Box style={webStyle.borderBottomStyle}></Box>
        <Container>
          <Box style={webStyle.typeBox}>
            <form id="Form">
              {
                this.state.cityData.length > 0 ? (this.state.cityData.map((item: any) => {
                  return (
                    <>
                      <Box key={item?.id} className="mainBox">
                        <label className="sideBarlabel" htmlFor="C">{item.name}</label>
                        <GreenRadio
                          key={item?.id}
                          data-test-id="handleChangeCity"
                          value={item.id}
                          checked={this.state.selectedValueCity === `${item.id}`}
                          onChange={this.handleChangeCity}
                          name="radio-button-demo"
                          inputProps={{ 'aria-label': 'C' }}
                        />
                      </Box>
                      <Divider className="mainBoxDivider" />
                    </>
                  )
                })) : ""
              }
            </form>
          </Box>
        </Container>
        </Grid>
        <Container style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', position: 'absolute', borderRadius: '8px', bottom: '0px', marginBottom: '10px'}}>
          <Button variant="contained" className="selectbtns" onClick={this.drawerClose}>Select</Button>
        </Container>
      </BoxMain>
    )
  }

  renderTabs = () => {
    const { editPostId, isrecontEdit, TabsView, open } = this.state;
    if (editPostId === "" || editPostId === null) {
      return (
        <>
          <Typography
            data-test-id="postcreation"
            className={TabsView === "PostCreation" ? "posttab" : "postcss"}
            style={{ cursor: "pointer" }}
            onClick={() => this.handleTabChange("PostCreation")}
          >
            Post
          </Typography>
          <Typography
            data-test-id="recommendfationId"
            className={TabsView === "PostRecommendation" ? "posttab" : "recommendation"}
            style={{ cursor: "pointer" }}
            onClick={() => this.handleTabChange("PostRecommendation")}
          >
            Recommendations
          </Typography>
        </>
      );
    } else if (!isrecontEdit) {
      return (
        <Typography
          data-test-id="postcreation"
          className={TabsView === "PostCreation" ? "posttab" : "postcss"}
          style={{ cursor: "pointer" }}
          onClick={() => this.handleTabChange("PostCreation")}
        >
          Post
        </Typography>
      );
    } else {
      return (
        <Typography
          data-test-id="recommendfationId"
          className={TabsView === "PostRecommendation" ? "posttab" : "recommendation"}
          style={{ cursor: "pointer" }}
          onClick={() => this.handleTabChange("PostRecommendation")}
        >
          Recommendations
        </Typography>
      );
    }
  };


  render() {
    const { Contentchange, dynamicsetwidth, TabsView, open } = this.state;
    let sidebarContent;

    switch (Contentchange) {
      case "Document":
        sidebarContent = this.AddDocumentSidebaar();
        break;
      case "category":
        sidebarContent = this.selectCategorySidebar();
        break;
      case "Image":
        sidebarContent = this.ImgSIdebaar();
        break;
      case "Link":
        sidebarContent = this.LinkSidebaar();
        break;
      case "tag":
        sidebarContent = this.TagSidebaar();
        break;
      case "types":
        sidebarContent = this.TypesSidebaar();
        break;
      case "city":
        sidebarContent = this.CitySidebaar();
        break;
      default:
        sidebarContent = null;
    }


    return (
      <BoxCustom>
        <AppHeader component={"Add"} />
        <Container style={{ display: "flex", flexDirection: "column", alignItems: "center", background: "#F8FAFC", opacity: this.state.open ? "30%" : "100%"  }}>
          <Card style={{ maxWidth: 750, marginBottom: "25px", width: "100%", marginTop: dynamicsetwidth && dynamicsetwidth < "500" ? "" : "5px", fontFamily: "MyFont", minHeight: "100vh" }}>
            <Box className="tabStyle">
              {this.renderTabs()}
            </Box>
            <CardContent>
              {TabsView === "PostCreation" ? this.postCreationView() : this.postrecommendationsView()}
            </CardContent>
          </Card>
          <CustomDrawer anchor="right" open={open} onClose={() => this.toggleDrawer(false, "")}>
            {sidebarContent}
          </CustomDrawer>
        </Container>
        <Toaster />
        <AppFooter component={"Add"} />
      </BoxCustom>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const BoxMain1 = styled(Box)({
  width: "400px",
  fontFamily: "MyFont !important",
  "@media(max-width: 600px)": {
    width: '200px',
  },
  '& .titleReason': {
    fontSize: "14px"
  },
  "& .mainBox": {
    padding: '4px',
    fontWeight: "400",
    marginBottom: '5px',
    paddingLeft: "10px",
    display: 'flex',
    height: '30px',
    fontFamily: "MyFont",
    justifyContent: 'space-between',
    fontSize: "12px"
  },
  "& .mainBoxDivider": {
    width: "95%",
    marginLeft: "10px"
  },
  "& .mediatitle": {
    display: "flex",
    width: "370px",
    fontWeight: "bold",
    fontFamily: "MyFont",
    margin: "15px",
    paddingLeft: "12px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .content-container": {
    paddingBottom: "80px",
    overflowY: "auto",
    height: "calc(100vh - 200px)",
  },
  "@media (max-width: 600px)": {
    "& .mediatitle": {
      width: "150px",
    },
  },
  "& .button-container": {
    "@media (max-width: 600px)": {
      width: '200px',
    },
    background: "white",
    width: "400px",
    position: "fixed",
    boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
    bottom: 0,
    padding: "10px 0", // Adjust as needed
  },
  '@media (max-width: 601px)': {
    '& .selectbtn': {
      marginTop: "0", // No need for extra margin
    },
  },
  '& .selectbtn': {
    color: "white",
    textTransform: "none",
    background: "#14362E",
    width: "95%",
    fontFamily: "MyFont"
  },
  '& .sideBarlabel': {
    letterSpacing: "8%",
    fontFamily: "MyFont",
    color: "#475569",
  },
  '& .selecttype': {
    fontSize: "24px",
    color: '#14362E',
    fontWeight: "bold",
    paddingLeft: '5px',
  },
  '& .flaggingcontent': {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'start',
    padding: '14px',
    justifyContent: 'center',
    color: "#0F172A",
    letterSpacing: "0.08em",
    fontWeight: "700",
    fontSize: "16px",
  },
  "& .typebox": {
    paddingLeft: "18px",
    paddingBottom: "15px",
    display: "flex",
    paddingTop: "15px",
  },
  "& .checkedCircle": {
    paddingTop: "10px",
    color: "#14362E",
    width: "27.7px",
    height: "27.7px",
  },
  "& .typebox:hover": {
    backgroundColor: "#D4E5DE"
  }
});
const BoxMain = styled(Box)({

  width: "425px",
  height: "100vh",
  "@media(max-width: 600px)": {
    width: '100vw',
  },
  "& .mainBox": {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '4px',
    height: '30px',
    marginBottom: '5px',
    paddingLeft: "10px",
    fontFamily: "MyFont",
    fontsize: "12px",
    font: "text-sm/font-normal",
    fontWeight: "400"
  },
  "& .mainBoxDivider": {
    marginLeft: "10px",
    width: "95%"
  },
  "& .mediatitle": {
    margin: "15px",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "12px",
    fontFamily: "MyFont"
  },
  "@media (max-width: 600px)": {
    "& .mediatitle": {

    },
  },
  '& .selectbtns': {
    width: "90%",
    background: "#14362E",
    color: "white",
    textTransform: "none",
    fontFamily: "MyFont"
  },
  '& .selectbtn': {
    width: "100%",
    background: "#14362E",
    color: "white",
    textTransform: "none",
    fontFamily: "MyFont"
  },
  '& .selecttype': {
    paddingLeft: '5px',
    color: '#14362E',
    fontWeight: "bold",
    fontSize: "24px",
  },
  '& .sideBarlabel': {
    fontFamily: "MyFont",
    color: "#475569",
    letterSpacing: "8%"
  }
})
const CustomDrawer = styled(Drawer)({
  minWidth: "540px",
  marginTop: "100px",
  display: "block", // Default value for 'xs'
  "& .MuiDrawer-paper": {
    boxSizing: "border-box",
    maxWidth: "100%",
    borderRadius: '24px 0px 0px 0px',
  },
  "& .MuiPaper-elevation16":{
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 4px 9px  !important",
  },
  fontFamily: "MyFont",
})

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-input":{
    fontFamily: "MyFont",
    color: "black",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#64748B",
    fontSize: "16px",
    fontFamily: "MyFont",
    height: "20px",
    fontWeight: 500,
    width: "685px",
  },
  "& .MuiInputBase-input.Mui-focused::placeholder": {
    color: "#64748B",
    height: "20px",
    fontSize: "16px",
    fontWeight: 500,
    width: "685px"

  }
})

const Discription = styled(Box)({
  "& .textarea-custom::placeholder": {
    color: "#64748B",
    fontSize: "14px",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  "& .typotext": {
    fontFamily: "'MyFont', 'Halyard Display'",
    color: "#64748B",
    fontSize: "14px",
  }
})

const BoxCustom = styled(Box)({
  '& .recommendation': {
    background: "#F8FAFC",
    color: "#64748B",
    marginLeft: "10px",
    fontFamily: "MyFont",
    width: "auto",
    padding: "2px 4px",
    borderRadius: 25,

  },
  '& .posttab': {
    background: "#F8FAFC",
    border: "2px solid #73A58E",
    width: "180px",
    padding: "2px 4px",
    borderRadius: 25,
    textAlign: "center" as 'center',
    cursor: "pointer",
    fontFamily: "'MyFont', 'Halyard Display'",
    color: "var(--900, #14362E)",
    fontWeight: 'bold'
  },
  "& .postcss": {
    width: '140px',
    padding: "2px 4px",
    textAlign: 'center' as 'center',
    marginLeft: "10px",
    background: "#F8FAFC",
    color: "#64748B",
    fontFamily: "'MyFont', 'Halyard Display'",
    borderRadius: 25,
  },
  "& .tabStyle": {
    display: "flex",
    backgroundColor: "#Neutrals/Cool gray/50",
    margin: "auto",
    marginTop: "20px",
    padding: "20px 8px",
    justifyContent: "center"
  },
  "& .drawerStyle": {
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 4px 9px !important"
  },
  "@media(max-width: 600px)": {
    "& .tabStyle": {
      display: "flex",
      backgroundColor: "#Neutrals/Cool gray/50",
      margin: "auto",
      marginTop: "20px",
      padding: "20px 8px",
      justifyContent: "center"
    },
    '& .recommendation': {
      background: "#F8FAFC",
      color: "#64748B",
      marginLeft: "10px",
      fontFamily: "MyFont",
      width: "auto",
      padding: "2px 4px"
    },
  }
})
const webStyle = {
  btnSpacing:{
    margin:"0 5px",
    padding:"0px 30px"
  },
  CategoryHeight:{
   
  },
  InputAdornmcss: {
    marginTop: "2%",
    borderRadius: "8px",
  },
  xmarkicon: {
    marginLeft: "-12px",
    color: "#64748B",
    background: "white",
    borderRadius: "50%",
    padding: "2px 5px",
    marginRight: "10px",
    border: "1px solid #E2E8F0",
    cursor: 'pointer'
  },
  typeBox: {
    marginTop: "30px",
    padding: "20px 10px",
    height: "60vh",
    maxHeight: "60vh",
    overflowY: "scroll" as "scroll", 
  },
  postcss: {
    width: 'auto',
    textAlign: 'center' as 'center',
    marginLeft: "10px",
    background: "#F8FAFC",
    color: "#64748B",
    fontFamily: "MyFont",
    padding: "2px 4px"
  },
  categoryDropdown: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px'
  },
  recommendation: {
    background: "#F8FAFC",
    color: "#64748B",
    marginLeft: "10px",
    fontFamily: "MyFont",
    width: "auto",
    padding: "2px 4px"
  },
  borderBottomStyle: {
    borderBottom: '1px solid #CBD5E1'
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '14px'
  },
  searchBox: {
    width: '100%',
    marginBottom: '10px',
    border: '1px solid #CBD5E1',
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center'
  },
  searchInput: {
    width: '100%',
    padding: '10px 5px',
    borderRadius: '5px',
    border: 'none',
    outline: 'none'
  },
  selectedTag: {
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    width: '80px',
    background: '#F1F5F9',
    flexWrap: 'wrap',
    marginBottom: '15px'
  },
  selectedTagText: {
    fontSize: '10px',
    marginRight: '8px',
    color: '#475569',
    padding: '5px'
  },
  seletionCat: (isSelected: string) => ({
    fontSize: 12,
    marginRight: 10,
    backgroundColor: isSelected ? "black" : "#E2E8F0",
    color: isSelected ? "white" : "#64748B",
    padding: 7,
    borderRadius: 20,
    marginBottom: 10,
    cursor: "pointer",
    fontFamily: "MyFont"
  }),
  tagImage: {
    width: '40px',
    height: '40px',
    borderRadius: '50%'
  },
  tagInfo: {
    marginLeft: '10px'
  },

  uploadmedia: {
    display: 'inline-block',
    height: "24px",
    fontFamily: "Halyard Display",
    fontSize: "16px",
    fontWeight: 900,
    lineHeight: "24px",
    letterSpecing: '0.08em',
    textAlign: 'left' as 'left',
    color: '#14362E'
  },
  mediabox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '-15px'
  },

  plusIcon: {
    width: "17px",
    height: "17px",
    color: "#14362E"
  },
  buckupicon: {
    width: "48",
    height: "32",
    color: '#94A3B8',
    marginTop: "-15px"
  },
  desxcriptiontext: {
    marginTop: '-15px',
    color: "#64748B",
    fontFamily: "Halyard Display",
    fontSize: "14px",
    fontWeight: 500,
    letterSpecing: "0.08em",
  },
  recommendationtab: {
    border: "2px solid #73A58E",
    width: "50%",
    borderRadius: "35%",
    cursor: "pointer",
    fontFamily: "MyFont"
  },
  postmainparent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  postmainbox: {
    display: "flex",
    alignItems: "center"
  },
  postheader: {
    paddingLeft: "10px",
    fontWeight: "700",
    fontFamily: "MyFont"
  },
  btnShare: {
    background: "#F1F5F9",
    color: "#CBD5E1",
    width: "90px",
    fontFamily: "MyFont"
  },
  firstname: {
    fontSize: "16px", fontWeight: 700, color: "#334155", fontFamily: "MyFont", letterSpacing: "1.4"
  },
  titlbox: {
    paddingTop: "10px"
  },
  categoryFirstBox: {
    display: 'flex',
    alignItems: "center"
  },
  categoryMainBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "35px"
  },
  textareastyle: {
    maxWidth: "800px",
    width: "100%",
    minHeight: "200px",
    borderRadius: 10,
    padding :"12px",
    borderColor: "#CBD5E1",
    marginTop: "20px",
    fontFamily: "MyFont",
    fontSize:"18px"
  },
  selectCircle: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "MyFont"
  },
  InputLabelStyle: {
    fontSize: "16px",
    fontWeight: 800,
    color: "#334155",
    fontFamily: "MyFont",
    letterSpacing: "1.4",
    marginBottom: "5px"
  },
  mediaselect: {
    color: "#64748B",
    fontSize: 20,
    width: '150px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: "10px",
    fontFamily: "MyFont"
  },
  selectCategoryCss: {
    paddingLeft: "10px",
    fontFamily: "MyFont"
  },
  selectCategoryBold: {
    paddingLeft: "10px",
    fontFamily: "MyFont",
    color: "#14362E",
    fontWeight: 700,
    fontSize: "24px",
    height: "32px"
  },
  selectedImg: {
    marginTop: "20px",
    display: "flex",
    flexWrap: "wrap" as "wrap",
    gap: "10px"
  },
  selectedimgstyle: {
    width: "100px",
    height: "100px",
    marginRight: '10px',
    borderRadius: 10
  },
  categoryDropwdown: {
    margin: "15px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontFamily: "MyFont",
  },
  borderBottomstyle: {
    border: "1px solid #E2E8F0"
  },
  selectctegoryParent: {
    padding: "40px",
  },
  selectcategory: {
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 10,
    fontFamily: "MyFont"
  },

  subcategoryparent: {
    width: "300px",
    padding: "10px",
    display: "flex",
    flexWrap: "wrap",
  },
  adddocumentStyle: {
    border: "2px solid #CBD5E1",
    height: "200px",
    marginTop: "20px",
    borderStyle: "dotted",
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: "MyFont",
    cursor:"pointer"
  },
  uploadfileparent: {
    marginTop: "20px"
  },
}
// Customizable Area End