import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("./config");
import toast from 'react-hot-toast';
import { getStorageData } from "framework/src/Utilities";

type RecommendationTypeAttributes = {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
};

type RecommendationType = {
  id: string;
  type: "recommendation_type";
  attributes: RecommendationTypeAttributes;
};

type ResponseJson = {
  data: RecommendationType[];
};
interface City {
  id: number;
  name: string;
  country: string;
}

interface CityData {
  data: City[];
}
// Customizable Area End


export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  PostData: [];
  token: string;
  name: string;
  description: string;
  body: string;
  recommendationDescription: string,
  newBody:string;
  price: string;
  currency: string;
  category_id: string;
  sub_category_id: string;
  categoryname: any,
  subcategoryname: any,
  Contentchange: any;
  image: string | undefined;
  uploadedImages: any;
  uploadedImagesformedia: any;
  newAddedImg: any;
  newAddedmedia: any;
  externalLink: any;
  linktitle: any;
  linkset: any;
  storeTagSet: boolean,
  AllCategory: any;
  id: string;
  refresh: boolean;
  file: string;
  profileImageData: { data: string | null | undefined, content_type: string, filename: string };
  selectedCategory: any;
  allCategories: { value: string, label: string }[];
  subCategories: any
  TabsView: string,
  open: any,
  imgupload: any,
  storeTagData: any,
  singleMediaUpload: any,
  storeselectedTagData: any,
  selectedValue: string,
  recommedTypes: ResponseJson[],
  recomendTitle: string,
  isRecommendation: boolean,
  selectedType: string,
  cityData: string[],
  editPostId: string,
  deletedImages: any,
  deletedformedia: any,
  permission: any,
  selectedCity: string,
  selectedValueCity: string,
  upImagesUpload2: any,
  upImagesUpload: any,
  buttonenable: boolean,
  userprofile: string,
  parentCategoryId: any,
  dynamicsetwidth: any,
  selectedSubcategories: any,
  parentCategory: any,
  isrecontEdit:any,
  tagmembervalue:any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PostCreationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPostItemCallId: string = "";
  apiGetTagDataId: string = "";
  apiGetCategoryCallID: string = "";
  apiGetSubCategoryCallId: string = "";
  PostApiCallId: string = "";
  DeleteApiCallId: string = "";;
  addpostApiCallId: string = "";;
  updatePostApiCallId: string = "";;
  apiGetTypesCallID: string = "";
  addRecommendApiId: string = "";
  apiGetCityCallID: string = "";
  apiGetPostCallID: string = "";
  updateMypostCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      token: "",
      PostData: [],
      name: "",
      description: "",
      body: "",
      recommendationDescription: "",
      newBody:"",
      price: "",
      currency: "$",
      category_id: "",
      sub_category_id: "",
      categoryname: "",
      subcategoryname: "",
      image: "",
      id: "",
      uploadedImages: [],
      uploadedImagesformedia: [],
      newAddedmedia: [],
      newAddedImg: [],
      externalLink: "",
      linktitle: "",
      linkset: false,
      storeTagSet: false,
      AllCategory: [],
      Contentchange: "",
      file: "",
      refresh: false,
      profileImageData: { data: null, content_type: "", filename: "" },
      selectedCategory: {},
      allCategories: [],
      subCategories: [],
      TabsView: "PostCreation",
      open: false,
      imgupload: false,
      storeTagData: [],
      singleMediaUpload: false,
      storeselectedTagData: [],
      permission: "true",
      selectedValue: "",
      recommedTypes: [],
      deletedImages: [],
      deletedformedia: [],
      recomendTitle: "",
      isRecommendation: true,
      selectedType: "",
      editPostId: "",
      cityData: [
      ],
      selectedCity: "",
      selectedValueCity: "",
      upImagesUpload: [],
      upImagesUpload2: [],
      buttonenable: false,
      dynamicsetwidth: "",
      selectedSubcategories: [],
      parentCategoryId: [],
      userprofile: "",
      parentCategory: [],
      isrecontEdit: "",
      tagmembervalue:""
    };
    // Customizable Area End
    console.disableYellowBox = true;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    let editPostId = await getStorageData("editPostId");
    window.addEventListener('resize', this.handleResize);
    let userprofile = await getStorageData("userprofile")
    // Set initial local storage value
    localStorage.setItem("dynamicwidth", window.innerWidth.toString());
    this.getAllCities();
    this.setState({
      editPostId: editPostId,
      userprofile: userprofile as unknown as string
    })
    if (this.state.editPostId !== '') {
      this.getSinglePostdata();
    }
    let authTokenReq = new Message(getName(MessageEnum.
      SessionRequestMessage));
    this.send(authTokenReq);
    this.getAllCategory()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      runEngine.debugLog("API Message Received", message);

      if (responseJson) {
        if (responseJson.errors) {
          this.handleErrors(responseJson.errors);
        } else {
          this.handleApiResponse(apiRequestCallId, responseJson);
        }
      } else if (errorResponse) {
        this.handleErrors(errorResponse);
      }
    }
  }

  handleErrors(errors: any) {
    this.parseApiErrorResponse(errors);
    this.parseApiCatchErrorResponse(errors);
  }


  handleApiResponse(apiRequestCallId: string, responseJson: any) {
    switch (apiRequestCallId) {
      case this.apiPostItemCallId:
        // Handle specific API call response
        break;
      case this.addpostApiCallId:
        toast.success('Post sucessfully created')

        setTimeout(() => {
          this.setState({ buttonenable: false })
          window.location.href = "NewsFeed";
        }, 2000)
        break;
      case this.apiGetCategoryCallID:
        this.updateCategories(responseJson.data);
        break;
      case this.apiGetSubCategoryCallId:
        this.updateSubCategories(responseJson.data);
        break;
      case this.apiGetTagDataId:
        this.updateStoreTagData(responseJson.accounts);
        break;
      case this.apiGetTypesCallID:
        this.gettypesData(responseJson);
        break
      case this.apiGetCityCallID:
        this.getCitiesData(responseJson);
        break;
      case this.apiGetPostCallID:
        this.getPostData(responseJson);
        break;
      case this.updateMypostCallId:
        this.getUpdatePostData(responseJson);
        break;
      case this.addRecommendApiId:
        toast.success('Recomenadation Post sucessfully created')
        this.setState({
          recomendTitle: "",
          selectedValue: ""
        })
        window.location.href = "NewsFeed";
        break;
      default:
        // Handle default case
        break;
    }
  }
  gettypesData(responseJson: ResponseJson) {
    this.setState({
      recommedTypes: [responseJson]
    })
  }

  getCitiesData(responseJson: any) {
    this.setState({
      cityData: responseJson.data
    })
  }
  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  async getUpdatePostData(responseJson: any) {
    if (!responseJson.data) {
      toast.error(responseJson.errors[0].message);
    } else {
      toast.success('Post sucessfully Updated!')
      setTimeout(() => {
        window.location.href = "UserProfileBasicBlock";
      }, 1100)
    }
  }

  async getPostData(responseJson: {
    data: {
      attributes: {
        name: string;
        city: string;
        images_and_videos: { id: string };
        tagged_users: { id: string };
        media: { id: string };
        link_title: string;
        sub_category: string; body: string;
        sub_category_ids: [{ id : string}]
        is_recommendation: boolean,
        recommendation_type:{
          name: string
        }
      }
    }
  }) {

    if(responseJson.data.attributes.is_recommendation) {
         this.setState({
            isrecontEdit: true,
            TabsView: "PostRecommendation"
         })
    } else  {
      this.setState({
        isrecontEdit: false,
        TabsView: "PostCreation",
     })
    }

    const media = Array.isArray(responseJson.data.attributes.media) ? responseJson.data.attributes.media : [responseJson.data.attributes.media];

    const base64 = [];

    for (const item of media) {
      const imageObject = {
        id: item.id,
        filename: item.filename,
        contentType: item.content_type,
        url: item.url,
        base64convert: await this.convertToBase64(item.url) // Convert to base64
      };
      base64.push(imageObject);
    }

    const media1 = Array.isArray(responseJson.data.attributes.images_and_videos) ? responseJson.data.attributes.images_and_videos : [responseJson.data.attributes.images_and_videos];

    const base65 = [];

    for (const item of media1) {
      const imageObject = {
        id: item.id,
        filename: item.filename,
        contentType: item.content_type,
        url: item.url,
        base64convert: await this.convertToBase64(item.url) // Convert to base64
      };
      base65.push(imageObject);
    }
    this.handleSetPostData({responseJson, base64, base65});
  }
  handleSetPostData({responseJson, base64, base65}: {responseJson: any, base64: any[],base65: any[]}) {
    this.setState({
      newBody:responseJson.data.attributes.body,
      body: responseJson.data.attributes.body,
      recomendTitle: responseJson.data.attributes.name, 
      selectedCity: responseJson.data.attributes.city,
      selectedType:  responseJson.data.attributes.is_recommendation ? responseJson.data.attributes.recommendation_type.name: '',
      subcategoryname: responseJson.data.attributes.sub_category,
      linktitle: responseJson.data.attributes.link_title !== "" && responseJson.data.attributes.link_title,
      uploadedImagesformedia: base64,
      storeselectedTagData: responseJson.data.attributes.tagged_users,
      uploadedImages: base65,
      upImagesUpload2: base64,
      selectedSubcategories: responseJson.data.attributes.sub_category_ids.map((item:{ id: string}) => {
         return `${item.id}`
      }),
      upImagesUpload: responseJson.data.attributes.is_recommendation ? base64 : base65,
    })
  }
  convertToBase64 = async (url: RequestInfo) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  }

  updateCategories(posts: any) {
    const categories: string[] = [];
    const allCategories: { value: string; label: string }[] = [];

    posts.forEach((item: { attributes: { name: string; id: string } }) => {
      if (!categories.includes(item.attributes.name)) {
        const category = {
          value: item.attributes.id,
          label: item.attributes.name,
        };
        allCategories.push(category);
        categories.push(item.attributes.name);
      }
    });

    this.setState({
      AllCategory: posts,
      allCategories: allCategories,
    });
  }

  handlelinkupdate=()=>{
    this.setState({open:true,Contentchange:"Link"})
  }

  handleLinkremove = ()=>{
    this.setState({externalLink:"",linktitle:"",linkset:"false"})
  }

  updateSubCategories(subCategories: any) {
    this.setState({ subCategories: subCategories });
  }

  updateStoreTagData(storeTagData: any) {
    this.setState({ storeTagData: storeTagData });
  }

  createPostCreation = () => {
    if (this.state.editPostId) {
      this.updateMyPost('post');
    } else {
      if (
        this.state.category_id === "" ||
        this.state.body === ""
      ) {
        toast.error('Please Enter category , description')
      } else {
        this.AddPostCreation();
      }
    }
  }

  recPostCreation = () => {
    if (this.state.editPostId) {
      this.updateMyPost('recommendation');
    } else {
      if (
        this.state.recommendationDescription === "" ||
        this.state.recomendTitle === "" ||
        this.state.selectedValue == ""
      ) {
        toast.error('Please Enter Title , description , Type')
      } else {
        this.AddRecPostCreation();
      }
    }

  }

  AddPostCreation = () => {
    const header = {
      token: localStorage.getItem("accessToken")
    };

    this.setState({ buttonenable: true })

    const accountId: any = localStorage.getItem("account_id")

    let formdata = new FormData()
    formdata.append("sub_category_id", this.state.sub_category_id);
    formdata.append("body", this.state.body);
    formdata.append("account_id", accountId);
    formdata.append("external_link", this.state.externalLink);
    formdata.append("link_title", this.state.linktitle);
    formdata.append("is_public", this.state.permission);

    for (const image of this.state.parentCategoryId) {
      formdata.append("category_ids[]", image);
    }

    for (const image of this.state.selectedSubcategories) {
      formdata.append("sub_category_ids[]", image);
    }

    for (const image of this.state.uploadedImages) {
      formdata.append("media[]", image.store);
    }


    for (const imagemedia of this.state.uploadedImagesformedia) {
      formdata.append("images[]", imagemedia.store);
    }

    for (const tagdata of this.state.storeselectedTagData) {
      formdata.append("tag_list[]", tagdata.id);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addpostApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_posts/posts'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  AddRecPostCreation = () => {
    const header = {
      token: localStorage.getItem("accessToken")
    };

    let formdata = new FormData()
    formdata.append("name", this.state.recomendTitle);
    formdata.append("body", this.state.recommendationDescription);
    formdata.append("city", this.state.selectedCity);
    formdata.append("recommendation_type_id", this.state.selectedValue);
    formdata.append("is_recommendation", `${this.state.isRecommendation}`);

    for (const image of this.state.uploadedImages) {
      formdata.append("media[]", image.store);
    }

    for (const imagemedia of this.state.uploadedImagesformedia) {
      formdata.append("images[]", imagemedia.store);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addRecommendApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_posts/posts'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleChange = (event: { target: { value: string } }) => {
    this.setState({
      selectedValue: event.target.value
    }, () => {
      this.state.recommedTypes[0].data.forEach((item) => {
        if (item.id == this.state.selectedValue) {
          this.setState({
            selectedType: item?.attributes.name
          })
        }
      })
    })
  }

  handleChangeCity = (event: { target: { value: string } }) => {
    this.setState({
      selectedValueCity: event.target.value
    }, () => {
      this.state.cityData && this.state.cityData.map((item: any) => {
        if (`${item.id}` === this.state.selectedValueCity) {
          this.setState({
            selectedCity: item?.name?.trim()
          })
        }
      })
    })
  }

  getAllCategory() {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem("accessToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCategoryCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllCatergoryEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getAllTypes() {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem("accessToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetTypesCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRecommendationAPI
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getSinglePostdata() {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem("accessToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPostCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSinglePostApi + this.state.editPostId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updateMyPost = async (editFrom: string) => {

    const header = {
      token: await getStorageData("accessToken")
    };
    const formData = new FormData();
    formData.append("name", this.state.recomendTitle);
    editFrom === "post" ? formData.append("body", this.state.body) : formData.append("body", this.state.recommendationDescription);
    formData.append("category_id", this.state.category_id);
    formData.append("sub_category_id", this.state.sub_category_id);
    formData.append("is_public", this.state.permission);
    formData.append("external_link", this.state.externalLink);
    formData.append("link_title", this.state.linktitle);


    for (const image of this.state.newAddedImg) {
      formData.append("images[]", image.store);
    }

    for (const image of this.state.deletedImages) {
      formData.append("remove_image_ids[]", image.id);
    }

    for (const imagemedia of this.state.newAddedmedia) {
      formData.append("media[]", imagemedia.store);
    }

    for (const image of this.state.deletedformedia) {
      formData.append("remove_media_ids[]", image.id);
    }

    for (const tagdata of this.state.storeselectedTagData) {
      formData.append("tag_list[]", tagdata.id);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.updateMypostCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updatePostApi + this.state.editPostId,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PutApicallId,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllCities() {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem("accessToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCityCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCitiesApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleCatChangeChange = (selectedOption: any) => {
    this.setState({
      selectedCategory: selectedOption.attributes.name,
      category_id: selectedOption.attributes.id
    });

    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem("accessToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSubCategoryCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/sub_categories?category_id=${selectedOption.attributes.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handletitlechange = (e: { target: { value: string; }; }) => {
    this.setState({
      recomendTitle: e.target.value
    })
  }

  toggleDrawer = (e: any, content: any) => {
    this.setState({ open: e, Contentchange: content })
    this.getAllCategory()
  }

  drawerClose = () => {
    this.setState({ open: false })
  }

  handleBodyChange = (e: any, fromPost: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const originalText = e.target.value.replace(configJSON.regexforurl, '$1');
    const data = originalText.replace(urlRegex, (url:any) => {
      return `<a href="${url}" target="_blank" style="color:blue; font-weight:bold;">${url}</a>`;
    });

    fromPost === 'post' ? this.setState({ newBody: e.target.value, body: data }) : this.setState({ recommendationDescription: data });

  }
  
  handleselectCategoryData = (category: {ele: { id: string }}) => {
    const { selectedSubcategories } = this.state;
    const subCategoryId = category.ele.id;
  
    if (selectedSubcategories.includes(subCategoryId)) {
      const updatedSelection = selectedSubcategories.filter((id: string) => id !== subCategoryId);
      this.setState({ selectedSubcategories: updatedSelection });
      return; 
    }

    this.setState({ selectedSubcategories: [...selectedSubcategories, subCategoryId] });
  }
  getSelectedSubcategoryNames() {
    const { AllCategory, selectedSubcategories } = this.state;
    let names: string[] = [];

    if (Array.isArray(AllCategory)) {
  
    AllCategory && AllCategory.forEach((category: { attributes: { sub_categories: [{ id: string , attributes: { name: string }}]}}) => {
      category.attributes?.sub_categories.forEach((subCategory: { id: string , attributes: { name: string }}) => {
        if (selectedSubcategories.includes(subCategory.id)) {
          names.push(subCategory.attributes.name);
        }
      });
    });
  }
      return names.slice(0, 5);
  }

  handleTabChange = (e: string) => {
    
    this.setState({ TabsView: e })
    this.getAllTypes();
    this.getAllCities();
  }

  handleMediaupload = (e: any) => {
    const files = e.target.files;
    const newImages: { base64convert: string; store: any; }[] = [];

    const supportedTypes = ['image/png', 'image/jpeg', 'video/mp4', 'video/x-matroska', 'video/x-msvideo', 'video/quicktime'];

    for (const item of files) {

      if (supportedTypes.includes(item.type)) {
        newImages.push({ base64convert: URL.createObjectURL(item), store: item });
      } else {
        toast.error("We support PNG,  JPG,  MP4, MKV, AVI, MOV ")
      }
    }
    this.setState((prevState) => ({
      uploadedImages: [...prevState.uploadedImages, ...newImages],
      newAddedImg: [...prevState.newAddedImg, ...newImages]
    }));
  }

  handlefileDelete = (e: any, element: any) => {

    const deleteSelectedfile = this.state.uploadedImages.filter((element: any, index: any) => {
      return index !== e
    })
    this.setState({
      uploadedImages: deleteSelectedfile,
      upImagesUpload: deleteSelectedfile
    })

    this.setState((prevState) => ({
      deletedImages: [...prevState.deletedImages, element]
    }));
  }

  handlefileuploadClose = () => {
    this.setState({
      upImagesUpload: this.state.uploadedImages
    })
    this.toggleDrawer(false, "")
    this.setState({ imgupload: "true" })
  }

  handleImgUploadClose = () => {
    this.setState({
      upImagesUpload2: this.state.uploadedImagesformedia
    })
    this.toggleDrawer(false, "")
    this.setState({ singleMediaUpload: "true" })
  }

  handleImgUpload = (e: any) => {
    const files = e.target.files;
    if (files.length > 0) {
      const base64Img: { base64convert: string; store: string }[] = [];
      for (const item of files) {
        base64Img.push({ base64convert: URL.createObjectURL(item), store: item });
      }

      this.setState(prevState => ({
        uploadedImagesformedia: [...prevState.uploadedImagesformedia, ...base64Img]
      }));
      this.setState(prevState => ({
        newAddedmedia: [...prevState.newAddedmedia, ...base64Img]
      }));
    }
  };


  handleImgDelete = (e: any, element: any) => {
    const deleteSelectedImg = this.state.uploadedImagesformedia.filter((ele: any, index: any) => {
      return index !== e;
    })

    const deleteSelectedImgs = this.state.upImagesUpload.filter((ele: any, index: any) => {
      return element.store.name !== ele.store.name
    })

    this.setState({
      uploadedImagesformedia: deleteSelectedImg,
      upImagesUpload2: deleteSelectedImg,
      upImagesUpload: deleteSelectedImgs,
    })

    this.setState((prevState) => ({
      deletedformedia: [...prevState.deletedformedia, element]
    }));
  }

  handleexternallink = (e: any) => {
    this.setState({ externalLink: e.target.value })
  }

  handleLinktitle = (e: any) => {
    this.setState({ linktitle: e.target.value })
  }

  handleLinkClose = () => {
    if (this.state.externalLink == "") {
      toast.error('Please Enter External Link')
      this.toggleDrawer(false, "")
    } else if (this.state.linktitle == "") {
      toast.error('Please Enter Link Title')
    } else {
      this.toggleDrawer(false, "")
      this.setState({ linkset: "true" })
    }
  }

  handleTagClose = () => {
    if (this.state.storeselectedTagData.length > 0) {
      this.toggleDrawer(false, "")
      this.setState({ storeTagSet: true })
    } else {
      toast.error("Please tag User")

    }
  }

  handlesearchStore = (event:any)=>{
    this.setState({tagmembervalue:event.target.value})
    this.handlesearchTag(event)
  }

  removevalue = ()=>{
    this.setState({tagmembervalue:""});
    this.setState({storeTagData:[]})
  }

  handlesearchTag = (e: any) => {

    const searchdata = e.target.value
    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem("accessToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetTagDataId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_posts/people_search?search=${searchdata}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  selectTagListdata = (elementdata: any, data: any) => {
    if (elementdata.target?.checked) {

      const IteamIndex = this.state.storeselectedTagData.findIndex((iteam: any) => iteam.id === data.id);

      if (IteamIndex < 0) {
        const storetag = [...this.state.storeselectedTagData, data];
        this.setState({ storeselectedTagData: storetag })
      }
    } else {
      const deleteSelectedTag = this.state.storeselectedTagData.filter((element: any, index: any) => {
        return element.id !== data.id
      })

      this.setState({ storeselectedTagData: deleteSelectedTag });
    }

  }

  handleselectTagDelete = (e: any) => {
    const deleteSelectedTag = this.state.storeselectedTagData.filter((element: any, index: any) => {
      return index !== e
    })
    this.setState({ storeselectedTagData: deleteSelectedTag });
  }

  handleChangepermission = (e: any) => {
    if (e.target.value == "Public") {
      this.setState({ permission: "true" })
    } else {
      this.setState({ permission: "false" })
    }
  }


  handleResize = () => {
    const newWidth = window.innerWidth;
    localStorage.setItem("dynamicwidth", newWidth.toString());
    this.setState({ dynamicsetwidth: newWidth });
  };
  // Customizable Area End
}
